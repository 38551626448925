<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Vendors Details</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="VendorsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Vendor ID</th>
                                <th>Vendor</th>
                                <th>Vendor Users</th>
                                <th>Rep. Name</th>
                                <th>Landline</th>
                                <th>Address</th>
                                <th>PO BOX</th>
                                <th>Rep. Title</th>
                                <th>Rep. Phone</th>
                                <th>License #</th>
                                <th>License Expiry Date</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>Countries of Operation</th>
                                <th>Cities of Operation</th>
                                <th>Highest Office Name</th>
                                <th>Highest Office Phone</th>
                                <th>Vendor Bank</th>
                                <th>Vendor Swift</th>
                                <th>Vendor IBAN</th>
                                <th>Last 3 Years Turnover</th>
                                <th>Establishment Year</th>
                                <th>Represented Brand</th>
                                <th>Exclusive Brand</th>
                                <th>Vendor Type</th>
                                <th>Vendor Score</th>
                                <th>Score</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="VendorInfoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="VendorInfoModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <h3 class="modal-title text-ogeo">Vendor ID: {{ ViewModalInfo.id }} </h3>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;border-radius:25px">
                        </div>
                        <div style="width:10%">
                            <span style="font-size: 24px;"><b class="text-ogeo">Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor_score }} 10</b></span>
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table">
                                    <h4 class="modal-title text-ogeo">Vendor Detail:</h4>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Legal Name: </b></td>
                                        <td>{{ ViewModalInfo.name }} </td>
                                    </tr>

                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Standing: </b></td>
                                        <td>
                                            <span><b>Status:</b> {{ ViewModalInfo.status }} {{ ViewModalInfo.lic_expiry_date | formatDateNoTime }}</span><br>
                                            <span class="text-ogeo"><b>Active:</b> {{ ViewModalInfo.active }}, <i>Since {{ ViewModalInfo.updated_at }}</i></span><br>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td width="40%"><b class="font-500">Establishment Year: </b></td>
                                        <td>{{ ViewModalInfo.open_since }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Created By: </b></td>
                                        <td>{{ ViewModalInfo.creator.name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Created At: </b></td>
                                        <td>{{ ViewModalInfo.created_at }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Updated By: </b></td>
                                        <td>{{ ViewModalInfo.update_user ? ViewModalInfo.update_user.name : '' }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Updated At: </b></td>
                                        <td>{{ ViewModalInfo.updated_at }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Representative Details: </b></td>
                                        <td>
                                            <span><b>Name:</b> {{ ViewModalInfo.rep_name }}</span><br>
                                            <span><b>Title:</b> {{ ViewModalInfo.rep_title }}</span><br>
                                            <span><b>Phone Number:</b> {{ ViewModalInfo.mobile }}</span><br>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Highest Ranking Office Details: </b></td>
                                        <td>
                                            <span><b>Name:</b> {{ ViewModalInfo.officer_name }}</span><br>
                                            <span><b>Phone:</b> {{ ViewModalInfo.officer_phone }}</span><br>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Landline: </b></td>
                                        <td>{{ ViewModalInfo.phone }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Address: </b></td>
                                        <td>
                                            <span><b>Address:</b> {{ ViewModalInfo.address }}</span><br>
                                            <span><b>PO.BOX:</b> {{ ViewModalInfo.po_box }}</span><br>
                                            <span><b>City:</b> {{ ViewModalInfo.city }}</span><br>
                                            <span><b>Country:</b> {{ ViewModalInfo.country }}</span><br>
                                        </td>
                                    </tr>
                                    
                                </table>
                            </div>

                            <div class="col-lg-6">
                                <table class="table">
                                    
                                    <tr>
                                        <td width="40%"><b class="font-500">Financials: </b></td>
                                        <td>
                                            <span><b>Bank Name:</b> {{ ViewModalInfo.bank_name }}</span><br>
                                            <span><b>Vendor IBAN:</b> {{ ViewModalInfo.bank_iban }}</span><br>
                                            <span><b>Last 3 Years Turnover:</b> {{ formatPrice(ViewModalInfo.turnover) }} USD</span><br>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Qualification Expiry Date: </b></td>
                                        <td>{{ ViewModalInfo.lic_expiry_date | formatDateNoTime }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">License Number: </b></td>
                                        <td>{{ ViewModalInfo.lic_num }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Website: </b></td>
                                        <td>{{ ViewModalInfo.url }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Brands: </b></td>
                                        <td>
                                            <span><b>Represented Brand:</b> {{ ViewModalInfo.sold_brands }}</span><br>
                                            <span><b>Exculsive Brands:</b> {{ ViewModalInfo.exculsive_brands }}</span><br>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Type: </b></td>
                                        <td>{{ ViewModalInfo.vendor_type }}</td>
                                    </tr>

                                    <tr>
                                        <td><b class="font-500">Associated Library of Materials Templates: </b></td>
                                        <td v-if="ViewModalInfo.item_templates">
                                            <ul style="display: block;" v-for="(template, index) in ViewModalInfo.item_templates">
                                                <li>{{template.template_name}}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Associated Library of Materials Templates
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><b class="font-500">Field Of Business: </b></td>
                                        <td v-if="ViewModalInfo.provided_service">
                                            <ul style="display: block;" v-for="(service, index) in ViewModalInfo.provided_service.split(',')">
                                                <li>{{service}}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                   
                                </table>

                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="modal-title text-ogeo">Vendor Qualification Documents:</h4>
                                <div class="col-lg-12">
                                    
                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">File Name</th>
                                            <th class="font-700">Uploaded At</th>
                                            <!-- <th>Action</th> -->
                                        </thead>
                                        <tbody v-if="ViewModalInfo.qualification_documents">
                                            <tr v-for="document in ViewModalInfo.qualification_documents">
                                                <td>{{ document.document_name }}</td>
                                                <td>{{ document.created_at }}</td>
                                                <!-- <td>
                                                    <a class="btn btn-success" :href="'/uploads/vendorsQualificationDocs/'+document.auto_generated_name" download> Download</a>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" align="center">No Qualification Documents Available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   

                                </div>
                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="modal-title text-ogeo">Vendor Users List:</h4>
                                <div class="col-lg-12">
                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">ID</th>
                                            <th class="font-700">User Name</th>
                                            <th class="font-700">User Email</th>
                                            <th class="font-700">Vendor Bid Manager Role</th>
                                            <th class="font-700">Vendor Invoicing Manager Role</th>
                                            <th class="font-700">Assigned At</th>
                                            <th class="font-700">Active</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="user in ViewModalInfo.users">
                                                <td>{{ user.id }}</td>
                                                <td>{{ user.name }}</td>
                                                <td>{{ user.email }}</td>
                                                <td>{{ user.pivot.sbm_role }}</td>
                                                <td>{{ user.pivot.sim_role }}</td>
                                                <td>{{ user.created_at }}</td>
                                                <td>{{ user.active }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <div class="d-inline-block">
                                    <span><b>Date:</b> {{ new Date() }}</span><br>
                                    <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Vendor Information';window.print()">Download PDF</button>   <br><br>
                                </div>
                                <div class="d-inline-block pull-right">
                                    <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="EditViewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="EditViewModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <h3 class="modal-title text-ogeo">Vendor ID: {{ EditModalInfo.id }} </h3>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;border-radius:25px">
                        </div>
                        <div style="width:10%">
                            <span style="font-size: 24px;"><b class="text-ogeo">Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor_score }} 10</b></span>
                        </div>
                        <div >
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            <!-- <span style="float: right; text-align: right; font-size: 24px"><b>Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor_score }} 10</b></span> -->
                        </div>
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <el-form :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="VendorEditRequestForm">

                                    <el-row :gutter="24">

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="name">
                                                    <span slot="label"><b class="font-700">Enter Vendor Legal Name</b></span>
                                                    <el-input v-model="EditModalInfo.name" placeholder="Enter Vendor Legal Name"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="vendor_alias">
                                                    <span slot="label"><b>Enter Vendor Alias</b></span>
                                                    <el-input v-model="EditModalInfo.vendor_alias" placeholder="Enter Vendor Alias"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="phone">
                                                    <span slot="label"><b class="font-700">Enter Vendor Landline</b></span>
                                                    <el-input v-model="EditModalInfo.phone" placeholder="Enter Vendor Landline"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="address">
                                                    <span slot="label"><b class="font-700">Enter Vendor Address</b></span>
                                                    <el-input v-model="EditModalInfo.address" placeholder="Enter Vendor Address"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="po_box">
                                                    <span slot="label"><b class="font-700">Enter Vendor PO.Box</b></span>
                                                    <el-input v-model="EditModalInfo.po_box" placeholder="Enter Vendor PO.Box"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="country">
                                                    <span slot="label"><b class="font-700">Select Vendor Country</b></span>

                                                    <el-select filterable allow-create style="width: 100%; padding: 0px;" @change="getCities($event)" v-model="TmpVC" placeholder="Select Vendor Country">
                                            
                                                        <el-option style="min-width:145px !important"
                                                            v-for="(item,CompanyCountry) in Countries"
                                                            :key="CompanyCountry"
                                                            :label="item.country_name"
                                                            :value="item.id">
                                                            <span style="float: left;">{{ item.country_name }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>

                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="city">
                                                    <span slot="label"><b class="font-700">Select Vendor City</b></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.city" placeholder="Select Vendor City">
                                                        
                                                        <el-option
                                                            v-for="(item,companyCity) in cities"
                                                            :key="companyCity"
                                                            :value="item.city_name"
                                                            :label="item.city_name">
                                                        <span style="float: left">{{ item.city_name }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="officer_name">
                                                    <span slot="label"><b class="font-700">Enter Vendor Highest Ranking Officer Name</b></span>
                                                    <el-input v-model="EditModalInfo.officer_name" placeholder="Enter Vendor Highest Ranking Officer Name"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="officer_phone">
                                                    <span slot="label"><b class="font-700">Enter Vendor Highest Ranking Phone Number</b></span>
                                                    <el-input v-model="EditModalInfo.officer_phone" placeholder="Enter Vendor Highest Ranking Phone Number"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="rep_name">
                                                    <span slot="label"><b class="font-700">Enter Vendor Representative Name</b></span>
                                                    <el-input v-model="EditModalInfo.rep_name" placeholder="Enter Vendor Representative Name"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="rep_title">
                                                    <span slot="label"><b class="font-700">Enter Vendor Representative Title</b></span>
                                                    <el-input v-model="EditModalInfo.rep_title" placeholder="Enter Vendor Representative Title"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="mobile">
                                                    <span slot="label"><b class="font-700">Enter Vendor Representative Phone Number</b></span>
                                                    <el-input v-model="EditModalInfo.mobile" placeholder="Enter Vendor Representative  Phone Number"></el-input>
                                                </el-form-item>
                                            </div>
                                        
                                        </el-col>

                                        <el-col :span="24">
                                            <hr>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_name">
                                                    <span slot="label"><b class="font-700">Enter Vendor Bank Name</b></span>
                                                    <el-input v-model="EditModalInfo.bank_name" placeholder="Enter Vendor Bank Name"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_name">
                                                    <span slot="label"><b class="font-700">Enter Vendor Bank Swift Code</b></span>
                                                    <el-input v-model="EditModalInfo.bank_swift" placeholder="Enter Vendor Bank Name"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_iban">
                                                    <span slot="label"><b class="font-700">Enter Vendor International Bank Account Number (IBAN)</b></span>
                                                    <el-input v-model="EditModalInfo.bank_iban" placeholder="Enter Vendor International Bank Account Number (IBAN)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24">
                                            <hr>
                                        </el-col>
                                        
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="open_since">
                                                    <span slot="label"><b class="font-700">Select Vendor Esablishment Year</b></span>
                                                    <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.open_since"  placeholder="Select Vendor Esablishment Year"> 
                                                        <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option> 
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="turnover">
                                                    <span slot="label"><b class="font-700">Enter Average of The Last 3 Years Turnover</b></span>
                                                    <el-input v-model="EditModalInfo.turnover" placeholder="Enter Average of The Last 3 Years Turnover"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="VendorCountryOperations">
                                                    <span slot="label"><b>Select Countries Of Operations</b></span>
                                                    <el-select filterable multiple collapse-tags id="country" class="country" @change="getMultiCities($event)" style="width: 100%; padding: 0px" v-model="VendorSC" placeholder="Select Countries Of Operations">
                                                        <el-option style="min-width:145px !important"
                                                            v-for="(item,CompanyCountry) in Countries"
                                                            :key="CompanyCountry"
                                                            :label="item.country_name"
                                                            :value="item.id">
                                                            <span style="float: left;">{{ item.country_name }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="VendorCityOperations">
                                                    <span slot="label"><b>Select Cities Where The Vendor Operates</b></span>
                                                    <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.operating_cities" placeholder="Select City">
                                                        
                                                        <el-option
                                                            v-for="(item,companyCity) in multiCities"
                                                            :key="companyCity"
                                                            :value="item.city_name"
                                                            :label="item.city_name">
                                                        <span style="float: left">{{ item.city_name }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24">
                                            <hr>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="url">
                                                    <span slot="label"><b class="font-700">Enter Vendor Website</b></span>
                                                    <el-input v-model="EditModalInfo.url" placeholder="http://www.vendorwebsite.com/otherurl/232/wsd"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="lic_num">
                                                    <span slot="label"><b class="font-700">Enter Vendor License Number</b></span>
                                                    <el-input v-model="EditModalInfo.lic_num" placeholder="Enter Vendor  License Number"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12"> 
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="vendor_type">
                                                    <span slot="label"><b class="font-700">Select Vendor Type</b></span>
                                                    <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.vendor_type"  placeholder="Select Vendor Type"> 

                                                        <el-option label="Manufacturer" value="Manufacturer"></el-option>
                                                        <el-option label="Wholesaler and Distributor" value="o Wholesaler and Distributor"></el-option>
                                                        <el-option label="Franchisor" value="Franchisor"></el-option>
                                                        <el-option label="Independent craft people" value="Independent craft people"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="provided_service">
                                                    <span slot="label"><b class="font-700">Select Vendor Business Sector</b></span>
                                                    <el-select filterable collapse-tags allow-create multiple style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.provided_service"  placeholder="Select Vendor Field Of Business">

                                                        <!-- <el-option v-for="field in FieldOfBusiness" :key="field.name" :label="field.name" :value="field.name"></el-option> -->
                                                        <el-option label="Information Technology Services" value="Information Technology Services"></el-option>
                                                        <el-option label="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services" value="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services"></el-option>
                                                        <el-option label="Transportation Services" value="Transportation Services"></el-option>
                                                        <el-option label="Medicine And Wellness Services" value="Medicine And Wellness Services"></el-option>
                                                        <el-option label="Convenience Services" value="Convenience Services"></el-option>
                                                        <el-option label="Financial Services" value="Financial Services"></el-option>
                                                        <el-option label="Contracting Services" value="Contracting Services"></el-option>
                                                        <el-option label="Construction & Fit-out Services" value="Construction & Fit-out Services"></el-option>
                                                        <el-option label="Supply of Mechanical Spare Parts" value="Supply of Mechanical Spare Parts"></el-option>
                                                        <el-option label="Supply of Electrical Spare Parts" value="Supply of Electrical Spare Parts"></el-option>
                                                        <el-option label="Supply of Construction Materials" value="Supply of Construction Materials"></el-option>
                                                        <el-option label="Supply of Foodstuff & Edible Products" value="Supply of Foodstuff & Edible Products"></el-option>
                                                        <el-option label="Supply of Tools" value="Supply of Tools"></el-option>
                                                        <el-option label="Supply of OEM Equipment" value="Supply of OEM Equipment"></el-option>
                                                        <el-option label="Supply of Furniture" value="Supply of Furniture"></el-option>
                                                        <el-option label="Supply of Vehicles" value="Supply of Vehicles"></el-option>
                                                        <el-option label="Supply of Textile Products" value="Supply of Textile Products"></el-option>
                                                        <el-option label="Supply of IT Products" value="Supply of IT Products"></el-option>
                                                        <el-option label="Supply of Fossil Fuels" value="Supply of Fossil Fuels"></el-option>
                                                        <el-option label="Supply of Stationary Products" value="Supply of Stationary Products"></el-option>
                                                        <el-option label="Supply of Packaging Products" value="Supply of Packaging Products"></el-option>
                                                        <el-option label="Supply of Fragrance Products" value="Supply of Fragrance Products"></el-option>
                                                        <el-option label="Supply of Cosmetic Products" value="Supply of Cosmetic Products"></el-option>
                                                        <el-option label="Supply of Cleaning Products" value="Supply of Cleaning Products"></el-option>
                                                        <el-option label="Supply of Personal Hygienic Products" value="Supply of Personal Hygienic Products"></el-option>
                                                        <el-option label="Supply of Consumer Electronics Products" value="Supply of Consumer Electronics Products"></el-option>
                                                        <el-option label="Supply of Whitegoods Products" value="Supply of Whitegoods Products"></el-option>
                                                        <el-option label="Supply of Garment Products" value="Supply of Garment Products"></el-option>
                                                        
                                                        <!-- Add Additional Options From Existing Vendors -->
                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="sold_brands">
                                                    <span slot="label"><b class="font-700">Enter Brands Represented By The Vendor</b></span>
                                                    <el-input v-model="EditModalInfo.sold_brands" placeholder="Brands Sold By The Vendors"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="exculsive_brands">
                                                    <span slot="label"><b class="font-700">Enter Brands Exclusively Represented By The Vendor</b></span>
                                                    <el-input v-model="EditModalInfo.exculsive_brands" placeholder="Brands Exclusively Represented By The Vendor"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12"> 
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="VendorSource">
                                                    <span slot="label"><b>Select What Can Be Sourced From The Vendor</b></span>
                                                    <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.vendor_dealsIn"  placeholder="*Select What Can Be Sourced From The Vendor"> 

                                                        <el-option label="Materials" value="Materials"></el-option>
                                                        <el-option label="Services" value="Services"></el-option>
                                                        <el-option label="Materials + Services" value="Materials + Services"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <transition v-if="EditModalInfo.vendor_dealsIn == 'Materials' || EditModalInfo.vendor_dealsIn == 'Materials + Services'" >
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="SelectedItemTemplates">
                                                        <span slot="label"><b><span class="text-danger">*</span>Select Applicable Library of Materials Templates</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <strong>I’m offered the possibility to link existing 
                                                                    applicable templates to the vendor. These 
                                                                    templates are pertaining to materials likely to be 
                                                                    offered by the vendor.</strong>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select id="SelectedItemTemplates" filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="EditModalInfo.item_templates" placeholder="Select Library of Materials Template">
                                                            
                                                            <el-option v-for="Template in ItemTemplates" :key="Template.id" :label="Template.template_name" :value="Template.id"></el-option>
                                                            
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </transition>

                                        <el-col :span="24">
                                            <hr>
                                        </el-col>
                                        
                                        <el-col :span="10">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="lic_expiry_date">
                                                    <span slot="label"><b class="font-700">Select The Vendor’s Qualification Expiry Date</b></span>
                                                    
                                                    <el-date-picker
                                                        :picker-options = "pickerOptions"
                                                        style="width: 100%"
                                                        v-model="EditModalInfo.lic_expiry_date"
                                                        type="date"
                                                        format="dd.MMM.yyyy"
                                                        value-format="dd-MM-yyyy">
                                                    </el-date-picker>
                                                    
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        
                                        <el-col :span="14"> 
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="VendorQualificationExpires">
                                                    <span slot="label"><b>Do You Want To Suspend Transactions With The Vendor As Soon As His Qualification Expires?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>If the 
                                                                transactions 
                                                                are suspended, 
                                                                the vendor will 
                                                                not be asked 
                                                                to submit 
                                                                proposals.</strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <el-select filterable style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.expireOn_licExpiry"  placeholder="*Do You Want To Suspend Transactions With The Vendor As Soon As His Qualification Expires?"> 

                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        
                                        <el-col :span="24">
                                            <hr>
                                            <h4 class="modal-title text-ogeo">Vendor Qualification Documents:</h4>
                                            <div class="col-lg-12">
                                                
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <th class="font-700">File Name</th>
                                                        <th class="font-700">Uploaded At</th>
                                                        <th class="font-700">Action</th>
                                                    </thead>
                                                    <tbody v-if="EditModalInfo.qualification_documents.length">
                                                        <tr v-for="(document, index) in EditModalInfo.qualification_documents">
                                                            <td><el-input v-model="document.name"/></td>
                                                            <td>{{ document.timestamp }}</td>
                                                            <td>
                                                                <button class="btn btn-danger" @click="RemoveFile(document.name, index)"> X </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="3" align="center">No Qualification Documents Available</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                               

                                            </div>
                                        </el-col>                                        



                                        <el-col :span="24">
                                            <div style="width: 100%" class="grid-content">
                                                <el-form-item style="margin-bottom: 0px;" label="" prop="VendorDocuments"></el-form-item>
                                                <span slot="label"><b>Upload Vendor Qualification Documents</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>The Qualification Documents</strong> are those documents that are mandatorily needed to finalize the qualification of the vendors. They may be updated from time to time by my organization. 
                                                        </span>
                                                    </span>
                                                </span>
                                                <br><br>
                                                <el-upload
                                                    v-model:file-list="EditModalInfo.qualification_documents"
                                                    class="upload-demo"
                                                    id='UploadDocuments'
                                                    :auto-upload="false"
                                                    ref="UploadDocuments"
                                                    :on-change="FileCount"
                                                    multiple
                                                    action="#"
                                                    :limit="10"
                                                    :show-file-list=false
                                                    :on-exceed="handleExceed"
                                                    >

                                                    <button type="button" slot="trigger" class="btn btn-success">Upload Docs!</button>
                                                  
                                                    <div class="el-upload__tip" slot="tip">Any file type is accepted with a size less than 3Mb (Max 10 Files)</div>
                                                </el-upload>

                                            </div>
                                            <br>
                                        </el-col>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="notes">
                                                    <span slot="label"><b class="font-700">Enter Vendor Additional Notes</b></span>

                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Any Additional Vendor Notes."
                                                        v-model="EditModalInfo.notes">
                                                    </el-input>
                                                    
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        
                                        <el-col :span="24">
                                            <hr>
                                            <el-checkbox v-model="VerifyInformation"><b>I have read all the attached documents and found them to be in order and in line with the organization’s internal procedure for vendor qualification</b></el-checkbox>
                                        </el-col>

                                        <el-col :span="24">
                                            
                                            <button type="button" @click="EditVendorDetailsForm" :class="[{ 'disabled': !VerifyInformation },'btn btn-success btn-block waves-effect text-center']">Update Vendor Detials</button>
                                        </el-col>

                                    </el-row>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
            
</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'vendors-list',
        data(){
            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === '' || value === null){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }
                    
                }
            };
            var CheckAlias = (rule, value, callback) => {
                this.checkVendorAlias(value);
                setTimeout(() => { 
                if (this.flag) {
                    callback();
                } else {

                    if(value === '' || value === null){
                        callback(new Error('Please Enter Vendor Alias'));
                    } else if(!this.flag) {
                        callback(new Error('Vendor Alias Already Taken!')); 
                    }
                    else
                        callback();
                }
            }, 1000);
            };
            return{
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() < Date.now();
                  },
                },
                DataTable: "",
                OriginalName: "",
                CompanySetup: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                ViewModalInfo: {},
                VerifyInformation: false,
                ItemTemplates: [],
                Countries: {},
                VendorSC: [],
                TmpVC: [],
                flag: "",
                cities: [],
                multiCities: [],
                FieldOfBusiness: [
                    {name: "Information Technology Services"},
                    {name: "Professional Services - Legal"},
                    {name: "Professional Services - Marketing"},
                    {name: "Professional Services - Consultancy"},
                    {name: "Professional Services - Design"},
                    {name: "Professional Services - Project Management"},
                    {name: "Professional Services - Security"},
                    {name: "Professional Services - Gardening"},
                    {name: "Professional Services - Cleaning"},
                    {name: "Transportation Services"},
                    {name: "Medicine And Wellness Services"},
                    {name: "Convenience Services"},
                    {name: "Financial Services"},
                    {name: "Contracting Services"},
                    {name: "Construction & Fit-out Services"},
                    {name: "Supply of Mechanical Spare Parts"},
                    {name: "Supply of Electrical Spare Parts"},
                    {name: "Supply of Construction Materials"},
                    {name: "Supply of Foodstuff & Edible Products"},
                    {name: "Supply of Tools"},
                    {name: "Supply of OEM Equipment"},
                    {name: "Supply of Furniture"},
                    {name: "Supply of Vehicles"},
                    {name: "Supply of Textile Products"},
                    {name: "Supply of IT Products"},
                    {name: "Supply of Fossil Fuels"},
                    {name: "Supply of Stationary Products"},
                    {name: "Supply of Packaging Products"},
                    {name: "Supply of Fragrance Products"},
                    {name: "Supply of Cosmetic Products"},
                    {name: "Supply of Cleaning Products"},
                    {name: "Supply of Personal Hygienic Products"},
                    {name: "Supply of Consumer Electronics Products"},
                    {name: "Supply of Whitegoods Products"},
                    {name: "Supply of Garment Products"},
                ],
                EditModalInfo: {},
                rules: {                    
                    name: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    vendor_alias: [{
                        required: false,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    rep_name: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    rep_title: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    address: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    po_box: [{
                        required: true,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    phone: [{
                        required: true,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    mobile: [{
                        required: true,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    lic_expiry_date: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    url: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    notes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    VendorDocuments: [{
                        required: false,
                        message: "Please Upload At least one documenet",
                        trigger: "blur"
                    }],
                    city: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    country: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    provided_service: [{
                        required: true,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    officer_name: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    officer_phone: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    bank_name: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_iban: [{
                        required: false,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    turnover: [{
                        required: false,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "blur"
                    }],
                    open_since: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    sold_brands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    exculsive_brands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    item_templates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    vendor_type: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                }
            }
        },
        methods: {
            RemoveFile(DocumentId, Index){
                if (event) event.preventDefault();
                let self = this;

                Swal({
                    title: 'Delete This Document?',
                    text: "Are you sure you what to permanently delete this document.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {

                        axios.post('/api/vendors_management/remove_qualification_doc', {DocumentId: DocumentId})
                            .then((response) => {

                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });
                            
                                self.EditModalInfo.qualification_documents.splice(Index, 1);                 
                            })
                    }
                        
                })
            },
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            FileCount(file) {
                this.EditModalInfo.qualification_documents.push({ name:file.name.split('.').slice(0, -1).join('.'), ext: file.name.split('.').pop(), timestamp:moment().format('DD,MMM,YYYY')}) 
            },
            getCountries() {
                axios.get('api/get/countries')
                    .then((res) => {
                        this.Countries = res.data
                    })
                    .catch((err) => {
                    })
            },
            getCities(e) {
                // this.cities= this.countries[this.EditModalInfo.VendorCountry];
                axios.get('api/get/cities/' + e)
                .then((res) => {
                    this.cities = res.data
                })
            },
            getMultiCities(e) {
                axios.post('api/get/cities-all', e)
                .then((res) => {
                    this.multiCities = res.data
                })
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            checkVendorAlias(e){
                 axios.post('/api/vendors_management/check_vendor_alias_duplicates', {name: e, id: this.EditModalInfo.id})
                .then((response) => { 
                    if(response.data == 'Yes') this.flag = false;
                    else this.flag = true;
                     });                  
            },
            EditVendorDetailsForm: function(){

                let self = this;

                if(this.VerifyInformation){

                    this.EditModalInfo.operating_countries = [];
                    this.Countries.some((el) => {
                                    if(this.VendorSC.includes(el.id))
                                        this.EditModalInfo.operating_countries.push(el.country_name);
                                    if(this.TmpVC == el.id)
                                        this.EditModalInfo.country = el.country_name;
                                });

                    if (event) event.preventDefault();

                    this.$refs.VendorEditRequestForm.validate((validation) => {

                        if(validation){


                            //Validate File Size
                            /*const isJPG = file.type === 'image/jpeg';
                            const isLt2M = file.size / 1024 / 1024 < 2;

                            if (!isJPG) {
                              this.$message.error('Avatar picture must be JPG format!');
                            }
                            if (!isLt2M) {
                              this.$message.error('Avatar picture size can not exceed 2MB!');
                            }
                            return isJPG && isLt2M;*/


                            axios.post('/api/vendors_management/check_vendor_name_duplicates', {name: this.EditModalInfo.name})
                                .then((response) => {

                                    if(response.data == 'Yes' && this.OriginalName != this.EditModalInfo.name){

                                        Swal({
                                            title: 'Vendor Name Duplicate!',
                                            text: "There is Another Vendor with the Same Name, Do You Want to Proceed?",
                                            type: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Yes',
                                            cancelButtonText: 'No'
                                        }).then((result) => {

                                            if (result.value) {

                                                let formData = new FormData();

                                                for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                                    let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                                    formData.append('FileUploads['+i+']', file);
                                                }
                                                

                                                formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));
                                                this.EditModalInfo.item_templates.forEach((data, index) => {
                                                    formData.append('EditModalInfo.item_templates[]', data);
                                                });

                                                axios.post( '/api/vendors_management/edit_vendor_details',
                                                    formData,
                                                    {
                                                        headers: {
                                                            'Content-Type': 'multipart/form-data'
                                                        }
                                                    }
                                                ).then(function(response){
                                                    Swal({ 
                                                        type: response.data.messageType, 
                                                        title: response.data.messageTitle, 
                                                        text: response.data.message,
                                                        showConfirmButton: true,
                                                        timer: 5000
                                                    });

                                                    self.VerifyInformation = false;

                                                    self.DataTable.ajax.reload( null, false );

                                                    $('#EditViewModal').modal('toggle');
                                                })
                                                .catch(function(){
                                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                                });

                                            }
                                                
                                        })



                                    } else {

                                        let formData = new FormData();

                                        for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                            let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                            formData.append('FileUploads['+i+']', file);
                                        }
                                        

                                        formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));

                                        this.EditModalInfo.item_templates.forEach((data, index) => {
                                                formData.append('EditModalInfo.item_templates[]', data);
                                            });
                                        axios.post( '/api/vendors_management/edit_vendor_details',
                                            formData,
                                            {
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }
                                            }
                                        ).then(function(response){
                                            Swal({ 
                                                type: response.data.messageType, 
                                                title: response.data.messageTitle, 
                                                text: response.data.message,
                                                showConfirmButton: true,
                                                timer: 5000
                                            });

                                            self.VerifyInformation = false;

                                            self.DataTable.ajax.reload( null, false );

                                            $('#EditViewModal').modal('toggle');
                                        })
                                        .catch(function(){
                                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                        });


                                    }
                                    
                                })
                            



                            

                        }

                    });
                }
                
            },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')
                
            },
            showViewModal(recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_vendor_details', {VendorID: recordId})
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        this.viewDataLoaded = true
                        console.log(this.ViewModalInfo);
                        $('#VendorInfoModal').modal('toggle');
                    })

                this.viewDataLoaded = false;
                
            },
            editViewModal(recordId){
                let self = this;
                this.ViewModalInfo = {};
                axios.post('/api/data/get_vendor_details', {VendorID: recordId})
                    .then((response) => {
                        self.EditModalInfo = response.data;

                        self.OriginalName = self.EditModalInfo.name;
                        if(self.EditModalInfo.provided_service)
                            self.EditModalInfo.provided_service = self.EditModalInfo.provided_service.split(',');
                        if(self.EditModalInfo.country)
                            self.TmpVC = self.EditModalInfo.country;
                        if(self.EditModalInfo.operating_countries)
                            self.VendorSC = self.EditModalInfo.operating_countries.split(',');
                        if(self.EditModalInfo.operating_cities)
                            self.EditModalInfo.operating_cities = self.EditModalInfo.operating_cities.split(',');
                        if(self.EditModalInfo.lic_expiry_date)
                            self.EditModalInfo.lic_expiry_date = new Date(self.EditModalInfo.lic_expiry_date);
                        
                        var TmpTemp = self.EditModalInfo.item_templates;
                        self.EditModalInfo.item_templates = [];
                        
                        TmpTemp.forEach((data, index) => {
                            self.EditModalInfo.item_templates.push(data.id);
                        });
                        self.editDataLoaded = true;
                        $('#EditViewModal').modal('toggle');
                    })

                this.viewDataLoaded = false;
                
            },
            ToggleActivation(recordId){
                let self = this;
                axios.post('/api/vendors_management/toggle_vendor_activation', {VendorID: recordId})
                    .then((response) => {

                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            },

        },
        mounted(){
            this.getCountries();
            let self = this;
            axios.get('/api/data/get_item_template_list')
            .then((response) => {
                self.ItemTemplates = response.data;
            })
            axios.get('/api/data/get_company_details')
            .then((response) => {
                // console.log(response.data);
                self.CompanySetup = response.data;
            });

            const table = $('#VendorsList').DataTable({
                fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_vendors_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'name', name: 'name' },
                    { data: 'vendor_users', name: 'vendor_users' },
                    { data: 'rep_name', name: 'rep_name' },
                    { data: 'phone', name: 'phone' },
                    { data: 'address', name: 'address' },
                    { data: 'po_box', name: 'phone' },
                    { data: 'rep_title', name: 'rep_title' },
                    { data: 'mobile', name: 'mobile' },
                    { data: 'lic_num', name: 'lic_num' },
                    { data: 'lic_expiry_date', name: 'lic_expiry_date' },
                    { data: 'country', name: 'country' },
                    { data: 'city', name: 'city' },
                    { data: 'operating_countries', name: 'operating_countries' },
                    { data: 'operating_cities', name: 'operating_cities' },
                    { data: 'officer_name', name: 'officer_name' },
                    { data: 'officer_phone', name: 'officer_phone' },
                    { data: 'bank_name', name: 'bank_name' },
                    { data: 'bank_swift', name: 'bank_iban' },
                    { data: 'bank_iban', name: 'bank_iban' },
                    { data: 'turnover', render: function(data) {
                        if(data){
                            var currency = (data/1).toFixed(2).replace(',', '.');
                            return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD";
                        }
                        else
                            return "0 USD";
                    } },
                    { data: 'open_since', name: 'open_since' },
                    { data: 'sold_brands', name: 'sold_brands' },
                    { data: 'exculsive_brands', name: 'exculsive_brands' },
                    { data: 'vendor_type', name: 'vendor_type' },
                    { data: 'vendor_score', name: 'vendor_score' },
                    { data: 'status', name: 'status' },
                    { data: 'active', name: 'active' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                columnDefs: [
                    {  targets: 28, responsivePriority: 1 },
                    {  targets: 0, responsivePriority: 2 },
                    {  targets: 27, responsivePriority: 4 },
                    {  targets: 26, responsivePriority: 3 },
                    {  targets: 2, width: '30%' },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 0, "ASC" ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'VendorsList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },    
            });

            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $this.parent().parent().find(">:first-child").text();
                //Check if Id has prefix and resolve
                if (RecordID.includes("-")){
                    RecordID = RecordID.substring(RecordID.indexOf("-") + 1);
                }
                self.showViewModal(RecordID);
            });

            $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');
                self.editViewModal(RecordID);
            });

            $('tbody', this.$refs.table).on('click', '.activations-placeholder', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');
                if($(this).attr('title') == 'Activate Vendor')
                    var msg = 'reactivate';
                else
                    var msg = 'deactivate';
                Swal({
                    type: 'warning', 
                    title: 'Warning!', 
                    text: 'Are you sure you want to '+msg+' this Vendor?',
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#FF0000",
                    confirmButtonColor: "#32CD32",
                }).then((confirm) => {
                    console.log(confirm.value);
                    if(confirm.value) {
                        self.ToggleActivation(RecordID);
                    }
                    else {
                        Swal('Unchanged', 'No Action Done!', 'info')
                    }
                });
            });

            self.DataTable = table;



            
        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>
<style>
@media print {
  body * {
    visibility: hidden;
  }
  #VendorInfoModal, #VendorInfoModal * {
    visibility: visible;
  }
  #VendorInfoModal {
    position: absolute;
    left: 0;
    top: 0;
  }
}



</style>
<style scoped>
    
    .font-500{
        font-weight: 700;
    }
    .font-700{
        font-weight: 700;
    }

    #VendorInfoModal .modal-dialog {
        min-width:80%;
    }

    #EditViewModal .modal-dialog {
        min-width:80%;
    }

</style>