<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 style="display: contents;">Services Purchase Enquiries List</h4>
                    <div class="text-ogeo float-right font-500 text-wrap">
                        <el-switch style="margin-top:0px" v-model="switchbutton" @change="changeTable()" active-color="rgb(255, 47, 47)" inactive-color="rgb(255, 47, 47)" active-text="Linked to Rates’ Contracts" inactive-text="Unrelated to Rates’ Contracts" active-value="Linked" inactive-value="Unlinked"></el-switch>
                    </div>
                </div>
                <div class="card-body">
                    <table ref="table" id="ProjectPEList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Purchase Enquiry Ref</th>
                                <th>GroupID</th>
                                <th>Setup</th>
                                <th>Originated By</th>
                                <th>Sourcing Priority</th>
                                <th>Service Short Descriptions</th>
                                <th>Delivery Location(s)</th>
                                <th>Countries & Cities</th>
                                <th>Advanced Payment Info</th>
                                <th>Retention Payment Info</th>
                                <th>Underlying Transaction</th>
                                <th>Service Contract Headers</th>
                                <th>Instruction To Vendor Headers</th>
                                <th>Vendor Offer Content</th>
                                <th>Type of Service Contract</th>
                                <th>Vendor's Offer Required Format</th>
                                <th>Service Term</th>
                                <th>List Of Validators</th>
                                <th>Originated At</th>
                                <th>Last Updated At</th>
                                <th>Status</th>
                                <th style="width: 30%">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>


        <div id="PurchaseEnquiryUpdateModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalView" aria-hidden="true" style="display: none;"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo">Services Purchase Enquiry Line ID# {{ ViewModalInfo.purchase_enquiry_group_id}} - {{ViewModalInfo.purchase_enquiry_ingroup_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Type: </b></td>
                                        <td>{{ ViewModalInfo.enquiry_type }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Delivery Address Details:</b></td>
                                        <td>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span><br>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span><br>
                                            <span><b class="font-700 font-italic">Location Name:</b> {{ ViewModalInfo.location_name }}</span><br>
                                            <span><b class="font-700 font-italic">Longitude:</b> {{ ViewModalInfo.longitude }}</span><br>
                                            <span><b class="font-700 font-italic">Latitude:</b> {{ ViewModalInfo.latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <!-- <tr align="center" v-if="ViewModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in ViewModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Service Short Description: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.service_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time: </b></td>
                                        <td>{{ ViewModalInfo.service_one_off }}</td>
                                    </tr> 
                                    <tr v-if="ViewModalInfo.service_one_off == 'Over A Period Of Time'">
                                        <td><b class="font-700">The Term Of The Service Contract, In Days:</b></td>
                                        <td>{{ ViewModalInfo.service_term }} Days</td>
                                    </tr> 
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Type Of Service Contract: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.type_of_services_contract }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Vendor Commercial Offer Format: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.vendor_commercial_offer }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Supporting Documents:</b></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul style="display: inline-flex" v-for="(his, index) in JSON.parse(ViewModalInfo.sd_images)">
                                                            <a :href="'/uploads/ServiceSupportingDocs/'+his" @click=""><li>{{ his }}</li></a>
                                                        </ul>
                                                    </td>
                                                </tr>    
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.tendererchildheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendoroffercontent">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 SCTH">Header</b></th>
                                                    <th><b class="font-700 SCTC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.childheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr>
                                    
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Rates Contracts -->
                            <!-- <div class="col-lg-12" v-if="ViewModalInfo.enquiry_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                                <hr>   
                            </div> -->
                            <!-- List of Valid Rates Contracts End-->

                        </div>                       

                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        
                        <div class="col-lg-12">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-Note</b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td>{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><button id="PrintHidden" class="btn btn-success ml-3" onclick="document.title = 'Services Rates’ Contract Line Information';window.print()">Download PDF</button>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>             
                    </div>
                </div>
            </div>
        </div>

        <div id="PurchaseEnquiryUpdateModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="PurchaseEnquiryUpdateModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title ogeo-text">Purchase Enquiry Line: {{ EditModalInfo.show_id }}</h4>
                        </div>
                        <div style="width:50%" v-if="this.CompanySetup.logo">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">
                            
                            <!-- Purchase Enquiry General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(EditModalInfo.project.value - EditModalInfo.project.consumed_budget) }} {{ EditModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ EditModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ EditModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ EditModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ EditModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Underlying Transaction: </b></td>
                                        <td>{{ EditModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Type: </b></td>
                                        <td>{{ EditModalInfo.enquiry_type }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Delivery Address Details:</b></td>
                                        <td>
                                            <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ EditModalInfo.country_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ EditModalInfo.country_of_delivery }}</span><br>
                                            <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span><br>
                                            <span><b class="font-700 font-italic">Location Name:</b> {{ EditModalInfo.location_name }}</span><br>
                                            <span><b class="font-700 font-italic">Longitude:</b> {{ EditModalInfo.longitude }}</span><br>
                                            <span><b class="font-700 font-italic">Latitude:</b> {{ EditModalInfo.latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Purchase Enquiry General Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment_guarantee ? EditModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.retention_percentage	 ? EditModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ EditModalInfo.retention_days ? EditModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <!-- <tr align="center" v-if="EditModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in EditModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ EditModalInfo.sourcing_priority }}</td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Service Short Description: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.service_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time: </b></td>
                                        <td>{{ EditModalInfo.service_one_off }}</td>
                                    </tr> 
                                    <tr v-if="EditModalInfo.service_one_off == 'Over A Period Of Time'">
                                        <td><b class="font-700">The Term Of The Service Contract, In Days:</b></td>
                                        <td>{{ EditModalInfo.service_term }} Days</td>
                                    </tr> 
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Type Of Service Contract: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.type_of_services_contract }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Vendor Commercial Offer Format: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.vendor_commercial_offer }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Supporting Documents:</b></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul style="display: inline-flex" v-for="(his, index) in JSON.parse(EditModalInfo.sd_images)">
                                                            <a :href="'/uploads/ServiceSupportingDocs/'+his" @click=""><li>{{ his }}</li></a>
                                                        </ul>
                                                    </td>
                                                </tr>    
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.tendererchildheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.vendoroffercontent">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 SCTH">Header</b></th>
                                                    <th><b class="font-700 SCTC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.childheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr>
                                    
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Purchase Enquiry -->
                            <!-- <div class="col-lg-12" v-if="EditModalInfo.enquiry_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                                <hr>   
                            </div> -->
                            <!-- List of Valid Purchase Enquiry End-->

                        </div>       
                        <div class="row">

                            <div class="col-lg-12">
                                <hr>

                                <el-form v-if="EditModalInfo.retention_percentage > 0 && !FullyInvoiced && EditModalInfo.receipt_note" :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseRequestRetentionUpdateForm">
                                <el-row :gutter="20">
                                    <el-col :span="24">

                                        <el-col :span="24">
                                            <hr>
                                            <h5>Adjust The Retention Timeframe</h5>
                                        </el-col>
                                        
                                        <el-col :span="24" >
                                            <div class="grid-content">
                                                <el-form-item label="Adjust The Retention Timeframe" prop="RetentionDays">
                                                        
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                The Rentention Timeframe initially opted for at the time of the Purchase Enquiry origination, can be revisited at any time as long as the invoicing by the vendor, hasn't been completed in full.
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="ValidationInfo.RetentionDays" placeholder="Select Retention Days From Delivery date">
                                                        
                                                        <el-option v-for="n in ValidationInfo.RetentionDays" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <div class="col-lg-12 float-right">
                                            <button type="button" @click="PurchaseEnquiryRententionUpdate" class="btn btn-block btn-success waves-effect">Update Retention Timeframe</button>
                                        </div>
                                        
                                    </el-col>
                                </el-row>
                            </el-form>   









                            <!-- Show approval form -->
                            <div class="row" v-if="EditModalInfo.status.indexOf('Created and Assigned to ') !== -1 || EditModalInfo.status.indexOf('PE line Approved by Level') !== -1 || EditModalInfo.status.indexOf('Resubmitted and Assigned') !== -1 || EditModalInfo.status.indexOf('for Review') !== -1">
                                <div class="col-lg-12">
                                    
                                    <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                
                                                <!-- <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item label="Approve Or Reject Request" prop="Decision">
                                                            <el-select @change="DecisionSelection" style="width: 100%; padding: 0px;" v-model="ValidationInfoApproval.Decision" placeholder="Select Yes/No">
                                                                  <el-option label="Approve" value="Approved"></el-option>
                                                                  <el-option label="Reject" value="Rejected"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div> 
                                                </div> -->

                                                <h3 class="text-ogeo">Decision:</h3>
                                                <b class="text-ogeo">A Rejected Line Will Be Sent Back To The Request Initiator Who Can Either Permanently Cancel It From The Materials Rates’ Contract Request, Or Amend Few Details And Resubmit For Validation, As The Case May Be. The Advance Payment And Retention Payment Arrangements, The Underlying Transaction, The Sourcing Priority, The Delivery Location Details, The Required Quantity & The Documents Required From The Vendors Are All Amendable.</b><br><br>   
                                                <span class="text-ogeo">The Setup & The Purchase Enquiry Type Can’t Be Amended, As They Are Common To All The Lines Of The Materials Purchase Enquiry. Neither Can The Item Details.</span><br><br>   


                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item prop="DecisionNotes">
                                                            
                                                            <el-input
                                                              type="textarea"
                                                              :rows="5"
                                                              placeholder="Enter Decision Notes"
                                                              v-model="ValidationInfoApproval.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="DecisionApprove" class="btn btn-block btn-success waves-effect" style="background-color: #32CD32; border-color: #32CD32;">Approve</button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                                </div>

                                                <!-- <div class="col-lg-12">
                                                    <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                                </div> -->
                                                    
                                                
                                            </div>
                                        </div>
                                    </el-form> 

                                </div>
                            </div>


                            <div class="row" v-else-if="EditModalInfo.status.indexOf('for Further Approval') !== -1">
                                <div class="col-lg-12">
                                    
                                    <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                
                                                <!-- <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item label="Approve Or Reject Request" prop="Decision">
                                                            <el-select @change="DecisionSelection" style="width: 100%; padding: 0px;" v-model="ValidationInfoApproval.Decision" placeholder="Select Yes/No">
                                                                  <el-option label="Approve" value="Approved"></el-option>
                                                                  <el-option label="Reject" value="Rejected"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div> 
                                                </div> -->
                                                
                                                <h3 class="text-ogeo">Decision:</h3>
                                                <b class="text-ogeo">Rejected Items Will Be Sent Back To The Purchase Request Initiator Who Can Either Drop Them From The Purchase Request, Or Amend Details Such As The Advance Payment  And Retention Payment  Arrangements, The Underlying Transaction, The Sourcing Priority, The Delivery Location Details, The Required Quantity & The Documents Required From The Vendors. 
                                                Only The Setup, The Purchase Enquiry Type & The Item Details Are Inalterable.
                                                </b><br><br>   
                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item prop="DecisionNotes">
                                                            <el-input
                                                              type="textarea"
                                                              :rows="5"
                                                              placeholder="Enter Decision Notes"
                                                              v-model="ValidationInfoApproval.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="DecisionApprove" class="btn btn-block btn-success waves-effect" style="background-color: #32CD32; border-color: #32CD32;">Approve</button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                                </div>

                                                <!-- <div class="col-lg-12">
                                                    <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                                </div> -->
                                                    
                                                
                                            </div>
                                        </div>
                                    </el-form> 

                                </div>
                            </div>

                            <div class="col-lg-12" v-else-if="EditModalInfo.status.includes('Rejected') && !showAmend">
                                <hr>
                                <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryApprovalForm">
                                    <div class="row">
                                        <div class="col-lg-12">
                                    
                                            <h3 class="text-ogeo">Decision:</h3>
                                            <b class="text-ogeo">A Rejected Line Can Either Be Altogether Canceled From The Materials Purchase Enquiry, Or Be Amended And Resubmitted For Validation. The Advance Payment And Retention Payment Arrangements, The Underlying Transaction, The Sourcing Priority, The Delivery Location Details, The Required Quantity & The Documents Required From The Vendors Are All Amendable.</b><br><br>   
                                            <span class="text-ogeo">The Setup & The Purchase Enquiry Type Can’t Be Amended, As They Are Common To All The Lines Of The Materials Purchase Enquiry. Neither Can The Item Details.</span><br><br>   

                                            <div class="col-lg-12">
                                                <div class="grid-content">
                                                    <el-form-item class="decision" prop="DecisionNotes" >
                                                        <el-input
                                                        type="textarea"
                                                        :rows="5"
                                                        placeholder="Enter Decision Notes"
                                                        v-model="ValidationInfo.DecisionNotes">
                                                        </el-input>
                                                    </el-form-item>
                                                </div> 
                                            </div>

                                            <div class="col-lg-6 float-right">
                                                <button type="button" @click="showAmend = true" style="background-color: green; border-color: green;" class="btn btn-block btn-success waves-effect">Amend Line Information And Resubmit For Approval</button>
                                            </div>

                                            <div class="col-lg-6 float-left">
                                                <button type="button" @click="DecisionRejectPermanent" class="btn btn-block btn-danger waves-effect">Cancel Line From the Materials Purchase Enquiry</button>
                                            </div>

                                            <!-- <div class="col-lg-12">
                                                <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                            </div> -->
                                                
                                            
                                        </div>
                                        <br>
                                    </div>
                                </el-form>
                                <hr>
                            </div>

                            <!-- Form for update and Resubmitt -->
                            <div class="col-lg-12" v-else>
                                <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="PeEditRules" ref="PurchaseEnquiryForm">

                                    <el-row :gutter="20">
                                        

                                        <el-col :span="24">
                                        <div class="grid-content">
                                                <h3>Amend A Purchase Enquiry Line</h3>
                                                <!-- <span class="text-ogeo font-700">Rates’ Contracts are pre-negotiated agreements with 
                                                vendors intended to freeze unit prices and lead times for materials 
                                                (strictly those from the Library of Materials) and services, before the 
                                                demand for them actually arises, so as to reduce cost and workload 
                                                (Purchase Enquiries linked to Rates’ Contracts can be directly turned into 
                                                Purchase Orders). Establishing Rates’ Contract starts with raising a 
                                                formal Rates’ Contract Creation Requests.</span> -->
                                            </div><br>
                                        </el-col>   

                                        <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="JobNumber">
                                                        <span slot="label"><b>Select A Setup</b></span>

                                                        <el-select disabled id="SetupSelection" @change="getProjectCurrency()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select A Setup">
                                                            
                                                            <!-- <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option> -->
                                                            
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </transition>
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.JobNumber"><hr></el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                                    <span slot="label"><b>Select Purchase Enquiry Type</b></span>

                                                    <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Select Purchase Enquiry Type (Material Or Service)">
                                                            
                                                        <el-option label="Materials" value="Materials"></el-option>
                                                        <el-option label="Services" value="Service"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                            <hr>
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="SourcingPriority">
                                                    <span slot="label"><b>Sourcing Priority</b></span>

                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                            </span>
                                                        </span>
                                                    </span>
                                                    
                                                    <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                            
                                                        <el-option label="Standard" value="Standard"></el-option>
                                                        <el-option label="Urgent" value="Urgent"></el-option>
                                                        <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                            
                                                    </el-select>

                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service') && (this.CompanySetup.additional_required_1 == 'Yes')">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="UnderlyingTransaction">
                                                    <span slot="label"><b>Enter The Underlying Transaction Reference</b></span>

                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>The Underlying Transaction Reference</strong> is 
                                                                the reference of the transaction that has led to the initiation
                                                                of this specific Purchase Enquiry. It can be a particular Job 
                                                                Ticket, a Sales Order, a Work Order, a Client Request,…etc
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <el-input disabled v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType"><hr></el-col>

                                        <!-- Delivery Location Selection -->
                                        <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                            <div class="grid-content">
                                                <el-form-item prop="Country">
                                                    <span slot="label"><b>Select Country</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="left: 100px">
                                                            <span class="tooltip-text2">Select the country where delivery is needed.</span>
                                                        </span>
                                                    </span>
                                                    <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country"> -->
                                                    <el-select disabled filterable collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Country">
                                                        
                                                        
                                                        <el-option
                                                            v-for="(item,CompanyCountry) in countries"
                                                            :key="CompanyCountry"
                                                            :label="CompanyCountry"
                                                            :value="CompanyCountry">
                                                            <span style="float: left">{{ CompanyCountry }}</span>
                                                        </el-option>

                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>                                   
                                        <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                            <div class="grid-content">
                                                <el-form-item prop="Cities">
                                                    <span slot="label"><b>Select City</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="left: -100px">
                                                            <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                                        </span>
                                                    </span>
                                                    <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                                    <el-select disabled filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select City" @change="checkCityRC($event)">
                                                        
                                                        <el-option
                                                            v-for="(item,companyCity) in cities"
                                                            :key="companyCity"
                                                            :value="item"
                                                            :label="item">
                                                        <span style="float: left">{{ item }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Select the Delivery Location Address On The Map</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            You may use Google Map and drop a pin in the location of delivery.                                            
                                                        </span>
                                                    </span>
                                                </span>

                                                <button disabled v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" type="button"  data-toggle="modal" id="get_map" data-target="#DeliveryLocation" class="btn btn-primary btn-block waves-effect text-center">Select Delivery Location</button>
                                            </el-form-item>
                                        </el-col> 
                                        <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="Longitude">
                                                <span slot="label"><b>Delivery Longitude</b></span>
                                                <el-input disabled type="number" readonly  placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="Latitude">
                                                <span slot="label"><b>Delivery Latitude</b></span>
                                                <el-input disabled type="number"  readonly placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="LocationName">
                                                <span slot="label"><b>Location Name</b></span>
                                                <el-input disabled type="text" placeholder="Enter Location Name" v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType"><hr></el-col>
                                        <!-- Delivery Location Selection -->
                                        
                                        
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b>Instructions To Vendors</b></span>
                                                    <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                        <thead class="text-white bg-dark">
                                                            <tr style="width:25%">
                                                                <th><span style="color:white">*</span>Header
                                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                            
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                            <span class="tooltip-text2">
                                                                                These headers help better describe the instructions to vendors,
                                                                                as well as the conditions of award. They can include Biding 
                                                                                Instructions, Bid Scoring Sheet, .....
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th style="width:70%">Content</th>
                                                                <th class="text-center" width="70"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.TendererContractHeaders"
                                                                :key="index">
                                                                <td>
                                                                    <input v-model="tenderheader.header_name" class="form-control">
                                                                </td>
                                                                <td>
                                                                    <input v-model="tenderheader.header_contant" class="form-control">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input class="form-control" v-model="tenderercontractheader.name">
                                                                </td>
                                                                <td>
                                                                    <input class="form-control" v-model="tenderercontractheader.contant">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button v-if="tenderercontractheader.name && tenderercontractheader.contant" style="padding: 0px 13px" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b>Vendor Offer Content</b></span>
                                                    <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                        <thead class="text-white bg-dark">
                                                            <tr style="width:25%">
                                                                <th><span style="color:white">*</span>Header
                                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                            
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                            <span class="tooltip-text2">
                                                                                These headers help better describe the instructions to vendors,
                                                                                as well as the conditions of award. They can include Biding 
                                                                                Instructions, Bid Scoring Sheet, .....
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th style="width:70%">Content</th>
                                                                <th class="text-center" width="70"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.VendorOfferContent"
                                                                :key="index">
                                                                <td>
                                                                    <input v-model="tenderheader.header_name" class="form-control">
                                                                </td>
                                                                <td>
                                                                    <input v-model="tenderheader.header_contant" class="form-control">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input class="form-control" v-model="vendoroffercontent.name">
                                                                </td>
                                                                <td>
                                                                    <input class="form-control" v-model="vendoroffercontent.contant">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button v-if="vendoroffercontent.name && vendoroffercontent.contant" style="padding: 0px 13px" @click.prevent="addVendorOfferContent()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b>Service Contract Template</b></span>
                                                    <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                        <thead class="text-white bg-dark">
                                                            <tr style="width:25%">
                                                                <th><span style="color:white">*</span>Header
                                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                            
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                            <span class="tooltip-text2">
                                                                                These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                                Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                                Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                                rights, Litigation...
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th style="width:70%">Content</th>
                                                                <th class="text-center" width="70"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(header, index) in PurchaseEnquiryLine.ContractHeaders"
                                                                :key="index">
                                                                <td>
                                                                    <input v-model="header.header_name" class="form-control">
                                                                </td>
                                                                <td>
                                                                    <input v-model="header.header_contant" class="form-control">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button style="padding: 0px 13px" type="button" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input class="form-control" v-model="contractheader.name">
                                                                </td>
                                                                <td>
                                                                    <input class="form-control" v-model="contractheader.contant">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button v-if="contractheader.contant && contractheader.contant" style="padding: 0px 13px" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                        <hr>
                                        <div class="grid-content">
                                            <el-form-item style="font-weight: bolder" label="temp" prop="TypeOfServicesContract">
                                                <span slot="label"><b>Type of Service Contract</b></span>
                                                
                                                <el-select @change="ClearServiceTypeOptions()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.TypeOfServicesContract" placeholder="Type of Service Contract">
                                                        
                                                    <el-option value="Fixed-Priced Contract">Fixed-Priced Contract
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                <span class="tooltip-text2">
                                                                    A fixed-price contract, also known as lump sum contract,<br> 
                                                                    is used in situations where the payment doesn’t depend on the<br> 
                                                                    resources used or time expended. With fixed-price contracts, <br>
                                                                    vendors will estimate the total allowable costs of labor, <br>
                                                                    materials and equipment and perform the action specified by<br>
                                                                    the contract regardless of the actual cost.<br>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </el-option>
                                                    <el-option value="Cost-Reimbursement Contract">Cost-Reimbursement Contract
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                <span class="tooltip-text2">
                                                                    With a cost-reimbursement contract, the final<br> 
                                                                    total cost is determined when the project is completed<br>
                                                                    or at another predetermined date within the contract’s timeframe.<br>
                                                                    Before the project is started, the vendor will create<br>
                                                                    an estimated cost to give the organization an idea of the budget.<br>
                                                                    The purpose of setting this expectation with cost-reimbursement<br> 
                                                                    contracts is to establish a ceiling price that the contractor<br>
                                                                    shouldn’t exceed without the approval of the organization. At<br>
                                                                    the same time, if that ceiling is reached, the vendor can stop work.<br>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </el-option>
                                                    <el-option value="Cost-plus-Fixed-Fee Contract">Cost-plus-Fixed-Fee Contract
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                <span class="tooltip-text2">
                                                                    A cost-plus contract is type of cost reimbursement contract<br>
                                                                    for situations where the organization agrees to pay the <br>
                                                                    actual cost of the entire project, including labor, materials,<br>
                                                                    and any unexpected expenses. The word “plus” refers to the<br>
                                                                    fee that covers the contractor’s profits and overhead. <br>
                                                                    In these agreements, the organization agrees to pay that extra<br> 
                                                                    amount and expects the vendor to deliver on their promise.<br>

                                                                </span>
                                                            </span>
                                                        </span>
                                                    </el-option>
                                                        
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-Priced Contract' && PurchaseEnquiryLine.EnquiryType == 'Service' " >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                            
                                                        <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                                        <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Reimbursement Contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                            
                                                        <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-plus-Fixed Fee Contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                            
                                                        <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <!-- <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="PELineNote">
                                                    <span slot="label"><b>Title</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Title"
                                                        v-model="PurchaseEnquiryLine.Title">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col> -->
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service'"><hr></el-col>


                                    <button type="button" @click="SubmitUpdatePurchaseRequest" class="btn btn-primary btn-block waves-effect text-center">Resubmit The Item To The Services Purchase Enquiry</button>


                                    </el-row>
                                </el-form>
                            </div>






                            </div>


                            <div class="col-lg-12">
                                <hr> 
                            </div>

                            <div class="col-lg-12">
                                <h3 class="ogeo-text">History</h3>   
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Action Description</b></th>
                                            <th><b>Action By</b></th>
                                            <th><b>Action Level</b></th>
                                            <th><b>Decision-Note</b></th>
                                            <th><b>Action Date</b></th>
                                            <th><b>Timespan Between Actions</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td>{{ item.decision }} - {{ item.decision_notes }}</td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><!--<button class="btn btn-success ml-3" onclick="document.title = 'Purchase Enquiry Line Information';window.print()">Download PDF</button>-->   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>
                        </div>
                                    
                    </div>
                </div>
            </div>
        </div>
        <div id="RFIModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RFIModal" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="RFIDataLoaded">
                        <div  class="modal-header">
                            <h4 class="modal-title text-ogeo">Request For Information <span style="color: black">(<span v-if="CompanySetup.rfq_prefix == ''">RfP-</span><span v-else>{{ CompanySetup.rfq_prefix }}-</span>{{ RFIDetails[0].quotation.quotation_group_id }} / <span v-if="CompanySetup.pe_prefix == ''">PE-</span><span v-else>{{ CompanySetup.pe_prefix }}-</span>{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_group_id }}-{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_ingroup_id }})</span></h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="chat-rbox" style="border: 1px dashed gray; border-radius: 10px; max-height: 450px; overflow-y: auto;">
                                        <ul class="chat-list">
                                            
                                            <!--chat Row -->
                                            <template v-for="message in RFIDetails">
                                              
                                                <li v-if="message.user_id != currentUser.userId">
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-info" >{{ message.message }}</div>
                                                    </div>
                                                    <div class="chat-time" style="min-width: 10%">{{ message.created_at }}</div>
                                                </li>
                                                <li v-else class="reverse">
                                                    <div class="chat-time">{{ message.created_at }}</div>
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - <i v-if="message.reply_id">(In Reponse to ID#: {{ message.reply_id }}) -</i> By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-ogeo" style="min-width: 10%">{{ message.message }}</div>
                                                    </div>
                                                </li>

                                            </template>
                                       
                                        </ul>
                                    </div>
                                    <div v-if="!RFIDetails[0].quotation.status.includes('Canceled') || !RFIDetails[0].quotation.status.includes('Ended') || !RFIDetails[0].quotation.status.includes('Submitted')" class="card-body b-t">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="grid-content">
                                                    <label><b>Select Message To Reply</b></label></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="RFIData.RFIMessageID" placeholder="Select Message ID">   
                                                        <el-option v-for="message in RFIDetails" :key="message.id" :label="'ID#: '+message.id" :value="message.id"></el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <br>
                                                <el-input
                                                    type="textarea"
                                                    rows="6"
                                                    placeholder="Enter Your Request For Information..."
                                                    v-model="RFIData.RFImessage">
                                                </el-input>
                                            </div>
                                            <div class="col-lg-12">
                                                <br>
                                                <button type="button" class="btn btn-block btn-success" @click="SubmitRFI">Submit Answers to RFI</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-12">
                                    <table ref="RFITable" id="RFIMessagesTable" class="display responsive table table-bordered table-hover" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Communication #</th>
                                                <th>By</th>
                                                <th>User Name</th>
                                                <th>Message</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="message in RFIDetails">
                                                <th>{{ message.id }}</th>
                                                <th>{{ message.user_type }}</th>
                                                <template v-if="message.user_type == 'Vendor'">
                                                    <th>{{ message.vendor_user.name }}</th>
                                                </template>
                                                <template v-else>
                                                    <th>{{ message.user.name }}</th>
                                                </template>
                                                <th>{{ message.message }}</th>
                                                <th>{{ message.created_at }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Locate with Marker</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select The Delivery Location by Dragging and Dropping the Pin on The Map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
            
</template>

<script>

    import validate, { isEmpty } from 'validate.js';
import { log } from 'logrocket';

    export default {
        name: 'purchase-enquiry-list',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter Retention Amount')); 
                }
                
                if (!Number.isInteger(value)) {
                    callback(new Error('Please Enter Digits Only'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select Retention Days')); 
                } else {
                    callback();
                }
            };
            return{
                E_images : [],
                p_images : [],
                sd_images : [],
                hostName: window.location.protocol+'//'+window.location.hostname,
                DataTable: "",
                CompanySetup: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                JobNumbers: false,
                searchItemsLoading: false,
                showAmend: false,
                ViewModalInfo: {},
                EditModalInfo: {},
                TmpEditModalInfo: {},
                FullyInvoiced: false,
                FoundItems: {},
                RFIDataLoaded: false,
                RFIDetails: [],
                validatorsList: "",
                RFIData: {
                    RFIMessageID: "",
                    RFImessage: "",
                    VendorId: "",
                    CompanyId: "",
                    QuotationId: "",
                    QuotationType: "",
                    ReplyBy: "Purchase Enquiry Initiator",
                    PEId: "",
                },
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    ServiceOneOff: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    ServiceRC_id: null,
                    StoreItemrequest: "No",
                    RCSelectionService: "",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    ServiceDescription: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    TypeOfServicesContract: '',
                    ContractHeaders:[],
                    TendererContractHeaders:[],
                    VendorOfferContent:[],
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                    ImagesSD: [],

                },
                switchbutton: "Unlinked",
                ValidationInfo: {
                    PurchaseEnquiryId: "",
                    RetentionDays: "",
                },
                contractheader: {
                    name: '',
                    contant: '',
                },
                tenderercontractheader: {
                    name: '',
                    contant: '',
                },
                vendoroffercontent: {
                    name: '',
                    contant: '',
                },
                rules: {
                    RetentionDays: [{
                        required: true,
                        message: "Please Select a Retention Days",
                        trigger: "blur"
                    }]
                },
                PeEditRules: {
                    JobNumber: [{
                        required: true, message: "Please Select Job Number", trigger: ["blur"]
                    }],
                    UnderlyingTransaction: [{
                        required: false,
                        message: "Please Underlying Transaction",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: false,
                        message: "Please Select Sourcing Priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please Select Transaction Type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter Service Description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please Enter Item Description",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Enter Quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: "blur"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{validator: checkRetentionDays, trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: true,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: true,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please Enter Location Name.",
                        trigger: "blur"
                    }]
                },
                ValidationInfoApproval: {
                    PurchaseEnquiryId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                ApprovalRules: {
                    Decision: [{
                        required: true,
                        message: "Please select a approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision Notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            changeTable(){
                if(this.switchbutton == 'Linked')
                    this.$router.push('/app/purchase_enquiry/linked-service-list')
                else
                    this.$router.push('/app/purchase_enquiry/service-list')
            },
            downloadDoc(his) {
                axios .get('/uploads/ServiceSupportingDocs/'+his, {responseType: 'blob'}) 
                .then(response => {
                    console.log(response) 
                let mylink = document.createElement('a');
                mylink.href = URL.createObjectURL(response.data); 
                mylink.download = his; 
                mylink.click() });
            },
            checkArray(data)
            {
                return Array.isArray(data);
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.PurchaseEnquiryLine.ContractHeaders.push({
                        header_name: this.contractheader.name,
                        header_contant: this.contractheader.contant,
                    })
                    this.contractheader.name = ''
                    this.contractheader.contant = ''
                } else {
                    Swal('Error', 'This Contract Header Already Exists.' , 'error'); 
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.ContractHeaders, index);
            },
            existContractHeader() {
                if (this.PurchaseEnquiryLine.ContractHeaders.find(item => item.header_name === this.contractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addVendorOfferContent() {
                if (this.existVendorOfferContent() == true) {
                    this.PurchaseEnquiryLine.VendorOfferContent.push({
                        header_name: this.vendoroffercontent.name,
                        header_contant: this.vendoroffercontent.contant,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.PurchaseEnquiryLine.VendorOfferContent, index);
            },
            existVendorOfferContent() {
                if (this.PurchaseEnquiryLine.VendorOfferContent.find(item => item.header_name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.PurchaseEnquiryLine.TendererContractHeaders.push({
                        header_name: this.tenderercontractheader.name,
                        header_contant: this.tenderercontractheader.contant,
                    })
                    this.tenderercontractheader.name = ''
                    this.tenderercontractheader.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.TendererContractHeaders, index);
            },
            existTendererContractHeader() {
                if (this.PurchaseEnquiryLine.TendererContractHeaders.find(item => item.header_name === this.tenderercontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfoApproval.Decision = "Approved";
                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                        self.ValidationInfoApproval.Decision = "";
                        self.ValidationInfoApproval.DecisionNotes = "";

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                this.ApprovalRules.DecisionNotes[0].required = false;
                
                

            },
            DecisionReject(){
                let self = this;
                this.ApprovalRules.DecisionNotes[0].required = true;
                this.ValidationInfoApproval.Decision = "Rejected";
                this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                self.ValidationInfoApproval.Decision = "";
                                self.ValidationInfoApproval.DecisionNotes = "";

                                self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                        this.ApprovalRules.DecisionNotes[0].required = false;

                        

                    }
                })
            },
            DecisionRejectPermanent(){
                Swal({
                    type: "warning", 
                    title: "Are You Sure You Want to Permanently Cancel This Purchase Enquiry Line ?", 

                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#FF0000",
                    confirmButtonColor: "#32CD32",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.ApprovalRules.DecisionNotes[0].required = true;
                        this.ValidationInfoApproval.Decision = "RejectedPermanent";
                        this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                                    .then(function(response){
                                        Swal({ 
                                            type: response.data.messageType, 
                                            title: response.data.messageTitle, 
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                        self.ValidationInfoApproval.Decision = "";
                                        self.ValidationInfoApproval.DecisionNotes = "";

                                        self.DataTable.ajax.reload( null, false );

                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                                    });

                                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                                this.ApprovalRules.DecisionNotes[0].required = false;

                                

                            }
                        })
                    }
                })
            },
            CancelPurchaseEnquiry(){
                let self = this;
                axios.post( '/api/purchase_enquiry/cancel_purchase_enquiry_request', {PurchaseEnquiryId: this.EditModalInfo.id})
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

            },
            ClearServiceTypeOptions()
            {
                this.PurchaseEnquiryLine.VendorCommercialOffer = "";
            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            SubmitUpdatePurchaseRequest() {

                let self = this;
                if (event) event.preventDefault();

                if ( Object.entries(this.PurchaseEnquiryLine).toString() !== Object.entries(this.TmpEditModalInfo).toString()){
                    Swal({
                        type: 'warning',
                        title: 'Are You Sure You Want To Proceed With The Following Amendment?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((response) => {
                        if(response.value)
                            this.SubmitChanges();
                    });
                }
                else{
                    Swal({
                        type: 'warning',
                        title: 'No Information Has been Amended. Are You Sure You Want To Proceed?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        if(confirm.value)
                            this.SubmitChanges();
                    });
                }


                },
            SubmitChanges(){
                let self = this;
                this.PurchaseEnquiryLine.RequiredDocuments = [];

                this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project_id;

                axios.post('/api/purchase_enquiry/update_purchase_enquiry_request', this.PurchaseEnquiryLine)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Enquiry Resubmission)', 'error');
                    });


                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');
                    
                },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items', {searchQuery: value})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                        //console.log(this.FoundItems);
                    })
            },
            showRateContract(ItemId){

                event.preventDefault();

                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                    .then((response) => {
                        this.ItemRateContractDetails = response.data;
                        this.RateContractDataLoaded = true;
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        if(response.data.sd_images !== null){
                            let image_array_string = response.data.sd_images;
                            let image_string = image_array_string.substring(1, image_array_string.length-1);
                            let final = image_string.replace(/"/g,"");
                            var d= final.split(',');
                            this.sd_images = d;
                        }
                        else
                            this.sd_images = [];
                        
                        this.loadValidations(response.data)
                        this.viewDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            showEditModal(elquentClass, recordId){
                this.EditModalInfo = {};
                this.showAmend = false
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = response.data;
                        if(response.data.sd_images !== null){
                            let image_array_string = response.data.sd_images;
                            let image_string = image_array_string.substring(1, image_array_string.length-1);
                            let final = image_string.replace(/"/g,"");
                            var d= final.split(',');
                            this.sd_images = d;
                        }
                        else
                            this.sd_images = [];
                        this.ValidationInfo.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfoApproval.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfo.RetentionDays = this.EditModalInfo.retention_days;

                        /* Check if Fully Invoiced So User Cannot Adjust The Retention Date (Known Bugs if the vendor invoices the client partially on the Retention Money */
                        if(this.EditModalInfo.invoicing_status == 'Invoicing Completed'){
                            this.FullyInvoiced = true;
                        }

                        /* Updating Edit Model Information */
                        if(this.EditModalInfo.status.indexOf('Rejected') !== -1){
                            this.PurchaseEnquiryLine.ID = this.EditModalInfo.id;
                            this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project.title;
                            this.PurchaseEnquiryLine.Country = this.EditModalInfo.country_of_delivery.split(', ');
                            this.PurchaseEnquiryLine.Cities = this.EditModalInfo.cities_of_delivery.split(', ');
                            this.PurchaseEnquiryLine.UnderlyingTransaction = this.EditModalInfo.underlying_transaction;
                            this.PurchaseEnquiryLine.SourcingPriority = this.EditModalInfo.sourcing_priority;
                            this.PurchaseEnquiryLine.EnquiryType = this.EditModalInfo.enquiry_type;
                            this.PurchaseEnquiryLine.EnquiryFromItemMaster = this.EditModalInfo.enquiry_from_item_master;
                            this.PurchaseEnquiryLine.ItemNumber = this.EditModalInfo.item_id;
                            this.PurchaseEnquiryLine.ItemDescription = this.EditModalInfo.item_description;
                            this.PurchaseEnquiryLine.Quantity = this.EditModalInfo.quantity;
                            this.PurchaseEnquiryLine.UnitOfMeasurement = this.EditModalInfo.u_o_m;
                            this.PurchaseEnquiryLine.ServiceDescription = this.EditModalInfo.service_description;
                            this.PurchaseEnquiryLine.AdvancedPayment = this.EditModalInfo.advanced_payment;
                            this.PurchaseEnquiryLine.RetentionPercentage = this.EditModalInfo.retention_percentage;
                            this.PurchaseEnquiryLine.RetentionDays = this.EditModalInfo.retention_days;
                            this.PurchaseEnquiryLine.RequiredDocuments = this.EditModalInfo.required_documents;
                            this.PurchaseEnquiryLine.PELineNote = this.EditModalInfo.notes;
                            this.PurchaseEnquiryLine.Longitude = this.EditModalInfo.longitude;
                            this.PurchaseEnquiryLine.Latitude = this.EditModalInfo.latitude;
                            this.PurchaseEnquiryLine.LocationName = this.EditModalInfo.location_name;
                            this.PurchaseEnquiryLine.TendererContractHeaders = this.EditModalInfo.tendererchildheaders;
                            this.PurchaseEnquiryLine.ContractHeaders = this.EditModalInfo.childheaders;
                            this.PurchaseEnquiryLine.VendorOfferContent = this.EditModalInfo.vendoroffercontent;
                            this.PurchaseEnquiryLine.TypeOfServicesContract = this.EditModalInfo.type_of_services_contract;
                            this.PurchaseEnquiryLine.VendorCommercialFormat = this.EditModalInfo.vendor_commercial_offer;
                            this.TmpEditModalInfo = Object.assign({}, this.PurchaseEnquiryLine);
                            this.ItemRateContractDetails = [];
                            this.RateContractDataLoaded = false;





                            /*
                             * Google Map inside modal
                             */
                            var initializeMap = function(id) {

                                var myLatLng = {lat: this.PurchaseEnquiryLine.Latitude, lng: this.PurchaseEnquiryLine.Longitude};

                                // Map Options
                                var mapOptions = {
                                    zoom: 10,
                                    center: new google.maps.LatLng(this.PurchaseEnquiryLine.Latitude, this.PurchaseEnquiryLine.Longitude),
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                };


                                // Initialize the map with options (inside #map element)
                                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                                var marker = new google.maps.Marker({
                                    position: myLatLng,
                                    map: map,
                                    draggable: true

                                });


                                google.maps.event.addListener(marker, 'position_changed', function () {
                                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                                });


                                // When modal window is open, this script resizes the map and resets the map center
                                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                                  google.maps.event.trigger(map, "resize");
                                  return map.setCenter(myLatLng);
                                });


                                google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));

                            }

                        }


                        this.loadValidations(response.data)
                        this.editDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    })
                this.editDataLoaded = false;
            },
            EnquiryItemDescriptionSelection(){
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            PurchaseEnquiryRententionUpdate(){
                let self = this;

                this.$refs.PurchaseRequestRetentionUpdateForm.validate((validation) => {
                    if(validation){

                        axios.post('/api/purchase_enquiry/update_purchase_enquiry_request_by_peo', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                 self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Update Purchase Request Details)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    }
                });

            },
            showAllRFIModal(recordId, RecordType, QuotationId) {
                this.RFIData.CompanyId = this.CompanySetup.id
                this.RFIData.QuotationId = QuotationId;
                this.RFIData.PEId = recordId;
                this.RFIData.QuotationType = RecordType;
                this.RFIDetails = [];
                axios.post('/api/data/get_all_pe_rfi_details', {PEId: recordId, QuotationType: RecordType})
                    .then((response) => {
                        this.RFIDetails = response.data;
                        this.RFIDataLoaded = true;
                        // console.log(this.RFIDetails);

                        $('#RFIModal').modal('toggle');

                        this.$nextTick(() => {
                            $('#RFIMessagesTable').DataTable({
                                responsive: true,
                                colReorder: true,
                                dom: '<"html5buttons">Brfgtip',
                                pageLength: 100,
                                lengthMenu: [
                                    [ 100, 500, 1000, -1 ],
                                    [ '100 Records', '500 Records', '1000 Records', 'All Records' ]
                                ],
                                order: [[ 4, "DESC" ]],
                                buttons: [
                                    { extend: 'pageLength', className: 'btn btn-success' },
                                    { extend: 'copy', className: 'btn btn-success' },
                                    { extend: 'excel', className: 'btn btn-success', title: 'QuotationsLinesList' },
                                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] }
                                ],
                            }); 
                        });
                    });

                this.RFIDataLoaded = false;
            },
            SubmitRFI(){
                //check if empty
                if(this.RFIData.RFImessage == ""){
                    Swal('Message Required', 'Message field can not be empty, please type your message', 'warning')
                    return false;
                } else {
                    axios.post( '/api/quotations_management/create_rfi_message', this.RFIData)
                        .then((response) => {
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                            });
                            this.RFIData.RFImessage = "";
                            this.RFIData.RFIMessageID = "";
                        })

                    

                    $('#RFIModal').modal('toggle');
                    this.DataTable.ajax.reload( null, false );
                }         
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },
        mounted(){
            $('.pe-button').addClass('router-link-exact-active router-link-active');
            let self = this;

            var groupColumn = 1;

            axios.get('/api/data/get_company_details')
                .then((response) => {
                    // console.log(response.data);
                    self.CompanySetup = response.data;
                });

            axios.get('/api/data/get_user_projects_list')
                .then((response) => {
                    this.JobNumbers = response.data;
                });


            setTimeout(() => {

                this.DataTable = $('#ProjectPEList').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                    
                    stateSave:  false,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    autoWidth: true,
                    serverSide: true,
                    ajax: '/api/data/get_service_purchase_request_list_for_peos',
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'purchase_enquiry_group_id', name: 'purchase_enquiry_group_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'creator.name', name: 'creator.name' },
                        { data: 'sourcing_priority', name: 'sourcing_priority' },
                        { data: 'service_description', name: 'service_description' },
                        { data: 'location_details', name: 'location_details' },
                        { data: 'countryCities', name: 'countryCities' },
                        { data: 'advanced_payment_info', name: 'advanced_payment_info' },
                        { data: 'retention_payment_info', name: 'retention_payment_info' },
                        { data: 'underlying_transaction', name: 'underlying_transaction' },
                        { data: 'contractheaders', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'tendererheaders', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'vendorofferz', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'type_of_services_contract', name: 'type_of_services_contract' },
                        { data: 'vendor_commercial_offer', name: 'vendor_commercial_offer' },
                        { data: 'service_term', render: function(data, type, full) {
                            if(full.service_one_off == 'One/Off')
                                return "One/Off"
                            else if(data)
                                return data
                            else
                                return "N/A"
                        } },
                        { data: 'validationList', render: function(data, type, full) {
                            if(data)
                                return data
                            else
                                return "N/A"
                        } },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'updated_at_human', render: function(data, type, full){
                            if(full.updated_at_human == null){
                                return '';
                            }else{
                                return full.updated_at_human.substr(0,200);
                            }
                        }},
                        // { data: 'status', name: 'status' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        {"orderable":false, "targets":9},
                        { visible: false, targets: groupColumn },
                        { width: '30%', targets: 6 },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 0 },
                        { responsivePriority: 3, targets: 20 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 9,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ groupColumn, 'ASC' ]],
                    "drawCallback": function ( settings ) {
                        var api = this.api();
                        var rows = api.rows( {page:'current'} ).nodes();
                        var last=null;

                        api.column({page:'current'} ).data().each( function ( group, i ) {
                        var res = group.slice(0, -2);
                            if ( last !== res ) {
                                $(rows).eq( i ).before(
                                    '<tr class="group"><td colspan="12">Purchase Enquiry: '+res+'-1</td></tr>'
                                );
                                last = res;
                            }
                        });
                    },
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'PurchaseEnquiriesList '+new Date() },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    }, 
                    "rowCallback": function( row, data, index ) {
                        // console.log(data);
                        // if (data.status.indexOf('Rejected') !== -1) {
                        //      $(row).css('background-color', 'rgb(252, 51, 23, 0.3)')
                        // }
                        if(data.action.indexOf('edit-placeholder') !== -1){
                            $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                        }
                    } 
                });

                

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.view-similar', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    self.$router.push({ name: 'Similar Purchase Enquiry', params: { id: RecordID }})
                    // this.$router.push('/app/purchase_enquiry/similar_purchase_enquiry/' + RecordID); 
                });


                $('tbody', this.$refs.table).on('click', '.view-rfi', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    var RecordType = $(this).data('recordtype');
                    var Quotationid = $(this).data('quotationid');
                    self.showAllRFIModal(RecordID, RecordType, Quotationid);
                });

                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showEditModal(elquentClass, RecordID);
                });

            }, 500);


        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>
<style>
.el-switch__label * {
        font-size: 18px;
        font-weight: bold;
    }
.el-switch__label.is-active {
    color: rgb(252, 51, 23);
}


</style>

<style scoped>

.sptable tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 0px;
    }

@page {
    size: Executive !important;
    margin: 0mm !important;
    padding: 0mm !important;
}
@media print {
  body * {
    visibility: hidden;
  }
  #PurchaseEnquiryUpdateModalView, #PurchaseEnquiryUpdateModalView * {
    visibility: visible;
  }
  #PurchaseEnquiryUpdateModalView::-webkit-scrollbar { width: 0 !important }
  #PurchaseEnquiryUpdateModalView:last-child {
     page-break-after: auto;
    }
   #PurchaseEnquiryUpdateModalView:last-child {
     page-break-before: none;
    }
  #PurchaseEnquiryUpdateModalView {
    position: relative;
    left: 0;
    margin-top: -550mm;
    width: 400mm !important;
  }
  button {
    display: none;
  }
}
    /* @media print {
  body * {
    visibility: hidden;
  }
  #PurchaseEnquiryUpdateModalView, #PurchaseEnquiryUpdateModalView * {
    visibility: visible !important;
    
  }
  
  #PurchaseEnquiryUpdateModalView::-webkit-scrollbar { width: 0 !important }
  #PurchaseEnquiryUpdateModalView:last-child {
     page-break-after: auto;
    }
   #PurchaseEnquiryUpdateModalView:last-child {
     page-break-before: none;
    }

  #PurchaseEnquiryUpdateModalView {
    position: absolute;
    left: auto;
    top: auto;
    width:100vw !important;
    width:100vh !important;
    padding:0 !important;
    margin:0 !important;
    -ms-overflow-style: none;
}
.vendorInsT {
    display: none;
}
/*
.vendorInsT>thead {
    margin: 0;
    padding: 0;
    float: left;
}
.vendorInsT>tbody {
    margin: 0;
    padding: 0;
    float: left;
    
}
.vendorInsT>thead>th {
    min-width: 100px;

} */

/* td.vendorInsC {
    float: left;
    padding-left: 0px;
    margin-left: 0px;
}
td.vendorInsH {
    min-width: 100px;
    padding-right: 1px;
    margin-right: 0px;
} 
#SCT {
    display: none;
}
button {
    display: none;
}
thead, tbody, tr { width: inherit; }
table {
    width: 100%;
}
  .vendorIns {
    height: auto;
  }
  .vendorInsH {
    width:20% !important;
  }
  .vendorInsC {
    width:80% !important;
    height: 100px !important;
  }
  #SCT th {
    color: red;
  }
  #SCTC, .SCTC {
    width: 100% !important;
    color: grey !important;
  }
} */
    .font-500{
        font-weight: 500;
    }

    .ogeo-background {
        background-color: rgb(252, 51, 23);
    }

    #PurchaseEnquiryUpdateModalView .modal-dialog {
        min-width:80%;
    }

    #PurchaseEnquiryUpdateModalEdit .modal-dialog {
        min-width:80%;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    .el-select-dropdown {
        z-index: 20000 !important;
    }

    .el-popper {
        z-index: 20000 !important;
    }

    .el-scrollbar {
        z-index: 20000 !important;
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }
    .el-scrollbar__wrap{
        z-index: 20000 !important;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .ogeo-text{
        color: rgb(252, 51, 23, 1);
    }

</style>