<template>
        
    <div class="row">
        <div id='Purchase_Enqiury_Form' class="col-lg-7">
            <div class="card" v-if="ProjectDetailsLoaded">
                <div class="card-header">
                    <h4 v-if="this.PurchaseEnquiryLine.EnquiryType == 'Materials'">Create A Material Purchase Enquiry Line</h4>
                    <h4 v-else-if="this.PurchaseEnquiryLine.EnquiryType == 'Service'">Create A Service Purchase Enquiry</h4>
                    <h4 v-else>Create A Purchase Enquiry</h4>
                </div>
                <div class="card-body">
                    <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryForm">

                        <el-row :gutter="20">
                            

                            <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="JobNumber">
                                            <span slot="label"><b>Select A Setup</b></span>

                                            <el-select id="SetupSelection" @change="getProjectCurrency($event)" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select A Setup">
                                                
                                                <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </transition>
                            <el-col :span=24><hr></el-col>
                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                        <span slot="label"><b>Select A Purchase Enquiry Type</b></span>

                                        <el-select @change="EnquiryTypeSelection" :disabled="PElineUp == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Select Enquiry Type (Materials Or Services)">
                                                
                                            <el-option label="Materials" value="Materials"></el-option>
                                            <el-option label="Services" value="Service"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24">
                                <hr>
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="SourcingPriority">
                                        <span slot="label"><b>Sourcing Priority</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                </span>
                                            </span>
                                        </span>
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType">
                                <div class="grid-content">
                                    <el-form-item label="temp" :prop="CompanySetup.additional_required_1 == 'Yes' ? 'UnderlyingTransaction' : ''">
                                        <span slot="label"><b>Enter The Underlying Transaction Reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <strong>The Underlying Transaction Reference</strong> is 
                                                    the reference of the transaction that has led to the initiation
                                                    of this specific Purchase Enquiry. It can be a particular Job 
                                                    Ticket, a Sales Order, a Work Order, a Client Request,…etc
                                                </span>
                                            </span>
                                        </span>

                                        <el-input v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType"><hr></el-col>
                            <!-- Delivery Location Selection -->
                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select Country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 100px">
                                                <span class="tooltip-text2">Select the country where delivery is needed.</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country"> -->
                                        <el-select filterable collapse-tags id="country" class="country" @change="PurchaseEnquiryLine.Cities = [], getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Country">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            

                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select City</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -100px">
                                                <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select City" @change="checkCityRC($event)">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select Countries</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 100px">
                                                <span class="tooltip-text2">Select the countries where delivery is needed. ( Multiple Cities Possible )</span>
                                            </span>
                                        </span>

                                        <el-select filterable multiple collapse-tags id="country" class="country" @change="PurchaseEnquiryLine.Cities = [], RCServiceCityCheck(), getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Countries">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select Cities</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -100px">
                                                <span class="tooltip-text2">Select the cities where delivery is needed. ( Multiple Cities Possible)</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" @change="RCServiceCityCheck" placeholder="Select Cities">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <el-form-item label="temp">
                                    <span slot="label"><b>Select the Delivery Location Address On The Map</b></span>

                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                        <span class="tooltip-item2">
                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                            
                                        </span>
                                        <span class="tooltip-content4 clearfix">
                                            <span class="tooltip-text2">
                                                You may use Google Map and drop a pin in the location of delivery.                                            
                                            </span>
                                        </span>
                                    </span>

                                    <button v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" type="button"  data-toggle="modal" id="get_map" data-target="#DeliveryLocation" class="btn btn-primary btn-block waves-effect text-center">Select Delivery Location</button>
                                </el-form-item>
                            </el-col>


                            
                            <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <el-form-item label="temp" prop="Longitude">
                                    <span slot="label"><b>Delivery Longitude</b></span>
                                    <el-input type="number" readonly  placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <el-form-item label="temp" prop="Latitude">
                                    <span slot="label"><b>Delivery Latitude</b></span>
                                    <el-input type="number"  readonly placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                </el-form-item>
                            </el-col>


                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <el-form-item label="temp" prop="LocationName">
                                    <span slot="label"><b>Location Name</b></span>
                                    <el-input type="text" placeholder="Enter Location Name" v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length"><hr></el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length"><hr data-content="Link To Services Rates' Contracts" class="hr-text"></el-col>


                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length" class="text-center mt-2 mb-2 pt-2 pb-2">

                                <h4 style="font-weight: 700;" v-if="PurchaseEnquiryLine.Cities == ''">No Cities, Countries Selected!</h4>
                                <h4 style="font-weight: 700;" v-if="RCServiceAvailableCheck">There are {{ RCServiceAvailableCheckCount }} Rates’ Contracts Available For The Selected Cities!</h4>
                                <h4 style="font-weight: 700;" v-if="PurchaseEnquiryLine.Cities && PurchaseEnquiryLine.JobNumber && !RCServiceAvailableCheck">There are 0 Rates’ Contracts Available For The Selected Cities!</h4>
                                <h4 style="font-weight: 700;" v-if="PurchaseEnquiryLine.Cities && !PurchaseEnquiryLine.JobNumber">Please Select A Setup In Order To Proceed With Rates’ Contract Selection!</h4>
                                <button v-if="RCServiceAvailableCheck && PurchaseEnquiryLine.JobNumber" type="button" :disabled="!PurchaseEnquiryLine.JobNumber" data-toggle="modal" id="get_rc_service" data-target="#RCSelectionService" class="btn btn-primary btn-block waves-effect text-center" @click="IgnoreRCSelectionService = false">View Valid Rates’ Contract</button>

                            </el-col>

                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr data-content="Unrelated To Services Rates' Contracts" class="hr-text"></el-col>
                            <!-- Delivery Location Selection -->
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" class="text-center mt-2 pt-2">

                                <h5 style="font-weight: 700;">The below information will apply to cities without Rates’ Contract. These cities are <i class="text-ogeo">{{ IgnoreRCSelectionService ? PurchaseEnquiryLine.Cities.join(', ') : NoRCServiceAvailableCheckCities.join(', ') }}</i>.</h5>
 
                            </el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceDescription">
                                        <span slot="label"><b>Enter Service Short Description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter Complete Description Of The Service Required."
                                            v-model="PurchaseEnquiryLine.ServiceDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length && this.CompanySetup.lom_manditory == 'LibrariesNFreeText'">

                                <div>
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                        <span slot="label"><b>Do You Want To Select An Item From The Library of Materials?</b></span>

                                        <el-select @change="EnquiryItemDescriptionSelection" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryFromItemMaster" placeholder="Do You Want To Select An Item From The Library of Materials?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'">
                                    <el-form-item label="temp" prop="ItemNumber">
                                        <span slot="label"><b>Select From The Library Of Materials</b></span>
                                        <el-select style="width: 100%"
                                            popper-class="itemSelect"
                                            v-model="PurchaseEnquiryLine.ItemNumber"
                                            @change="checkRateContract()"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placement="top-start"
                                            placeholder="Search and Select an Item">
                                            <el-option style="max-width: 1200px; min-height: 150px"
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                :value="item.id">

                                                <div class="row" style="border: 2px solid black">
                                                    <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                        <img v-if="!isJSON(item.picture)" class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                            <img v-else class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+JSON.parse(item.picture)[0]">
                                                        
                                                    </div>
                                                    <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr>

                                                                <td>
                                                                    <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                    <span style=" white-space: normal; line-height: 1.5em;">
                                                                        <template v-for="index in 20">      
                                                                            <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                            </span>
                                                                        </template>
                                                                    </span><br><br>
                                                                    <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                    <div class="col-sm-3 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; margin-right: 5%; word-wrap: break-word; border-left: 2px solid black">
                                                        <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                        <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                            <tr>
                                                                <table>
                                                                    <tr>
                                                                        <th><b class="font-700">Vendor Name</b></th>
                                                                        <th><b class="font-700">Vendor Score</b></th>
                                                                        <th><b class="font-700">Expiry Date</b></th>
                                                                        <th><b class="font-700">Unit Rate</b></th>
                                                                        <th><b class="font-700">Lead Time</b></th>
                                                                    </tr>
                                                                    <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                        <td>{{ item.vendor_name }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.date }}</td>
                                                                        <td>{{ item.unit_rate }} USD </td>
                                                                        <td>{{ item.lead_time }}</td>
                                                                    </tr>
                                                                </table>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid black">
                                                        <h4 class="text-center">Last Transactions Info</h4>
                                                        <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                            <tr>
                                                                <table>
                                                                    <tr>
                                                                        <th><b class="font-700">Unit Rate</b></th>
                                                                        <th><b class="font-700">Setup Name</b></th>
                                                                        <th><b class="font-700">City</b></th>
                                                                        <th><b class="font-700">Date</b></th>
                                                                        <th><b class="font-700">Quantity</b></th>
                                                                        <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                    </tr>
                                                                    <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                        <td>{{ item.unit_rate }}</td>
                                                                        <td>{{ item.concern }}</td>
                                                                        <td>{{ item.city }}</td>
                                                                        <td>{{ item.date }}</td>
                                                                        <td>{{ item.quantity }}</td>
                                                                        <td>{{ item.pobid }}</td>
                                                                    </tr>
                                                                </table>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                </div>

                             

                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                    <el-form-item label="temp" prop="ItemDescription">
                                        <span slot="label"><b>Enter The Material Description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Complete Description Of The Item Required."
                                            v-model="PurchaseEnquiryLine.ItemDescription">
                                        </el-input>
                                    </el-form-item>
                                </div>
                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-upload
                                                    style="width:654px;hight:270px;"
                                                    action="#"
                                                    list-type="picture-card"
                                                    :limit="4"
                                                    id='Images'
                                                    :auto-upload="false"
                                                    ref="Images"
                                                    accept=".jpg, .jpeg, .png"
                                                    :on-change="handleChange"
                                                    :on-exceed="handleExceed"
                                                    :on-remove="handleRemove">
                                                    <i class="el-icon-plus"></i>
                                                </el-upload>
                                                <span>You can upload 4 Images maximum size of 1 MB.</span>
                                                
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>


                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">

                                    <el-form-item label="temp" prop="UnitOfMeasurement">
                                        <span slot="label"><b>Enter Unit Of Measurement</b></span>

                                        <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                    </el-form-item>

                                </div>

                            </el-col>

                            <el-col :span="24" v-else-if="this.CompanySetup.lom_manditory == 'Libraries' && PurchaseEnquiryLine.Cities.length">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ItemNumber">
                                        <span slot="label"><b>Select The Item From The Library Of Materials</b></span>
                                        <el-select style="width: 100%"
                                            v-model="PurchaseEnquiryLine.ItemNumber"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placeholder="Search and Select an Item">
                                            <el-option
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'Item ID: ('+item.item_group_id+'-'+item.item_ingroup_id+'), Description: '+item.description"
                                                :value="item.id">
                                                <div class="row">
                                                    <div class="col-lg-2 b-r" style="padding-left: 0px; padding-top: 5px; padding-buttom: 0px; padding-right: 0px;" >
                                                       <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                        
                                                    </div>
                                                    <div class="col-lg-4 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr> 
                                                                <td width="50%"> <span>LoM</span>-{{ item.item_group_id  }}-{{ item.item_ingroup_id  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_1 !== null'><b>{{ item.item_template.field_1.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_1 !== null'> {{ item.field_1.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_2'><b>{{ item.item_template.field_2.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_2'>{{ item.field_2.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_3'><b>{{ item.item_template.field_3.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_3'>{{ item.field_3.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>Purchase Enquiry:</b></td>
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)"> View Rates</td>
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)">
                                                                    <el-tooltip placement="top-start" effect="light">
                                                                        <div slot="content">
                                                                            <div v-if="RateContractDataLoaded">
                                                                                <table style="min-width: 500px" class="table table-bordered">
                                                                                    <thead>
                                                                                        <td align="center"><b>Price</b></td>
                                                                                        <td align="center"><b>Lead Time</b></td>
                                                                                        <td align="center"><b>Contract End Date</b></td>
                                                                                    </thead>
                                                                                    <tbody v-if="ItemRateContractDetails.length > 0">
                                                                                        <tr v-for="(RateContract, key, index) in ItemRateContractDetails" :key="index">
                                                                                            <td align="center">{{ formatPrice(RateContract.vendor_responses[0].unit_rate) }} {{ RateContract.vendor_responses[0].currency }}</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].lead_days }} Day(s)</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].rate_contract_request.rc_end_period.substring(0, 10) }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tbody v-else>
                                                                                        <tr>
                                                                                            <td align="center" colspan="3"> NO CONTRACT AVAILABLE </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div align="center" v-else>
                                                                                <h2>Gathering Data...</h2>
                                                                                <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <i style="line-height: 0px">View Rates</i>
                                                                    </el-tooltip>
                            
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-lg-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px;">
                                                        <div>
                                                            <h5><b>Item Description:</b></h5>
                                                            <p>{{ item.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>
                            
                                    </el-form-item>
                                </div>
                            </el-col>
                            


                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'FreeText' && PurchaseEnquiryLine.Cities.length" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="ItemDescription">
                                        <span slot="label"><b>Enter Material Description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Complete Description Of The Item Required."
                                            v-model="PurchaseEnquiryLine.ItemDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">50/{{PurchaseEnquiryLine.ItemDescription.length}}</span> -->
                                </div>

                                <div class="grid-content">

                                    <el-form-item label="temp" prop="UnitOfMeasurement">
                                        <span slot="label"><b>Enter Unit Of Measurement</b></span>

                                        <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                        <!-- <el-select filterable allow-create style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="Enter Unit Of Measurement">
                                                
                                            <el-option label="Metre" value="Metre"></el-option>
                                            <el-option label="Millimeter" value="Millimeter"></el-option>
                                            <el-option label="Centimeter" value="Centimeter"></el-option>
                                            <el-option label="Decimeter" value="Decimeter"></el-option>
                                            <el-option label="Kilometer" value="Kilometer"></el-option>
                                            <el-option label="Cup" value="Cup"></el-option>
                                            <el-option label="Gill" value="Gill"></el-option>
                                            <el-option label="Pint" value="Pint"></el-option>
                                            <el-option label="Grams" value="Grams"></el-option>
                                            <el-option label="Kilogram" value="Kilogram"></el-option>
                                            <el-option label="Grain" value="Grain"></el-option>
                                            <el-option label="Dram" value="Dram"></el-option>
                                            <el-option label="Ounce" value="Ounce"></el-option>
                                            <el-option label="Pound" value="Pound"></el-option>
                                            <el-option label="Dozen" value="Dozen"></el-option>
                                                
                                        </el-select> -->
                                    </el-form-item>

                                </div>
                            </el-col>

                            

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="Quantity">
                                        <span slot="label"><b>Enter Needed Quantity</b></span>
                                        <el-input min='0.0001' type="number" placeholder="Enter Item Quantity" v-model="PurchaseEnquiryLine.Quantity">
                                        </el-input>
                                    </el-form-item>

                                </div>
                                <hr>
                            </el-col>
                            
                            

                            <el-col :span="24"  v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Documents Required From The Vendors</b></span>
                                            
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    These are the documents that the vendors need to submit along with their commercial proposals, so that their proposals can be assessed.
                                                </span>
                                            </span>
                                        </span>

                                        <el-select collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select Required Document" id="reqDocs" popper-class="reqDocs" ref="reqDocs" @change="OtherDocsVisibility()">
                                            
                                            <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                            <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                            <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                            <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                            <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                            <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                            <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                            <el-option label="Other Documents" value="Other"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                                
                            </el-col>
                            <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                            <thead class="text-white bg-dark w-100">
                                                <tr style="width: 100% !important;">
                                                    <th><span style="color:white;"></span>Other Documents Required From The Vendors
                                                        <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                    Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                    Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                    rights, Litigation...
                                                                </span>
                                                            </span>
                                                        </span> -->
                                                    </th>
                                                    
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in OtherDocTmpHolder"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="PElineUp == 'Y'" v-model="item.name" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="PElineUp == 'Y'" style="padding: 0px 13px" type="button" @click="delOtherDocs(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="PElineUp == 'N'" class="form-control" v-model="OtherDocTmp" placeholder="Please Enter Name of the Required Document" maxlength="200">
                                                    </td>
                                                    <td class="text-center my-auto" width="10%">
                                                        <button v-if="OtherDocTmp && PElineUp =='N'" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0;" @click.prevent="addOtherDocs()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>

                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity"><hr></el-col>

                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity" >
                                <div class="grid-content" v-if="this.showQuestionRateMaterial == true">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                        <span slot="label"><b>This item from the Library of Materials has (the number of existing, valid, Purchase Enquiry) Purchase Enquiry(s) attached to it. Do you want to select one of them (if there are many) it (if there is only one) to reduce the sourcing turnaround time?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    A Rate Contract is a framework agreement signed with a Vendor, for a list of materials, which freezes both Unit Rates' & Lead Times over a given period of time. The use of Purchase Enquiry helps reduce turnaround times since a validated Purchase Enquiry line, linked to a Rate Contract, can reasdily be turned into a Purcase Order, without the need to source proposals.                                            
                                                </span>
                                            </span>
                                        </span>
                                        <el-select @change="ShowPopUpIgnoreRateContract" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ShowPopUpIgnoreRateContract" placeholder="Do You Want To Select An Item From The Library of Materials?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber && checkCity">

                                    <el-form-item label="temp">
                                    <span slot="label"><b>Select From Valid Rates’ Contracts</b></span>
                                    <span style="z-index: 1; margin-right: 15px;" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    Materials Rates’ Contracts 
                                                    are pre-negotiated agreements with vendors intended to 
                                                    freeze unit prices and lead times for items extracted from the 
                                                    Library of Materials, before the demand for them actually 
                                                    arises, so as to reduce cost and workload (Purchase Enquiries 
                                                    linked to Rates’ Contracts can be directly turned into Purchase 
                                                    Orders).
                                                </span>
                                            </span>
                                        </span>
                                    <input type="checkbox" id="rtcheckbox" true-value="yes" false-value="no" @click="ResetRateContractDropdown()" v-model="PurchaseEnquiryLine.rtYN">
                                    <label for="rtcheckbox" :style="PurchaseEnquiryLine.rtYN == 'yes' ? 'color: red': 'color: black'">I'll proceed without a Rates’ Contract</label>
                                        <el-select  v-if="PurchaseEnquiryLine.rtYN === 'no'" style="width: 100%"
                                            v-model="PurchaseEnquiryLine.RateContractSelected"
                                            @change="checkLowestRateContract()"
                                            placeholder="Select Rate Contract">
                                            <el-option style="max-width: 960px; min-height: 150px"
                                                v-for="item in RateContractSelection"
                                                :key="item.value"
                                                :label="'Vendor Name: '+item.vendor_name+' | Vendor Score: '+item.vendor_score+' | Unit Rate: '+item.unit_rate+ 'USD | Lead Time: ' +item.lead_time+' | Rates’ Contract Reference: '+item.rate_contract_reference+' | Rate Contract Expiry Date: '+item.date"
                                                :value="item" v-if="item.city == PurchaseEnquiryLine.Cities">
                                                <div class="row" >
                                                    <div class="col-sm-12 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border: 2px solid">
                                                        <table class="table itemTable lomitemtable mx-auto w-100" style="margin-left:auto;margin-right:auto;">
                                                            <tr>
                                                                <table style="margin-left:auto;margin-right:auto;">
                                                                    <tr>
                                                                        <th><b class="font-700">Vendor Name</b></th>
                                                                        <th><b class="font-700">Vendor Score</b></th>
                                                                        <th><b class="font-700">Expiry Date</b></th>
                                                                        <th><b class="font-700">Unit Rate</b></th>
                                                                        <th><b class="font-700">Lead Time</b></th>
                                                                        <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                                        <th><b class="font-700">Discount Rate</b></th>
                                                                        <th><b class="font-700">Total Procured So Far</b></th>
                                                                        <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                                                        <th><b class="font-700">Retention Payment Arrangement</b></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ item.vendor_name }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.date }}</td>
                                                                        <td>{{ item.unit_rate }} USD </td>
                                                                        <td>{{ item.lead_time }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                    </tr>
                                                                </table>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <hr>
                                </div>
                            </el-col>
                            <!-- <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col> -->
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ServiceOneOff">
                                        <span slot="label"><b>Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time?</b></span>
                                        <el-select style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceOneOff" placeholder="Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time?">
                                                
                                            <el-option label="One/Off" value="One/Off"></el-option>
                                            <el-option label="Over A Period Of Time" value="Over A Period Of Time"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff == 'Over A Period Of Time'">
                                    <el-form-item label="temp" prop="ServiceTerm">
                                        <span slot="label"><b>What Is The Term, In Days, Of The Services?</b></span>
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceTerm" placeholder="What Is The Term, In Days, Of The Services?">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Instructions To Vendors</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the instructions to vendors,
                                                                    as well as the conditions of award. They can include Biding 
                                                                    Instructions, Bid Scoring Sheet, .....
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.TendererContractHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input v-model="tenderheader.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input v-model="tenderheader.contant" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-model="tenderercontractheader.name">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-model="tenderercontractheader.contant">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="tenderercontractheader.name && tenderercontractheader.contant" style="padding: 0px 13px" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Vendor Offer Content</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the instructions to vendors,
                                                                    as well as the conditions of award. They can include Biding 
                                                                    Instructions, Bid Scoring Sheet, .....
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.VendorOfferContent"
                                                    :key="index">
                                                    <td>
                                                        <input v-model="tenderheader.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input v-model="tenderheader.contant" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-model="vendoroffercontent.name">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-model="vendoroffercontent.contant">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendoroffercontent.name && vendoroffercontent.contant" style="padding: 0px 13px" @click.prevent="addVendorOfferContent()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Service Contract Template</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                    Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                    Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                    rights, Litigation...
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in PurchaseEnquiryLine.ContractHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input v-model="header.contant" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px" type="button" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-model="contractheader.name">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-model="contractheader.contant">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="contractheader.contant && contractheader.contant" style="padding: 0px 13px" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr></el-col>
                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp">
                                        <span slot="label"><b>Attach Supporting Documents</b></span><br><br>
                                        <el-upload
                                            style=""
                                            class="upload-demo"
                                            action="#"
                                            :limit="4"
                                            id='Images'
                                            :auto-upload="false"
                                            ref="ImagesSD"
                                            accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .xlsx"
                                            :on-change="handleChangeSD"
                                            :on-exceed="handleExceedSD"
                                            :on-remove="handleRemoveSD">
                                            <button type="button" slot="trigger" class="btn btn-success">Click to Select Files</button>
                                      
                                            <div class="el-upload__tip" slot="tip">JPEG, PNG, DOCX, PDF, XLSX file types are accepted with a size less than 1Mb (Max 4 Files)</div>
                                        </el-upload>
                                    </el-form-item>

                                </div>
                            </el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                <hr>
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="TypeOfServicesContract">
                                        <span slot="label"><b>Type of Service Contract</b></span>
                                        
                                        <el-select @change="ClearServiceTypeOptions()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.TypeOfServicesContract" placeholder="Type of Service Contract">
                                                
                                            <el-option value="Fixed-Priced Contract">Fixed-Priced Contract
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                        <span class="tooltip-text2">
                                                            A fixed-price contract, also known as lump sum contract,<br> 
                                                            is used in situations where the payment doesn’t depend on the<br> 
                                                            resources used or time expended. With fixed-price contracts, <br>
                                                            vendors will estimate the total allowable costs of labor, <br>
                                                            materials and equipment and perform the action specified by<br>
                                                            the contract regardless of the actual cost.<br>
                                                        </span>
                                                    </span>
                                                </span>
                                            </el-option>
                                            <el-option value="Cost-Reimbursement Contract">Cost-Reimbursement Contract
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                        <span class="tooltip-text2">
                                                            With a cost-reimbursement contract, the final<br> 
                                                            total cost is determined when the project is completed<br>
                                                            or at another predetermined date within the contract’s timeframe.<br>
                                                            Before the project is started, the vendor will create<br>
                                                            an estimated cost to give the organization an idea of the budget.<br>
                                                            The purpose of setting this expectation with cost-reimbursement<br> 
                                                            contracts is to establish a ceiling price that the contractor<br>
                                                            shouldn’t exceed without the approval of the organization. At<br>
                                                            the same time, if that ceiling is reached, the vendor can stop work.<br>
                                                        </span>
                                                    </span>
                                                </span>
                                            </el-option>
                                            <el-option value="Cost-plus-Fixed-Fee Contract">Cost-plus-Fixed-Fee Contract
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                        <span class="tooltip-text2">
                                                            A cost-plus contract is type of cost reimbursement contract<br>
                                                            for situations where the organization agrees to pay the <br>
                                                            actual cost of the entire project, including labor, materials,<br>
                                                            and any unexpected expenses. The word “plus” refers to the<br>
                                                            fee that covers the contractor’s profits and overhead. <br>
                                                            In these agreements, the organization agrees to pay that extra<br> 
                                                            amount and expects the vendor to deliver on their promise.<br>

                                                        </span>
                                                    </span>
                                                </span>
                                            </el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-Priced Contract' && PurchaseEnquiryLine.EnquiryType == 'Service' " >
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                        
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                            <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Reimbursement Contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                        
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                            <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-plus-Fixed-Fee Contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                        
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                            <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <!-- <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="PELineNote">
                                        <span slot="label"><b>Title</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Title"
                                            v-model="PurchaseEnquiryLine.Title">
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-col> -->
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.TypeOfServicesContract && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.TypeOfServicesContract && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Will You Consider Offering An Advance Payment?</b></span>
                                        <span style="z-index: 1; margin-right: 15px;" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    I have the possibility to signal whether or not my 
                                                    organization is willing to make an advance payment to
                                                    the awardee vendor, at the time of the Purchase 
                                                    Order. If “Yes” is selected, the vendors will be able to 
                                                    submit, in their offer, their desired advance payment 
                                                    requirement, expressed in percentage of their total
                                                    offer. When a Materials Rates’ Contract is selected for 
                                                    this Purchase Enquiry item, the advance payments 
                                                    arrangements are those applicable to the said 
                                                    Materials’ Rates’ Contract, as agreed with the 
                                                    concerned awardee vendor.
                                                </span>
                                            </span>
                                        </span>
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will You Consider Offering An Advance Payment?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment == 'Yes'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="GuaranteeNeedForAdvancePayment">
                                        <span slot="label"><b>Is A Guarantee Needed From the Awardee Vendor In Return For The Advance Payment?</b></span>

                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment" placeholder="Is a Guarantee Needed From the Awardee Vendor In Return For The Advance Payment?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" >
                                <div class="grid-content">                                    

                                    <el-form-item label="temp" prop="RetentionPercentage">
                                        <span slot="label"><b>Enter The Retention Percentage (If Applicable)</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    Retention is a percentage of the amount certified as due to the awardee 
                                                    vendor, which is temporarily deducted from the amount due and retained by 
                                                    the organization. The purpose of retention is to ensure that the awardee 
                                                    vendor properly completes the activities required of him. This amount will be 
                                                    released back to the awardee vendor at the expiry of the Retention 
                                                    Timeframe. When a Materials Rates’ Contract is selected for the item, the 
                                                    retention arrangements are those applicable to the said Materials’ Rates’ 
                                                    Contract, as agreed with the concerned awardee vendor.
                                                </span>
                                            </span>
                                        </span>

                                        <el-input min='0.0001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="PurchaseEnquiryLine.RetentionPercentage">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col> 

                            <el-col :span="24" v-if="PurchaseEnquiryLine.RetentionPercentage != 0 || PurchaseEnquiryLine.RetentionPercentage != ''" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="RetentionDays">
                                        <span slot="label"><b>Select Retention Timeframe From The Delivery Date</b></span>
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RetentionDays" placeholder="Select Retention Timeframe From The Delivery Date">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>


                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr></el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="PELineNote">
                                        <span slot="label"><b>Addtional Notes</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Purchase Enqiury Line Note."
                                            v-model="PurchaseEnquiryLine.PELineNote">
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr></el-col>

                           <button v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Line To The Purchase Enquiry</button>

                           <button v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryAll.length == 0 && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" type="button" @click="SubmitService" class="btn btn-primary btn-block waves-effect text-center">Submit The Service Purchase Enquiry For Approval</button>
                           <button :disabled = "PurchaseEnquiryAll.filter(item => item.ServiceType == 'Unlinked').length > 0" v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryAll.length > 0 && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" type="button" @click="AddToServiceList(null, 'Unlinked')" class="btn btn-primary btn-block waves-effect text-center btn-unlinked-service">Add The Line To Service Purchase Enquiry</button>


                        </el-row>
                    </el-form>

                </div>
            </div>

            <div class="card" v-else>
                <div class="card-header">
                    <h4>Create A Purchase Enquiry Line</h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Required Details...</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of PEs in the list -->
        <div id="Purchase_Enqiury_List" class="col-lg-5" v-if="PurchaseEnquiryLine.EnquiryType">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="PurchaseEnquiryAll.length > 0 && PurchaseEnquiryLine.EnquiryType == 'Materials'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Material(s) Purchase Enquiry Lines</h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Items Details</th>
                                            <th>Quantity</th>
                                            <th>Expected Cost, {{this.projectcurrency.substring(0, 3)}}</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" :key="index">
                                            <td v-if="PurchaseEnquiry.ItemNumber != ''">
                                                <span v-if="PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix == null">LoM</span>
                                                <span v-else>{{ PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix }}</span>-{{ PurchaseEnquiry.ItemLibraryDescription.item_group_id }}-{{ PurchaseEnquiry.ItemLibraryDescription.item_ingroup_id }}</td>
                                            <td v-else>{{ PurchaseEnquiry.ItemDescription.substring(0, 20) + '...' }}</td>
                                            <td>{{ PurchaseEnquiry.Quantity }}</td>
                                            <td v-if="PurchaseEnquiry.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiry.RateContractSelected.unit_rate">{{ formatPrice(PurchaseEnquiry.RateContractSelected.unit_rate * PurchaseEnquiry.Quantity)}}</td>
                                            <td v-else> - </td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <a style="padding: 6px 8px;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p v-if="this.TotalExpectedPrice">Total Expected Cost For the Lines With Purchase Enquiry, Past Purchase Orders or Past Vendors’ Offers:<b class="font-weight-bold"> {{formatPrice(this.TotalExpectedPrice)}} {{this.projectcurrency.substring(0, 3)}}.</b></p>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-primary btn-block waves-effect text-center">Submit The Materials Purchase Enquiry For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="RCSelectionService && PurchaseEnquiryLine.EnquiryType == 'Service'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Service(s) Purchase Enquiry Lines</h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <h5>Service Purchase Enquiry Line – Linked To Services Rates’ Contracts</h5>
                                <table class="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>Services Rates’ Contract | Service Lines Units Of Measurement</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                        <tbody style="display:;width: 100% !important; max-height: 200px; overflow-y: scroll;">
                                            <tr v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" v-if="PurchaseEnquiry.ServiceType == 'Linked'" :key="index">
                                                <td>{{ PurchaseEnquiry.UnitOfMeasurement }}</td>
                                                <td>{{ PurchaseEnquiry.Quantity }}</td>
                                                <td width="25%">
                                                    <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                    <a style="padding: 6px 8px;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)"><i class="fa fa-remove"></i></a>
                                                </td>
                                            </tr>
                                            <tr v-if="PurchaseEnquiryAll.filter(item => item.ServiceType == 'Linked').length == 0">
                                                <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                            </tr>
                                        </tbody>
                                </table>
                                <hr>
                                <h5>Service Purchase Enquiry Line – Unrelated To Services Rates’ Contracts</h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Service Short Description</th>
                                            <th>Type of Service Contract</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="unlinkedServiceList">
                                        <tr class="peServiceLineUnlinked" v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" v-if="PurchaseEnquiry.ServiceType == 'Unlinked'" :key="index">
                                            <td>{{ PurchaseEnquiry.ServiceDescription }}</td>
                                            <td>{{ PurchaseEnquiry.TypeOfServicesContract }}</td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <a style="padding: 6px 8px;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                        <tr v-if="PurchaseEnquiryAll.filter(item => item.ServiceType == 'Unlinked').length == 0">
                                            <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-primary btn-block waves-effect text-center">Submit The Services Purchase Enquiry For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="RCSelectionService && PurchaseEnquiryLine.EnquiryType == 'Service'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Service(s) Purchase Enquiry Lines</h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <h5>Service Purchase Enquiry Line – Linked To Services Rates’ Contracts</h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Services Rates’ Contract | Service Lines Units Of Measurement</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr>
                                <h5>Service Purchase Enquiry Line – Unrelated To Services Rates’ Contracts</h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Service Short Description</th>
                                            <th>Type of Service Contract</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-primary btn-block waves-effect text-center">Submit The Materials Purchase Enquiry For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="PurchaseEnquiryAll.length == 0 && PurchaseEnquiryLine.EnquiryType == 'Materials'" :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4>Material(s) Purchase Enquiry Lines</h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>Items Details</b></th>
                                        <th><b>Quantity</b></th>
                                        <th><b>Expected Cost, {{this.projectcurrency.substring(0, 3)}}</b></th>
                                        <th><b>Actions</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-primary btn-block waves-effect text-center disabled">Submit The Purchase Enquiry For Approval </button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>



        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Locate with Marker</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select The Delivery Location by Dragging and Dropping the Pin on The Map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="RCSelectionService" tabindex="-1" aria-labelledby="RCSelectionService" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-ogeo">Service Rates’ Contracts Selection Panel</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <table class="table tg" data-component="datatable" data-locale="en" >
                            <thead>
                            <tr>
                                <th class="tg-0lax" rowspan="2">Selected Cities, Countries</th>
                                <th class="tg-0lax" colspan="3">Valid Rates Contracts</th>
                                <th class="tg-0lax" rowspan="2">Expiry Date</th>
                                <th class="tg-0lax" rowspan="2">Unit Rate</th>
                                <th class="tg-0lax" rowspan="2">Quantity</th>
                                <th class="tg-0lax" rowspan="2">Action</th>
                            </tr>
                            <tr>
                                <th class="tg-0lax" width="15%">Vendor Reference & Score</th>
                                <th class="tg-0lax">Short Description</th>
                                <th class="tg-0lax">Service Line Unit Of Measurement</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr  v-for="(item, index) in RateContractSelectionService" v-if="PurchaseEnquiryLine.Cities.includes(item.city)" :key="index">
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.city == item.city).length" v-if="index > 0 ? (item.city !== RateContractSelectionService[index-1].city) : 1">{{ item.city }}</td>
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.reference == item.reference && PurchaseEnquiryLine.Cities.includes(subitem.city)).length" v-if="index > 0 ? (item.reference !== RateContractSelectionService[index-1].reference) : 1"><b>Vendor ID: </b> {{ item.reference }}, <br><b>Vendor Name: </b> {{ item.vendor_name }}, <br><b>Vendor Score: </b> {{ item.vendor_score }}</td>
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.short_desc == item.short_desc && PurchaseEnquiryLine.Cities.includes(subitem.city)).length" v-if="index > 0 ? (item.short_desc !== RateContractSelectionService[index-1].short_desc) : 1">{{ item.short_desc }}</td>
                                <td class="tg-0lax">{{ item.u_o_m }}</td>
                                <td class="tg-0lax">{{ item.exp }}</td>
                                <td class="tg-0lax">{{ item.unit_rate }}</td>
                                <td class="tg-0lax"><input style="text-align: center;" class="RCAddQuan" type="number" min="0" :id="'RCServiceQ'+index" @keyup="checkQuanValue(index)" required></td>
                                <td class="tg-0lax" style="max-width: 200px;"><button disabled type="button" :id="'RCServiceBA'+index" class="btn btn-primary btn-block waves-effect text-center text-wrap RCAddBtn" @click="AddToServiceList(index, 'Linked')">Link & Add To The Services Purchase Enquiry</button>
                                <button hidden type="button" :id="'RCServiceBR'+index" class="btn btn-danger btn-block waves-effect text-center text-wrap RCRemBtn" @click="RemoveFromServiceList(index, 1)">Unlink & Remove From The Services Purchase Enquiry</button></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal" @click="IgnoreRCServiceSelection()">Ignore Services Rates' Contracts</button>
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="PEDetailsModalId" tabindex="-1" aria-labelledby="PEDetailsModalId" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="PELineShow">
                    <div class="modal-header">
                        <div style="width:40%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-if="PEModalInfo.EnquiryType == 'Materials'">Materials Purchase Enquiry Line ID# {{ PEModalInfoKey + 1 }}</h4>
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-else-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">Service Purchase Enquiry Line ID# {{ PEModalInfoKey + 1 }} - Linked To A Valid Rates’ Contract</h4>
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-else-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">Service Purchase Enquiry Line ID# {{ PEModalInfoKey + 1 }} - Unrelated To Services Rates’ Contract</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:60px;height:60px;margin-left:50px;border-radius:25px" class="my-auto">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="row">

                            <!-- Purchase Enquiry Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td>
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ PEModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ PEModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ PEModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(PEModalInfo.project.value - PEModalInfo.project.consumed_budget) }} {{ PEModalInfo.project.currency }}</span>
                                            </td>
                                        </tr>
                                        
                                </table>
                            </div>
                            <!-- <div class="col-lg-12">
                                <table class="table">
                                   
                                </table>
                            </div> -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td><b class="font-700">Line Initiated By: </b></td>
                                        <td>{{ currentUser.Name }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Underlying Transaction: </b></td>
                                        <td>{{ PEModalInfo.UnderlyingTransaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Type: </b></td>
                                        <td>{{ PEModalInfo.EnquiryType }}<span v-if="PEModalInfo.EnquiryType == 'Service'">s</span></td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ PEModalInfo.SourcingPriority }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Delivery Address Details:</b></td>
                                        <td>
                                            <span v-if="PEModalInfo.EnquiryType == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ PEModalInfo.Country }}</span>
                                            <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ PEModalInfo.Country.toString() }}</span><br>
                                            <span v-if="PEModalInfo.EnquiryType == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ PEModalInfo.Cities }}</span>
                                            <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ PEModalInfo.Cities.toString() }}</span><br>
                                            <span><b class="font-700 font-italic">Location Name:</b> {{ PEModalInfo.LocationName }}</span><br>
                                            <span><b class="font-700 font-italic">Longitude:</b> {{ PEModalInfo.Longitude }}</span><br>
                                            <span><b class="font-700 font-italic">Latitude:</b> {{ PEModalInfo.Latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>

                                </table>
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments COnsidered For The Items Sourced Under The Materials Purchase Enquiry?</b></td>
                                        <td>{{ PEModalInfo.AdvancedPayment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ PEModalInfo.GuaranteeNeedForAdvancePayment ? PEModalInfo.GuaranteeNeedForAdvancePayment : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Purchase Enquiry?</b></td>
                                        <td>{{ PEModalInfo.RetentionPercentage ? PEModalInfo.RetentionPercentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ PEModalInfo.RetentionDays ? PEModalInfo.RetentionDays + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>                                 
                                </table>
                            </div>
                            <!-- Purchase Enquiry General Infomation Panel End -->

                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryType == 'Materials'"> 
                                
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                        <td v-if="PEModalInfo.RequiredDocuments">
                                            <ul class="d-inline-flex" v-for="(document, index) in PEModalInfo.RequiredDocuments">
                                                <li>{{ document }}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Documents Required
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials' && PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Library Of Materials Item Details: </b></td>
                                        <td>
                                            <span v-if="!isJSON(PEModalInfo.ItemLibraryDescription.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+PEModalInfo.ItemLibraryDescription.picture"></span>
                                            <span class="text-center" v-else v-for="(img, index) in JSON.parse(PEModalInfo.ItemLibraryDescription.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></span>
                                            <br><br><span><b class="font-700"><span v-if="PEModalInfo.ItemLibraryDescription.company.lom_prefix == null">LoM</span><span v-else>LoM-</span>{{ PEModalInfo.ItemLibraryDescription.item_group_id }}-{{ PEModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                            <span>
                                                <template v-for="index in 20">      
                                                    <span v-if="PEModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                        <b class="font-700 text-uppercase">{{ PEModalInfo.ItemLibraryDescription.item_template['field_'+index] }}:</b> {{ PEModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <!-- <span><b>Quantity:</b> {{ PEModalInfo.Quantity }}</span><br> <br> -->
                                            <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ PEModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                            <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(PEModalInfo.ItemLibraryDescription.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Material Details: </b></td>
                                        <td class="dont-break-out">
                                            <span v-if="PEModalInfo.Images.length > 0" v-for="(img, index) in PEModalInfo.Images">
                                                <img style="width: 7vw; height: 7vw; text-align: center !important;" :src="img">
                                            </span><br><br>
                                            <span> {{ PEModalInfo.ItemDescription }}</span><br><br>
                                            <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ PEModalInfo.UnitOfMeasurement.toUpperCase() }}</span><br>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                        <td><b class="font-700">Quantity: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            {{  PEModalInfo.Quantity }}
                                        
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                        <td width="40%"><b class="font-700">Selected Rates’ Contract: </b></td>
                                        <td v-if="PEModalInfo.RateContractSelected.vendor_name">
                                            <span><b class="font-700">Vendor Name:</b> {{ PEModalInfo.RateContractSelected.vendor_name }}</span><br>
                                            <span><b class="font-700">Vendor Score:</b> {{ PEModalInfo.RateContractSelected.vendor_score }}</span><br>
                                            <span><b class="font-700">Expiry Date:</b> {{ PEModalInfo.RateContractSelected.date }}</span><br>
                                            <span><b class="font-700">Unit Rate:</b> {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate) }}  {{this.projectcurrency}}</span><br>
                                            <span><b class="font-700">Lead Time:</b> {{ PEModalInfo.RateContractSelected.lead_time }}</span><br>
                                        </td>
                                        <td colspan="2" v-else-if="PEModalInfo.EnquiryFromItemMaster == 'No'">
                                            This Isn't An Item From The Library of Materials. There Are No Rates’ Contracts For It.
                                        </td>
                                        <td colspan="2" v-else>
                                            No Rates’ Contract was selected for this material.
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                        <td width="40%"><b class="font-700">Expected Cost: </b></td>
                                        <td colspan="2" v-if="PEModalInfo.RateContractSelected.unit_rate"><b class="font-700"> {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate * PEModalInfo.Quantity) }} {{this.projectcurrency}} </b> From The Selected Rates’ Contract</td>
                                        <td colspan="2" v-else-if="PEModalInfo.EnquiryFromItemMaster == 'No'">Not Applicable As It Is A Free-Text Described Item (Not An Item From The Library of Materials.)</td>
                                        <td colspan="2" v-else>Not Available</td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                        <td width="40%"><b class="font-700">Additional Notes: </b></td>

                                        <td v-if="!PEModalInfo.PELineNote">Not Additional Notes</td>
                                        <td v-else>{{ PEModalInfo.PELineNote }}</td>

                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                        <td width="40%"><b class="font-700">Awardee Vendor: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            Not Yet Awarded
                                        
                                        </td>


                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Materials Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryType == 'Service'">
                                <table class="table">
                                    <!-- <tr align="center" v-if="PEModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in PEModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td width="40%"><b class="font-700 text-ogeo">Service Rates’ Contract Reference: </b></td>
                                        <td>
                                            <span><b class="font-700 font-italic">Vendor ID: </b>{{   RateContractSelectionService[ PEModalInfo.ServiceRC_id ].reference }}</span><br>
                                            <span><b class="font-700 font-italic">Vendor Name: </b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].vendor_name }}</span><br>
                                            <span><b class="font-700 font-italic">Vendor Email: </b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].vendor_email }}</span><br>
                                            <span><b class="font-700 font-italic">Vendor Score: </b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].vendor_score }}</span><br>  
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service'">
                                        <td width="40%"><b class="font-700">Service Rates’ Contract Description: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.ServiceDescription }}</span><br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time: </b></td>
                                        <td>{{ PEModalInfo.ServiceOneOff }}</td>
                                    </tr> 
                                    <tr v-if="PEModalInfo.ServiceOneOff == 'Over A Period Of Time'">
                                        <td><b class="font-700">The Term Of The Service Contract, In Days: </b></td>
                                        <td>{{ PEModalInfo.ServiceTerm }} Days</td>
                                    </tr> 
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td width="40%"><b class="font-700">Service Rates’ Contract Start Date & End Date: </b></td>
                                        <td>
                                            <span>{{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].strt }} - {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].exp }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td colspan="2"><div class="text-center m-0 p-0"><button class="text-center" style="background-color: red; border: none; color: white; height: 40px; width: 400px;">Services Rates’ Contract</button></div></td>
                                       
                                    </tr>
                                    <!-- <tr v-if="PEModalInfo.EnquiryType == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Supporting Documents:</b></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul style="display: inline-flex" v-for="(his, index) in PEModalInfo.ImagesSD">
                                                            <a :href="window.URL.createObjectURL(blob)" @click=""><li>{{ his }}</li></a>
                                                        </ul>
                                                    </td>
                                                </tr>    
                                            </table>
                                        </td>
                                    </tr> -->
                                    <tr class="vendorIns" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.TendererContractHeaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                    <td class="vendorInsC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor Offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.VendorOfferContent">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                    <td class="vendorInsC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="" id="SCT" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Contract Draft</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 SCTH">Header</b></th>
                                                    <th id="SCTC"><b class="font-700 SCTC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.ContractHeaders">
                                                    <td class="text-uppercase font-700 SCTH col-lg-2">{{ header.name }}</td>
                                                    <td class="SCTC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td width="40%"><b class="font-700">Service Line Unit of Measurement: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.UnitOfMeasurement }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td width="40%"><b class="font-700">Unit Rate: </b></td>
                                        <td>
                                            <span>{{ RateContractSelectionService[PEModalInfo.ServiceRC_id].unit_rate }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td width="40%"><b class="font-700">Quantity: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.Quantity }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td width="40%"><b class="font-700">Type Of Service Contract: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.TypeOfServicesContract }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td width="40%"><b class="font-700">Commercial Offer Format: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.VendorCommercialOffer }}</span><br>

                                        </td>
                                    </tr>
                                    <!-- <tr v-if="PEModalInfo.EnquiryType == 'Service'">
                                        <td width="40%"><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr> -->
                                    
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->

                            <!-- List of Valid Rates Contracts -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                <table class="table">
                                    <tr class="text-ogeo text-center">
                                        <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                    </tr>
                                    <table>
                                        <tr>
                                            <th><b class="font-700">Vendor Name</b></th>
                                            <th><b class="font-700">Country Cities</b></th>
                                            <th><b class="font-700">Unit Rate</b></th>
                                            <th><b class="font-700">Expiry Date</b></th>
                                            <th><b class="font-700">Lead Time</b></th>
                                            <th><b class="font-700">Volume To Trigger Discount</b></th>
                                            <th><b class="font-700">Discount Rate</b></th>
                                            <th><b class="font-700">Total Procured So Far</b></th>
                                            <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                            <th><b class="font-700">Retention Payment Arrangement</b></th>
                                        </tr>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                            <td>{{ item.vendor_name }}</td>
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </table>
                                </table>
                            </div>
                            <!-- List of Valid Rates Contracts End -->

                        </div>
                        
                    </div>
                    

                    <div class="col-lg-12">
                        <hr>
                        <div class="d-inline-block">
                            <span><b>Date:</b> {{ new Date() }}</span><br>
                            <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Materials Purchase Enquiry Line ID# '+window.PEModalInfoKey + 1;window.print()">Download PDF</button>   <br><br>
                        </div>
                        <div class="d-inline-block pull-right">
                            <span><img width="250px" :src="hostName+'/assets/images/poweredby.png'" alt=""></span>
                        </div>
                    </div>

                </div>
            </div>v 
        </div>

    </div>


            
</template>

<script>
    
    //import { CustomJs } from '../../helpers/custom.js';
    import validate from 'validate.js';

    export default {
        name: 'new-purchase-enquiry',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter Retention Amount')); 
                }
                
                if ((value + '').replace(/^-?\d*\.?|0+$/g, '').length > 2) {
                    callback(new Error('Please Enter No More Than Two Decimal Places.'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select Retention Days')); 
                } else {
                    callback();
                }
                
            };
            return {

                hostName: window.location.protocol+'//'+window.location.hostname,
                jsDoc: document,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
			    cities: [],
                checkCity: false,
                JobNumbers: [],
                FoundItems: {},
                docFilelist: [],
                ReqDocTmp: [],
                OtherDocTmp: '',
                OtherDocTmpHolder: [],
                ProjectDetailsLoaded: false,
                ItemRateContractDetails: [],
                RateContractDataLoaded: false,
                RCSelectionService: false,
                IgnoreRCSelectionService: false,
                RCServiceAvailableCheck: false,
                RCServiceAvailableCheckCount: 0,
                NoRCServiceAvailableCheckCount: 0,
                RCServiceAvailableCheckCities: [],
                NoRCServiceAvailableCheckCities: [],
                NoRCServiceSelectedCities: [],
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                PELineShow: false,
                PElineUp: 'N',
                PElineFlag: false,
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
                      country: 'Afghanistan',
                      city: 'Kabul',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                RateContractSelectionService: [
					{
			          reference: 1,
			          vendor_name: 'Gucci',
			          vendor_email: 'vendor@gucci.com',
			          vendor_score: '25',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+15 years) Software Development Engineer',
			          unit_rate: '$3000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'Yes',
                      rtp: 90,
                      stpD: 180,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 50,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '95',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+5 years) SEO Specialist',
                      unit_rate: '$5000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'Yes',
                      rtp: 40,
                      stpD: 130,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 50,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '95',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+5 years) Market Specialist',
                      unit_rate: '$1000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Kandahar',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'No',
                      rtp: 60,
                      stpD: 150,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 10,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '75',
                      short_desc: 'Retained Legal Services Agreement',
                      u_o_m: '10 hours of 01 experienced (+5 years) LAW Specialist',
                      unit_rate: '$4000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Kabul',
			          rate_contract_reference: 'Gucci',
                      adp: 'No',
                      adpG: 'No',
                      rtp: 0,
                      stpD: null,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
                    
				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    ServiceOneOff: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    ServiceRC_id: null,
                    StoreItemrequest: "No",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    ServiceDescription: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    TypeOfServicesContract: '',
                    ContractHeaders:[],
                    TendererContractHeaders:[],
                    VendorOfferContent:[],
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                    ImagesSD: [],

                },
                minVal: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                PEModalInfoKey: "",
                PEModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true, message: "Please Select A Setup", trigger: ["blur"]
                    }],
                    Country: [{
                        required: true, message: "Please Select A Country", trigger: ["blur"]
                    }],
                    Cities: [{
                        required: true, message: "Please Select A City", trigger: ["blur"]
                    }],
                    TendererContractHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur"]
                    }],
                    VendorOfferContent: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur"]
                    }],
                    ContractHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur"]
                    }],
                    ServiceOneOff: [{
                        required: true,
                        message: "Please Select",
                        trigger: "blur"
                    }],
                    ServiceTimePeriod: [{
                        required: true,
                        message: "Please Select",
                        trigger: "blur"
                    }],
                    VendorCommercialOffer: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    VendorCommercialFormat: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    TypeOfServicesContract: [{
                        required: true,
                        message: "Please Type Of Services Contract",
                        trigger: "blur"
                    }],
                    UnderlyingTransaction: [{
                        required: true,
                        message: "Please Underlying Transaction",
                        trigger: "blur"
                    }],
                    ContractHeaders: [{
                        required: true,
                        message: "Please Header",
                        trigger: "blur"
                    }],
                    Images: [{
                        required: true,
                        message: "Please Select Image",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: true,
                        message: "Please Select Sourcing Priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please Select Transaction Type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter Service Description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please Enter Item Description",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Enter Quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please Select Term of Service",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: "change"
                    }],
                    GuaranteeNeedForAdvancePayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: "change"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please Select Retention Days", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: false,
                        message: "Please Enter Location Name.",
                        trigger: "blur"
                    }]
                },
                showQuestionRateMaterial: false,
                RateContractPurcase: [],
                TotalExpectedPrice: '',
                contractheader: {
                    name: '',
                    contant: '',
                },
                tenderercontractheader: {
                    name: '',
                    contant: '',
                },
                vendoroffercontent: {
                    name: '',
                    contant: '',
                },
                projectcurrency: '',
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            checkQuanValue(e) {
                if($('#RCServiceQ'+e).val() == 0)
                    $('#RCServiceBA'+e).attr('disabled', true);
                else
                    $('#RCServiceBA'+e).attr('disabled', false);
            },
            checkChildren(){
                if($('#unlinkedServiceList').children().length == 0 || !$('#unlinkedServiceList tr').hasClass('peServiceLineUnlinked'))
                    return true;
                else
                    return false;
            },
            OtherDocsVisibility(){
                if(this.ReqDocTmp.includes('Other')){
                  this.$refs.reqDocs.blur();
                  $('.reqDocs').hide();
                }
            },
            ResetRateContractDropdown()
            {
                this.PurchaseEnquiryLine.RateContractSelected = ''
            },
            checkProjectExpiry(e){
                var val = axios.get('/api/data/get_project_expiry/' + e)
                    .then((response) => {
                        if(new Date(response.data[0]) > new Date())
                            return true;
                        else{
                            this.PurchaseEnquiryLine.JobNumber = '';
                            Swal('The Selected Setup Has Expired', 'Using this concern will be possible if those holding the role of Setup Budgets Initiator extend its end date.', 'error');
                            return false;    
                        }
                    })
                return val;
            },
            checkCityRC(e){

                this.checkCity = false;
                this.RateContractSelection.forEach(item => {
                    if(item.city == e)
                        this.checkCity = true;
                })
            },
            RCServiceCityCheck(){
                this.RCServiceAvailableCheckCount = 0;
                this.NoRCServiceAvailableCheckCount = 0;
                this.RCServiceAvailableCheckCities = [];
                this.NoRCServiceAvailableCheckCities = [];
                this.NoRCServiceSelectedCities = [];
                if(this.PurchaseEnquiryLine.Cities)
                    this.PurchaseEnquiryLine.Cities.forEach( item => this.RateContractSelectionService.some(subitem => item.includes(subitem.city) ? this.RCServiceAvailableCheckCount++ : false) ? (this.RCServiceAvailableCheckCities.push(item)) : (this.NoRCServiceAvailableCheckCount++, this.NoRCServiceAvailableCheckCities.push(item)) )
                if(this.RCServiceAvailableCheckCount > 0)
                    this.RCServiceAvailableCheck = true, this.NoRCServiceSelectedCities = Array.from(this.RCServiceAvailableCheckCities);
                else
                    this.RCServiceAvailableCheck = false;    
            },
            getProjectCurrency(e)
            {
                if(this.checkProjectExpiry(e))
                    axios.get('/api/data/get-project-currency/' + e)
                    .then((response) => {   
                        this.projectcurrency = response.data[0]
                    })
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.PurchaseEnquiryLine.ContractHeaders.push({
                        name: this.contractheader.name,
                        contant: this.contractheader.contant,
                    })
                    this.contractheader.name = ''
                    this.contractheader.contant = ''
                } else {
                    Swal('Error', 'This Contract Header Already Exists.' , 'error'); 
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.ContractHeaders, index);
            },
            existContractHeader() {
                if (this.PurchaseEnquiryLine.ContractHeaders.find(item => item.name === this.contractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addVendorOfferContent() {
                if (this.existVendorOfferContent() == true) {
                    this.PurchaseEnquiryLine.VendorOfferContent.push({
                        name: this.vendoroffercontent.name,
                        contant: this.vendoroffercontent.contant,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.PurchaseEnquiryLine.VendorOfferContent, index);
            },
            existVendorOfferContent() {
                if (this.PurchaseEnquiryLine.VendorOfferContent.find(item => item.name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.PurchaseEnquiryLine.TendererContractHeaders.push({
                        name: this.tenderercontractheader.name,
                        contant: this.tenderercontractheader.contant,
                    })
                    this.tenderercontractheader.name = ''
                    this.tenderercontractheader.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.TendererContractHeaders, index);
            },
            existTendererContractHeader() {
                if (this.PurchaseEnquiryLine.TendererContractHeaders.find(item => item.name === this.tenderercontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addOtherDocs() {
                if (this.existOtherDocs() == true) {
                    this.OtherDocTmpHolder.push({name: this.OtherDocTmp})
                    this.OtherDocTmp = ''
                } else {
                    Swal('Error', 'This Document Already Exists.' , 'error'); 
                }
            },
            existOtherDocs() {
                if (this.OtherDocTmpHolder.find(item => item.name === this.OtherDocTmp)) {
                    return false;
                } else {
                    return true;
                }
            },
            delOtherDocs(index) {
                this.$delete(this.OtherDocTmpHolder, index);
            },
            ShowPopUpIgnoreRateContract()
            {
                if(this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract == "No")
                {
                    Swal('Warning', 'Please Note That You Have Ignored A Rates’ Contracts With A Lower Unit Rate.' , 'error'); 
                    $(".swal2-tital").css('background-color', '#000');
                }
            },
            checkRateContract()
            {
                event.preventDefault();

                axios.post('/api/data/check_item_rate_contract_details', this.PurchaseEnquiryLine.ItemNumber)
                    .then((response) => {
                        if(response.data)
                        {
                            this.showQuestionRateMaterial = false
                        }
                        else
                        {
                            this.showQuestionRateMaterial = true
                        }
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            checkLowestRateContract()
            {

                var numbers = [25, 30, 10];
							
                var sorted = numbers.slice().sort(function(a, b) {
                return a - b;
                });

                var smallest = sorted[0],                      
                    secondSmallest = sorted[1],                
                    secondLargest = sorted[sorted.length - 2], 
                    largest  = sorted[sorted.length - 1];
                if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate > smallest)
                {
                    Swal('Warning', 'Please Note That You Have Ignored A Rates’ Contract With A Lower Unit Rate.' , 'error'); 
                }

            },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.PurchaseEnquiryLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                        //console.log(this.FoundItems);
                    })
            },
            EnquiryTypeSelection: function(){
                
                if(this.PurchaseEnquiryLine.EnquiryType == "Materials"){
                    $('#Purchase_Enqiury_List').removeClass('animated fadeOutRight');
                    $('#Purchase_Enqiury_List').addClass('animated fadeInRight');
                    $('#Purchase_Enqiury_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                } else if(this.RCSelectionService) {
                    $('#Purchase_Enqiury_List').removeClass('animated fadeOutRight');
                    $('#Purchase_Enqiury_List').addClass('animated fadeInRight');
                    $('#Purchase_Enqiury_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                } else {
                    $('#Purchase_Enqiury_List').addClass('animated fadeOutRight');
                    setTimeout(function() {
                        $('#Purchase_Enqiury_Form').removeClass('col-lg-7').addClass('col-lg-12');
                    }, 100);

                }
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RateContractSelected = {};
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.PurchaseEnquiryLine.TypeOfServicesContract = ""
                this.PurchaseEnquiryLine.VendorCommercialOffer = ""
            },
            handleChangeSD(file, fileList){
                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isPDF = file.raw.type === 'application/pdf';
                const isDOC = file.raw.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isXLSX = file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                const isLt2M = file.raw.size / 1024 / 1024 < 2;

                if ((isJPG || isPNG || isPDF || isDOC || isXLSX) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                     console.log(image_array);
                    this.PurchaseEnquiryLine.ImagesSD = image_array;
                }
                else {
                    
                    
                    this.$message.error('Document must be JPG, PNG, PDF or Doc format with size not exceeding 1MB!!');
                    fileList.splice(-1);
                    return false;
                }
                  
            },
            handleExceedSD: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 documents.'  , 'error'); 
            },
            handleRemoveSD(file, fileList) {
                if(!fileList){
                    var fileList = this.$refs.ImagesSD.uploadFiles;
                    fileList.forEach((value, index, object) => {
                    if (value.uid === file.uid) {
                        fileList.splice(index, 1)
                    }
                    })
                }
                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                const reader=new FileReader();
                reader.readAsDataURL(fileList[i].raw);
                reader.onload = function(e) {
                        var rawLog = reader.result;
                          image_array.push(rawLog);
                    };
                
             
                  
                }
                  this.PurchaseEnquiryLine.ImagesSD=image_array;
               
              
            },

            handleChange(file,fileList){

                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isLt2M = file.raw.size / 1024 / 1024 < 2;
                
                if (fileList.length == 4)
                    $(".el-upload--picture-card").hide();
                else
                    $(".el-upload--picture-card").show();

                if ((isJPG || isPNG) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                    //  console.log(image_array);
                    this.PurchaseEnquiryLine.Images=image_array;
                }
                else {
                    
                    fileList.shift();
                    fileList.shift();
                    this.imageUrl = "";
                    
                    this.$message.error('Avatar picture must be JPG or PNG format with size not exceeding 1MB!!');
                    return false;
                }
                  
            },
            handleExceed: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 pictures of the material.'  , 'error'); 
            },
            handleRemove(file, fileList) {
                var image_array=[];
                for(var i=0;i<fileList.length;i++){
                    
                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                        var rawLog = reader.result;
                        image_array.push(rawLog);
                    };
                    
                    
                    
                }
                this.PurchaseEnquiryLine.Images=image_array;
                setTimeout(function (){
                    $(".el-upload--picture-card").show();
                },1100);
              
            },
            AddToServiceList(e, type) {
                if(type === 'Linked'){
                    Swal({
                        title: 'Are you sure?',
                        text: "This Rates' Contract Will Be Added To Service Purchase Enquiry List.",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                        }).then(result => {
                            if(result.value){
                                $('#RCServiceBA'+e).attr("disabled", true);
                                $('#RCServiceBA'+e).hide();
                                $('#RCServiceBR'+e).attr("hidden", false);
                                $('#RCServiceQ'+e).attr("disabled", true);
                                let quantity = $('#RCServiceQ'+e).val();
                                this.RCSelectionService = true;
                                // this.NoRCServiceSelectedCities.splice(this.NoRCServiceSelectedCities.indexOf(this.RateContractSelectionService[e].city), 1)
                                var TempLine = {};
                                Object.assign(TempLine,this.PurchaseEnquiryLine);
                                TempLine.Quantity = quantity;
                                TempLine.ServiceRC_id = e;
                                TempLine.Country = this.RateContractSelectionService[e].country;
                                TempLine.Cities = this.RateContractSelectionService[e].city;
                                TempLine.ServiceDescription = this.RateContractSelectionService[e].short_desc;
                                TempLine.UnitOfMeasurement = this.RateContractSelectionService[e].u_o_m;
                                TempLine.AdvancedPayment = this.RateContractSelectionService[e].adp;
                                TempLine.GuaranteeNeedForAdvancePayment = this.RateContractSelectionService[e].adpG;
                                TempLine.RetentionPercentage = this.RateContractSelectionService[e].rtp;
                                TempLine.RetentionDays = this.RateContractSelectionService[e].stpD;
                                TempLine.ServiceType = type;
                                this.PurchaseEnquiryAll.push(TempLine);
                                this.EnquiryTypeSelection();
                            }
                        })
                        
                    
                }
                else {
                    var TempLine = {};
                    Object.assign(TempLine,this.PurchaseEnquiryLine);
                    TempLine.ServiceType = type;
                    TempLine.ServiceRC_id = null;
                    TempLine.Cities = Array.from(this.NoRCServiceAvailableCheckCities);
                    this.PurchaseEnquiryAll.push(Object.assign({}, TempLine));
                }



            },
            RemoveFromServiceList(e, source) {
                if(source == 1)
                    Swal({
                    title: 'Are you sure?',
                    text: "This Line Will Be Removed Permanently.",
                    type: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                    }).then((result) => {
                        if (result.value) {
                            let key = this.PurchaseEnquiryAll.findIndex(item => item.ServiceRC_id === key);
                            this.PurchaseEnquiryAll.splice(key, 1);
                            $('#RCServiceBA'+e).attr("disabled", false);
                            $('#RCServiceQ'+e).attr("disabled", false);
                            $('#RCServiceBR'+e).attr("hidden", true);
                            $('#RCServiceBA'+e).show();
                            if(!this.PurchaseEnquiryAll.some(item => item.ServiceRC_id !== null))
                                this.RCSelectionService = false, this.EnquiryTypeSelection();
                        }
                    })
                else {
                    
                        $('#RCServiceBA'+e).attr("disabled", false);
                        $('#RCServiceQ'+e).attr("disabled", false);
                        $('#RCServiceBR'+e).attr("hidden", true);
                        $('#RCServiceBA'+e).show();
                }

                
            },
            AddItem: function() {

                // if(this.CompanySetup.lom_manditory == 'LibrariesNFreeText'){
                //     if(this.PurchaseEnquiryLine.ItemNumber == "" && this.PurchaseEnquiryLine.ItemDescription == ""){
                //         Swal('Missing Item Information', 'Please Select an item from the list or enter item description', 'error');
                //         return false;
                //     }
                // }

                



                // if(this.PurchaseEnquiryLine.Latitude == ""){
                //     Swal('Missing Map Location', 'Please Select a Location on Map to create PE Line', 'error');
                //     return false;
                // }

                this.$refs.PurchaseEnquiryForm.validate((validation, flag) => {




                    if(validation){
                        this.ReqDocTmp.map(item => {
                            if(item !== 'Other')
                                this.PurchaseEnquiryLine.RequiredDocuments.push(item)
                                })
                        this.OtherDocTmpHolder.map(item => {
                                this.PurchaseEnquiryLine.RequiredDocuments.push(item.name)
                                })
                        this.PElineUp = 'Y';
                        // Getting Item Description of the selected item
                        if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == "Yes"){
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.PurchaseEnquiryLine.ItemNumber);
                            this.PurchaseEnquiryLine.ItemLibraryDescription = selectedItem;
                          
                        }

                        if(!this.PurchaseEnquiryLine.RateContractSelected.unit_rate && this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                        {
                            Swal({
                                title: 'Are you sure?',
                                text: "There Is At Least One Rates’ Contract That Covers This Material. Are You Sure You Don't Want To Select A Rates’ Contract?",
                                type: 'error',
                                showCancelButton: true,
                                cancelButtonText: "Yes, let me proceed without selecting a Materials Rates' Contract!",
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: "No, let me select a Materials Rates' Contract!"
                            }).then((result) => {
                                if (result.value) {
                                    return false;
                                }
                                else{
                                    /* Add Setupname */
                                    //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                    this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                    if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                    {
                                        axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                        .then(function(response){
                                            if(response.data)
                                            {
                                                this.RateContractPurcase = response.data
                                            }
                                        })
                                        if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                        {
                                            Swal('Selected Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                        }
                                    }
                                    // console.log(this.PurchaseEnquiryAll);
                                    this.PurchaseEnquiryLine.JobNumber = "";
                                    this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                                    this.PurchaseEnquiryLine.Cities = ""
                                    this.PurchaseEnquiryLine.Country = ""
                                    this.PurchaseEnquiryLine.AdvancedPayment = "";
                                    this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    this.PurchaseEnquiryLine.RetentionDays = ""
                                    this.PurchaseEnquiryLine.RateContractSelected = {};
                                    this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                    this.PurchaseEnquiryLine.ItemNumber = "";
                                    this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                    this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                    this.PurchaseEnquiryLine.ItemDescription = "";
                                    this.PurchaseEnquiryLine.Quantity = "";
                                    this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                    this.PurchaseEnquiryLine.ServiceDescription = "";
                                    this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                                    this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    this.PurchaseEnquiryLine.RequiredDocuments = [];
                                    this.ReqDocTmp = [];
                                    this.OtherDocTmp = "";
                                    this.OtherDocTmpHolder = [];
                                    this.PurchaseEnquiryLine.PELineNote = "";
                                    this.PurchaseEnquiryLine.Longitude = "";
                                    this.PurchaseEnquiryLine.Latitude = "";
                                    this.PurchaseEnquiryLine.LocationName = "";
                                    
                                    // this.scrollTo(0, 1000);
                                    // totalExpectedPrice(){
                                        // alert('enter');
                                        var sum=0;
                                        // var event = this.PurchaseEnquiryAll;
                                        this.PurchaseEnquiryAll.forEach(function (element) {
                                            if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                            {
                                                sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                            }
                                            console.log(sum)
                                        })
                                        this.TotalExpectedPrice = sum;
                                    // }

                                
                                }
                            })
                        }
                        else{
                                /* Add Setupname */
                                //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                {
                                    axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                    .then(function(response){
                                        if(response.data)
                                        {
                                            this.RateContractPurcase = response.data
                                        }
                                    })
                                    if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                    {
                                        Swal('Selected Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                    }
                                }
                                // console.log(this.PurchaseEnquiryAll);
                                this.PurchaseEnquiryLine.JobNumber = "";
                                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                                this.PurchaseEnquiryLine.Cities = ""
                                this.PurchaseEnquiryLine.Country = ""
                                this.PurchaseEnquiryLine.AdvancedPayment = "";
                                this.PurchaseEnquiryLine.RetentionPercentage = "";
                                this.PurchaseEnquiryLine.RetentionDays = ""
                                this.PurchaseEnquiryLine.RateContractSelected = {};
                                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                this.PurchaseEnquiryLine.ItemNumber = "";
                                this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                this.PurchaseEnquiryLine.ItemDescription = "";
                                this.PurchaseEnquiryLine.Quantity = "";
                                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                this.PurchaseEnquiryLine.ServiceDescription = "";
                                this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                                this.PurchaseEnquiryLine.RetentionPercentage = "";
                                this.PurchaseEnquiryLine.RequiredDocuments = [];
                                this.ReqDocTmp = [];
                                this.OtherDocTmp = "";
                                this.OtherDocTmpHolder = [];
                                this.PurchaseEnquiryLine.PELineNote = "";
                                this.PurchaseEnquiryLine.Longitude = "";
                                this.PurchaseEnquiryLine.Latitude = "";
                                this.PurchaseEnquiryLine.LocationName = "";
                                
                                // this.scrollTo(0, 1000);
                                // totalExpectedPrice(){
                                    // alert('enter');
                                    var sum=0;
                                    // var event = this.PurchaseEnquiryAll;
                                    this.PurchaseEnquiryAll.forEach(function (element) {
                                        if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                        {
                                            sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                        }
                                        console.log(sum)
                                    })
                                    this.TotalExpectedPrice = sum;
                                // }
                            }
                            
                            
                        }
                    })
            },
            IgnoreRCServiceSelection(){
                Swal({
                    title: 'Are you sure?',
                    text: "Are You Sure You Don't Want To Select A Rates’ Contract?",
                    type: 'error',
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Yes"
                }).then((result) => {
                    if(result.value){
                        this.IgnoreRCSelectionService = true, this.RCSelectionService = false, this.PurchaseEnquiryAll = []; 
                        $('.RCAddQuan, .RCAddBtn').attr('disabled', false), $('.RCAddBtn').show(), $('.RCRemBtn').attr('hidden', true);
                        this.EnquiryTypeSelection();
                    }
                });

            },
            ClearServiceTypeOptions()
            {
                this.PurchaseEnquiryLine.VendorCommercialOffer = "";
            },
            SubmitService() {
                if(this.PurchaseEnquiryLine.ContractHeaders[0])
                {
                    if(this.PurchaseEnquiryLine.TendererContractHeaders[0])
                    {

                        if (event) event.preventDefault();

                        this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));

                        axios.post('/api/purchase_enquiry/post_purchase_enquiry_request', this.PurchaseEnquiryAll)
                        .then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 10000
                            });
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Service Purchase Enquiry Submit)', 'error');
                        });   
                    }
                    else
                    {
                        Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                    }
                }
                else
                {
                    Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                }
                
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.Country = [];
                this.PurchaseEnquiryLine.Cities = [];
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RateContractSelected = {};
                this.PurchaseEnquiryAll = [];
                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                this.PurchaseEnquiryLine.EnquiryType = "";` `
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = "";
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.ServiceOneOff = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.Images = [];
                this.PurchaseEnquiryLine.LocationName = "";
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ContractHeaders = [];
                this.contractheader = {
                    name: '',
                    contant: '',
                };
                this.PurchaseEnquiryLine.TendererContractHeaders = [];
                this.tenderercontractheader = {
                    name: '',
                    contant: '',
                };

                if(this.CompanySetup.setup_mode == 'Company Wide'){
                    this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                }

            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {
                this.cities = [];
                if(this.PurchaseEnquiryLine.EnquiryType == 'Materials')
                    this.PurchaseEnquiryLine.Cities = [];
                if ((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null) && Array.isArray(this.PurchaseEnquiryLine.Country)){
                    this.PurchaseEnquiryLine.Country.forEach((item) => {
                        this.cities.push(...this.countries[item])
                    });
                }
				else if((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null)){
                  this.cities = this.countries[this.PurchaseEnquiryLine.Country];
               }
                else {
                    this.cities = [];
                }
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            showPEDetails: function(key, event) {
                if (event) event.preventDefault();

                this.PEModalInfoKey = key;
                window.PEModalInfoKey = key;
                this.PEModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);

                axios.post('/api/data/get_project_details', {id: this.PEModalInfo.JobNumber})
                .then(response => {
                    this.PEModalInfo.project = response.data;
                    this.$forceUpdate();
                    this.PELineShow = true;
    
                    $('#PEDetailsModalId').modal('toggle');
                });

            },
            RemovePE: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are you sure?',
                  text: "This Line Will Be Removed Permanently.",
                  type: 'error',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        let e = this.PurchaseEnquiryAll[key].ServiceRC_id;
                        this.PurchaseEnquiryAll.splice(key, 1);
                        if(!this.PurchaseEnquiryAll.some(item => item.ServiceRC_id !== null))
                            this.RCSelectionService = false, this.EnquiryTypeSelection(), this.RemoveFromServiceList(e, 0);
                    }
                })

            },
            SubmitPEList() {
                if (event) event.preventDefault();
                // console.log(this.PurchaseEnquiryAll);
                axios.post('/api/purchase_enquiry/post_purchase_enquiry_request', this.PurchaseEnquiryAll)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Enquiry Submit)', 'error');
                    });

                //Clear All Data
                this.$refs["PurchaseEnquiryForm"].resetFields();
                this.PurchaseEnquiryAll = "";
                this.PurchaseEnquiryLine.Country = [];
                this.PurchaseEnquiryLine.Cities = [];
                this.PElineUp = 'N';
                this.PurchaseEnquiryLine.JobNumber = "";
                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                this.PurchaseEnquiryLine.EnquiryType = "";
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                this.PurchaseEnquiryLine.ItemLibraryUnitOfMeasurement = "",
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryAll = [];
                this.PurchaseEnquiryLine.ServiceOneOff = "";
                this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract = '';
                this.PurchaseEnquiryLine.RateContractSelected = '';
                this.PurchaseEnquiryLine.TypeOfServicesContract = '';
                this.PurchaseEnquiryLine.ContractHeaders = [];
                this.PurchaseEnquiryLine.TendererContractHeaders = [];
                this.PurchaseEnquiryLine.VendorOfferContent = [];
                this.PurchaseEnquiryLine.VendorCommercialOffer = '';
                this.RCSelectionService = false;
                this.IgnoreRCSelectionService = false;
                this.EnquiryTypeSelection();
                if(this.CompanySetup.setup_mode == 'Company Wide'){
                    this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                }

            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
            EnquiryItemDescriptionSelection(){

                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Images = "";
                
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            showRateContract(ItemId){
                event.preventDefault();
                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                .then((response) => {
                    this.ItemRateContractDetails = response.data;
                    this.RateContractDataLoaded = true;
                })
                .catch(function(){
                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                });
            }
        },
        created(){
            this.EnquiryTypeSelection();
            this.getCountries();
        },
        mounted(){

            //CustomJs();
            let self = this;

            axios.get('/api/data/get_user_projects_list')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    // console.log(this.JobNumbers);
                    axios.get('/api/data/get_company_details')
                    .then((SecondResponse) => {

                        this.CompanySetup = SecondResponse.data;

                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        if(this.CompanySetup.additional_required_1 == "Yes"){
                           this.rules.UnderlyingTransaction[0].required = false; 
                        }

                        self.ProjectDetailsLoaded = true;

                    });
                });


            /*
             * Google Map inside modal
             */
            var initializeMap = function(id) {

                var myLatLng = {lat: 25.2707688, lng: 55.3227913};

                // Map Options
                var mapOptions = {
                    zoom: 10,
                    center: new google.maps.LatLng(25.2707688, 55.3227913),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };


                // Initialize the map with options (inside #map element)
                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    draggable: true

                });


                google.maps.event.addListener(marker, 'position_changed', function () {
                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                });


                // When modal window is open, this script resizes the map and resets the map center
                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                  google.maps.event.trigger(map, "resize");
                  return map.setCenter(myLatLng);
                });

            };

            google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));
            
        },
        beforeRouteLeave (to, from, next) {

            if(this.PurchaseEnquiryAll.length > 0 || this.PurchaseEnquiryLine.EnquiryType != ""){
                Swal({
                    title: 'Navigate Away?',
                    text: "Are You Sure You want To Navigate Away?, Non-submitted Data Will Be Lost.",
                    type: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('PRO')){
                next();
            } else if (currentUser.hasOwnProperty('PRV')) {
                next('/app/purchase_enquiry/list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

</script>
<style>
    @media print {
      body * {
          visibility: hidden;
      }
      #PEDetailsModalId, #PEDetailsModalId * {
        visibility: visible;
      }
      #PEDetailsModalId {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .itemSelect .el-select-dropdown__wrap{
        max-height: 400px !important;
    }

</style>
<style scoped>
.tg  {border-collapse:collapse;border-spacing:0; width: 95%; margin: auto;}
.tg th {text-align: center; vertical-align: middle; font-weight: 700; border: 2px solid}
.tg td {text-align: center; vertical-align: middle; font-weight: 500; border: 2px solid}
/* .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-0lax{text-align:left;vertical-align:top} */
hr {
    background-color: #2874A6;
}
hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #2874A6;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: red;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
    #Purchase_Enqiury_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Purchase_Enqiury_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        min-width:80%;
    }

    #PEDetailsModalId .modal-dialog {
        min-width:80%;
    }
    #RCSelectionService .modal-dialog {
        min-width:80%;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .lomitemtable>tr>table>tr>th {
        border-top:none;
        margin-top: 0px;
        padding-top: 0px;
    }
    .lomitemtable {
        margin: 0px;
        padding: 0px
    }
    .el-select-dropdown{
        z-index: 0 !important;
       
    }
  

    .el-popper {
        z-index: 0 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 0;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 0;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 0;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 0;
    }

    .swal2-icon.swal2-error {
        border-color: #74f293;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }



    .dont-break-out {

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

    }
</style>