<template>
        
    <div class="row">
        <div id='Rate_Contract_Request_Form' class="col-lg-7">
            <div class="card" v-if="CompanyDetailsLoaded">
                <div class="card-header">
                    <h4>Initiate A <i>Rates' Contract Creation Request</i></h4>
                </div>
                <div class="card-body">
                    <el-form :model="RateContractItemLine" class="demo-form-inline" :rules="rules" ref="RateContractRequestForm">
                        <el-row :gutter="24">
                            <el-col :span="24">   
					            <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.025);color: rgb(252, 51, 23);" >
									<i class="fa fa-comment"></i>  <i>Rates’ Contracts</i> are agreements entered with <i>Awardee Vendors</i>, intended to freeze 
                                    <b>Unit Rates</b> and <b>Lead Times</b> for <i>Materials</i> <u>(strictly those from The <i>Library of Materials</i>)</u> 
                                    and <i>Services</i>, before the demand for them actually arises, so as to reduce costs and workloads (<i>Purchase Enquiries</i> linked to <i>Rates’ Contracts</i> 
                                    can be directly turned into <i>Purchase Orders</i>). <hr>
                                    <b><i>Rates’ Contract Creation Requests</i> formally set in motion the process of implementing <i>Rates’ Contracts</i> with <i>Awardee Vendors</i>.</b>
                                </div>
                            </el-col>
                                        
                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="JobNumber" >
                                            <span slot="label"><b>Select An <i>Account</i></b></span>
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" class="setUp" id="setUp"  @change="getProjectCurrency($event)" v-model="RateContractItemLine.JobNumber" placeholder="Choose an Account">                   
                                                <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>      
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>                      
                            
                            <el-col :span="24"><hr></el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="RCType">
                                        <span slot="label"><b>Select The <i>Rates’ Contract</i> Type</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 50px">
                                                <span class="tooltip-text2"><i>Rates' Contract Creation Requests</i> can be initiated to set in motion the process of implementing <i>Rates' Contract</i> for either <i>Materials</i> or <i>Services</i>.<br>
                                                 if it is for <i>Materials</i>, it can only be done for those items extracted from the <i>Library of Materials</i>. So make sure that your 
                                                 library has items...</span>
                                            </span>
                                        </span>
                                        <el-select :disabled="itemAdditionFlag == 'Y'" @change="RCTypeSelection($event)" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RCType" placeholder="Choose 'Materials Rates’ Contract' or 'Services Rates' Contract'">
                                                
                                            <el-option label="Materials Rates' Contract" value="Material"></el-option>
                                            <el-option label="Services Rates' Contract" value="Service"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber"><hr></el-col>

                            <el-col :span="12" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCountry">
                                        <span slot="label"><b>Select A Country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 50px">
                                                <span class="tooltip-text2">Select the country where the <i>Materials</i> or <i>Services</i> under the <i>Rates' Contract</i>, will be needed. (1 country only)</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Choose a country from the dropdown list"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.CompanyCountry" placeholder="Choose a country from the dropdown list">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCities">
                                        <span slot="label"><b>Select City(ies)</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -100px">
                                                <span class="tooltip-text2">Select the cities where the <i>Materials</i> or <i>Services</i>under the <i>Rates' Contract</i>, will be needed . (Selecting multiple cities is possible.)</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="RateContractItemLine.CompanyCities" placeholder="Choose a city, or cities, from the dropdown list">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                                <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr></el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item prop="SourcingPriority">
                                        <span slot="label"><b>Select The Sourcing Priority</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<!--<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">-->
																	<span style="bottom: 20px; right: -100 px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">The sourcing priority is, by default, set to 'Standard' but can be changed to 'Urgent' or 'Very Urgent'.</strong> 
                                                                        It is meant to indicate how quickly the tendering process, to source proposals from the <i>Qualified Vendors</i>, should be conducted.</span>
																	</span>
		                                </span>
                                        <!--<span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                </span>
                                            </span>
                                        </span>-->
                                        
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="UnderlyingTransaction">
                                        <span slot="label"><b>Enter A <i>Rates’ Contract</i> Underlying Transaction Reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    The Underlying Transaction Reference is the reference of the transaction that has led to the creation of this specific <i>Rates' Contract Creation Request</i>. <br><br>
                                                    <i>Sales Orders</i>, <i>Work Orders</i>, <i>Job Tickets</i>, <i>Lead References</i> .. are examples of 
                                                    such Underlying Transaction Reference.
                                                </span>
                                            </span>
                                        </span>

                                        <el-input v-model="RateContractItemLine.UnderlyingTransaction" :disabled="itemAdditionFlag == 'Y'" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber"><hr></el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">

                                    <!-- <el-form-item label="What should be the term, in months,of the requested Rates' Contract?" prop="RateContractItemPeriod"> -->
                                        
                                    <el-form-item label="temp" prop="RateContractItemPeriod">
                                        <span slot="label"><b>What Is The Required Term, In Month(s), For This <i>Rates' Contract</i>?</b></span>
                                        
                                         <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -120px;">
                                                <span class="tooltip-text2">
                                                    The <i>Rates' Contract</i>, signed on the back of this request, will be valid for this term, starting from the date of the <i>Rates' Contract</i> implementation.
                                                </span>
                                            </span>
                                        </span>
                                        <el-input id="expTerm" :disabled="itemAdditionFlag == 'Y'" class="expTerm"  type="number" oninput="this.value = Math.floor(this.value);" placeholder="Enter the required term, in month(s)" v-model="RateContractItemLine.RateContractItemPeriod">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Material'"><hr></el-col>
                            <el-col :span="24"  v-if="RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Select/Enter The Documents Required From The <i>Qualified Vendors</i></b></span>
                                            
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    These are the documents that the <i>Qualified Vendors</i> need to submit during the tendering process, along with their commercial proposals, 
                                                    so that their proposals can be assessed, and <i>Awardee Vendors</i> selected.
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select Required Document" id="reqDocs" popper-class="reqDocs" ref="reqDocs" @change="OtherDocsVisibility()">
                                            
                                            <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                            <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                            <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                            <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                            <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                            <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                            <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                            <el-option label="Other Documents" value="Other"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                                
                            </el-col>
                            <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                            <thead class="text-white bg-dark w-100">
                                                <tr style="width: 100% !important;">
                                                    <th><span style="color:white;"></span>Other Documents Required From The Vendors
                                                        <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                    Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                    Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                    rights, Litigation...
                                                                </span>
                                                            </span>
                                                        </span> -->
                                                    </th>
                                                    
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in OtherDocTmpHolder"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="item.name" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px" type="button" @click="delOtherDocs(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="OtherDocTmp" placeholder="Enter The Name Of The Required Document" maxlength="200">
                                                    </td>
                                                    <td class="text-center my-auto" width="10%">
                                                        <button v-if="OtherDocTmp && itemAdditionFlag =='N'" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0;" @click.prevent="addOtherDocs()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>

                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType"><hr></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label" class=""><b>Will <i>Advance Payments</i> Be Considered For Items Sourced Under This <i>Rates’ Contract</i>?</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -150px">
                                                <span class="tooltip-text2">
                                                    I have the possibility to signal, whether or 
                                                    not, my organization is willing to make 
                                                    <i>Advance payments</i>, to the <i>Awardee vendor</i>, at 
                                                    the time of issuing <i>Purchase Orders</i> under this <i>Rates' Contract</i>. <br><br>If 'Yes' 
                                                    is selected, the <i>Qualified Vendors</i> will be able to submit during the tendering process,
                                                    in their proposals, their desired <i>Advance 
                                                    Payments</i>, expressed in percentage of their 
                                                    total offer.
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.AdvancedPayment" placeholder="Choose 'Yes' or 'No'">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="RateContractItemLine.AdvancedPayment == 'Yes'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Are Guarantees, from the <i>Awardee Vendors</i>, Required Against The <i>Advance Payments</i>?</b></span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.GuaranteeNeedForAdvancePayment" placeholder="Choose 'Yes' or 'No'">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType"><hr></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType" >
                                <div class="grid-content">                                    

                                    <el-form-item label="temp" prop="RetentionPercentage">
                                        <span slot="label"><b>Enter The <i>Retention Percentage</i>, If Applicable To This <i>Rates' Contract</i></b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -150px">
                                                <span class="tooltip-text2">
                                                    The <i>Retention Percentage</i> is the portion, in %, of a payment withheld by my organization 
                                                    from the <i>Awardee Vendor</i>, for all <i>Purcahse Orders</i> issued under this <i>Rates' Contract</i>, until the elapse of the <i>Retention Timeframe</i>. 
                                                    <br>It serves as a form of security 
                                                    or guarantee that the <i>Awardee Vendor</i> will fulfill all contractual obligations, including rectifying any defects 
                                                    or deficiencies identified after the delivery of the <i>Materials</i> and <i>Services</i>.
                                                </span>
                                            </span>
                                        </span>

                                        <el-input :disabled="itemAdditionFlag == 'Y'" min='0.0001' max="100" type="number" placeholder="Enter the Retention Percentage " v-model.number="RateContractItemLine.RetentionPercentage">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col> 
                            
                            <el-col :span="24" v-if="RateContractItemLine.RetentionPercentage != 0 || RateContractItemLine.RetentionPercentage != ''" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="RetentionDays">
                                        <span slot="label"><b>Select The <i>Retention Timeframe</i></b></span>
                                        
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    The <i>Retention Timeframe</i> starts from the date of either receiving, from the <i>Awardee Vendor</i>, the complete quantity specified in the <i>Purchase Order</i>, or closing the <i>Purchase Order</i>.                                           
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RetentionDays" placeholder="Choose the Retention Timeframe duration, in days ">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>


                            <el-col :span="24" v-if="RateContractItemLine.RCType"><hr></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceDescription">
                                        <span slot="label"><b>Enter The <i>Services Rates’ Contract</i> Title</b></span>
                                        <el-input
                                            :disabled="itemAdditionFlag == 'Y'"
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter a title for the required Services Rates' Contract"
                                            v-model="RateContractItemLine.ServiceDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorInstructionHeaders">
                                        <span slot="label"><b>The Instructions To <i>Qualified Vendors</i></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    These free-text headers serve the purpose of providing clearer instructions 
                                                                    to the <i>Qualified Vendors</i> participating in the tendering process, and outlining the conditions of the award. 
                                                                    <hr>They should encompass various aspects such as the 'Tender General Information', the 'Bid Scoring Information', the 'Tender Timetable', the 'Bidding Instructions' and more.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorInstructionHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px;" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.name" placeholder="e.g. Instruction #1:" style="width: 150px; font-size: 14px; color: #09a3eb;">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.content" placeholder="e.g. The Bid Scoring Information is described below: .......">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendorinstructionheader.name && vendorinstructionheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorOfferContent">
                                        <span slot="label"><b>The <i>Qualified Vendors</i> Proposal Content</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe what the consulted vendors must submit to support their commercial 
                                                                    proposal. The Vendor Offer Content can include, Method Statements, Service Delivery Methodology, Reports, 
                                                                    Attestations, Customers Statements, Products Catalogue, Compliance Statements, Financial Statements, Bank 
                                                                    Guarantee Letter, Licenses, Proof of Capability, CVs, Timetables ...
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorOfferContent"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control" ref="VendorOfferContent">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control" ref="VendorOfferContent">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px;" type="button" @click="deleteVendorOfferContent(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.name" placeholder="e.g. Submittal #1:" style="width: 150px; font-size: 14px; color: #09a3eb;">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.content" placeholder="e.g. The Qualified Vendor shall deliver a copy of ....." style="color: lightgrey;">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendoroffercontent.name && vendoroffercontent.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addVendorOfferContent()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ServiceContractHeaders">
                                        <span slot="label"><b>The <i>Services Rates’ Contract</i> Template</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the Service Rates’ Contract which will govern the relationship 
                                                                    with the awardee vendor. The Service Contract can include, for example, a detailed description of the 
                                                                    Scope of Works, the required Activities, Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs 
                                                                    (Key Performance Indicators), Penalty Matrixes, desired Manpower Skills, Invoicing Instructions, 
                                                                    Payment Arrangement, Termination & Suspension Conditions, Arbitration, Force Majeure, IP rights, 
                                                                    Arbitration & Litigation...... Quantities are captured under the Service Rates’ Contract Unit Of 
                                                                    Measurement.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.ServiceContractHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px;" type="button" :disabled="itemAdditionFlag == 'Y'" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.name" placeholder="e.g. 1.Scope of Works" style="width: 180px; font-size: 14px; color: #fc3317;">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.content" placeholder="e.g. The Awardee Vendor shall deliver the following.....">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="servicecontractheader.name && servicecontractheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'"><hr></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceUOM">
                                        <span slot="label"><b>Enter A <i>Service Line Unit Of Measurement</i></b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -140px;">
                                                <span class="tooltip-text2">
                                                    This must be a very detailed <i>Service Line Unit Of Measurement</i>, for which rates are sought from The <i>Qualified Vendors</i> during the tendering process. <br>
                                                    A <i>Service Line Unit Of Measurement</i> can be, for example, 'Housekeeping services for 8 hours a day, 5 days a week.', 'Social Media Management services, for 30 days, by a digital marketer with 10+ years of experience in Saas products.'. and so on.
                                                </span>
                                            </span>
                                        </span>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter a (very) detailed Service Line Unit Of Measurement"
                                            v-model="RateContractItemLine.ServiceUOM">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Service'"><hr></el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content">
                                <el-form-item label="temp" prop="ItemNumber">
                                            <span slot="label"><span class="text-ogeo">* </span><b>Select From The <i>Library Of Materials</i></b></span>
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                <span class="tooltip-item2">
                                                    <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>  
                                                </span>
                                                <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                    <span class="tooltip-text2">
                                                        Only items from the <i>Library of Materials</i>, that are active for the designated <i>Account</i>, are selectable.
                                                    </span>
                                                </span>
                                            </span>
                                            <el-select style="width: 100%"
                                                @change="getUom()"
                                                v-model="RateContractItemLine.ItemNumber"
                                                filterable
                                                remote
                                                :remote-method="searchItems"
                                                :loading="searchItemsLoading"
                                                popper-class="itemSelect"
                                                placeholder="Search the Library of Materials and select an item">
                                                <el-option style="max-width: 1200px; min-height: 150px"
                                                    v-for="item in FoundItems"
                                                    :key="item.id"
                                                    :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                    :value="item.id">

                                                    <div class="row" style="border: 2px solid black">
                                                        <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                            <img v-if="!isJSON(item.picture)" class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                            <img v-else class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+JSON.parse(item.picture)[0]">
                                                            
                                                        </div>
                                                        <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                            <table class="table itemTable">
                                                                <tr>

                                                                    <td>
                                                                        <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                        <span style=" white-space: normal; line-height: 1.5em;">
                                                                            <template v-for="index in 20">      
                                                                                <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                    <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                                </span>
                                                                            </template>
                                                                        </span><br><br>
                                                                        <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                        <div class="col-sm-3 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; margin-right: 5%; word-wrap: break-word; border-left: 2px solid black">
                                                            <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                            <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                                <tr>
                                                                    <table>
                                                                        <tr>
                                                                            <th><b class="font-700">Vendor Name</b></th>
                                                                            <th><b class="font-700">Vendor Score</b></th>
                                                                            <th><b class="font-700">Expiry Date</b></th>
                                                                            <th><b class="font-700">Unit Rate</b></th>
                                                                            <th><b class="font-700">Lead Time</b></th>
                                                                        </tr>
                                                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                            <td>{{ item.vendor_name }}</td>
                                                                            <td>{{ item.vendor_score }}</td>
                                                                            <td>{{ item.date }}</td>
                                                                            <td>{{ item.unit_rate }} USD </td>
                                                                            <td>{{ item.lead_time }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid black">
                                                            <h4 class="text-center">Last Transactions Info</h4>
                                                            <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                                <tr>
                                                                    <table>
                                                                        <tr>
                                                                            <th><b class="font-700">Unit Rate</b></th>
                                                                            <th><b class="font-700">Setup Name</b></th>
                                                                            <th><b class="font-700">City</b></th>
                                                                            <th><b class="font-700">Date</b></th>
                                                                            <th><b class="font-700">Quantity</b></th>
                                                                            <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                        </tr>
                                                                        <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                            <td>{{ item.unit_rate }}</td>
                                                                            <td>{{ item.concern }}</td>
                                                                            <td>{{ item.city }}</td>
                                                                            <td>{{ item.date }}</td>
                                                                            <td>{{ item.quantity }}</td>
                                                                            <td>{{ item.pobid }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                </div>
                                <hr>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.ItemNumber && this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content">
  
                                      <!-- <el-form-item label="Enter the expected needed quantity during the term?" prop="Quantity"> -->
  
                                    <el-form-item label="temp" prop="Quantity">
                                              <span slot="label"><b>Enter The Item's Expected Required Quantity, in {{this.uom.toUpperCase()}}, During The Term</b></span>
                                              <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 50px">
                                                <span class="tooltip-text2">This information, about the item's expected required quantity, will be shared with the <i>Qualified Vendors</i> during the tendering process, so that they have an idea about the 
                                                likely required quantity, throughout the <i>Rates' Contract</i> term. <br>However, this is passed on as "FOR INFORMATION ONLY", as my organization DOES NOT 
                                                commit to sourcing this quantity, but rather to sourcing items, under this <i>Rates' Contract</i>, as and when the needs arises.</span>
                                            </span>
                                        </span>
                                          <el-input  type="number" placeholder="Enter the item's expected required quantity" v-model="RateContractItemLine.Quantity">
                                          </el-input>
                                    </el-form-item>
  
                                </div>
                                  <hr>
                            </el-col>


                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content" >
                                    <el-form-item label="temp" prop="VolumeDiscount">
                                            <span slot="label"><b>Do You Want To Have A <i>Volume-discount Arrangement</i> Apply To The Selected Item?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>              
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -200px">
                                                    <span class="tooltip-text2">
                                                        A <i>Volume-discount Arrangement</i> is a clause that will be added to the <i>Rates' Contract</i>.<br>
                                                        It will mandate that the <i>Awardee Vendor</i> will provide reduced Unit Rates, once the total sourced <i>Materials</i> or <i>Services</i>, 
                                                        exceeds an agreed-on threshold called <i>Minimal Procured Value</i>.
                                                    </span>
                                            </span>
                                        </span>
                                        <el-select filterable collapse-tags class="volume" id="volume" @change="hideShowMin()" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.VolumeDiscount" placeholder="Select 'Yes' or 'No'">
                                            
                                            <el-option label="Yes" value="Yes"></el-option> 
                                            <el-option label="No" value="No"></el-option> 
                                           
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <transition v-if="RateContractItemLine.VolumeDiscount == 'Yes'  && RateContractItemLine.RCType == 'Material'">
                                <el-col :span="24" >
                                    <!-- <div class="grid-content">  -->             
                                        <el-form-item label="temp" prop="MinimalProcuredValue" class="custominput">
                                            <span slot="label" class="customLabel"><b>What Should Be The <i>Minimal Procured Value</i>, in {{this.projectcurrency.substring(0, 3)}}, Which Will Trigger the Item’s Unit Rate’s Discount?</b>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                   <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                    
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: 200px">
                                                    <span class="tooltip-text2">
                                                        The <i>Minimal Procured Value</i> is the minimum monetary threshold required to activate the 
                                                        <i>Volume-discount Arrangement</i> specified in this <i>Rates' Contract</i>, for the selected <i>Library of Materials</i> item.<br> 
                                                        Once the <i>Minimal Procured Value</i> is reached, future <i>Purchase Orders</i> for this particular item will be 
                                                        eligible for a discounted Unit Rate. The discount applicable to item's Unit Rate, mutually agreed upon with the <i>Awardee Vendor</i>, 
                                                        will be communicated by the <i>Qualified Vendors</i> during the tendering process, in their proposals.
                                                    </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <el-input id="minVal" @change="commaprice()" class="minVal" type="currency" placeholder="Enter the Minimal Procured Value which will trigger the Unit Rate’s discount for the item" v-model="RateContractItemLine.MinimalProcuredValue">                       
                                            </el-input>
                                        </el-form-item>
                                    <!-- </div> -->
                                </el-col>

                            </transition>
                            <el-col :span=24 v-if="RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'"><hr></el-col>

                            <!-- ----------------------------------- -->
                            <button v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Item To The <i>Materials Rates’ Contract Creation Request</i></button>
                            <button v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Service'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Item To The <i>Services Rates’ Contract Creation Request</i></button>

                        </el-row>
                    </el-form>
                </div>
                <!-- <div class="card-body" v-else>
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-danger">Rates' Contract Request Are Available Only For Library of Materials Items</h3>
                        </el-col>
                    </el-row>
                </div> -->
            </div>
            <div class="card" v-else>
                <div class="card-header">
                    <h4>Create A Rates' Contract Creation Request</h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Company Details.....</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of Items in the list -->
        <div id="Rate_Contract_List" class="col-lg-5" v-if="this.RateContractItemLine.RCType !== ''">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="RateContractAllItems.length > 0" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Rates’ Contract Creation Request Lines</h4>
                            </div>
                            <div id="Rate_Contract_List_Table" class="card-body">
                                <table class="table" v-if="RateContractAllItems[0].RCType == 'Service'">
                                    <tr>
                                        <td class="text-ogeo">{{ RateContractAllItems[0].ItemLibraryDescription.description}}</td>
                                    </tr>
                                </table>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'">Library Of Materials Item Details</th>
                                            <th v-if="RateContractAllItems[0].RCType == 'Service'">Service Lines Unit of Measurement</th>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'">Expected Required Quantity</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(RateContractItem, key, index) in RateContractAllItems" :key="index">
                                            
                                            <!-- <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.description.substring(0, 20) + '...' }}</td> -->
                                            <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase().substring(0, 20) + '...' }}</td>
                                            <td v-if="RateContractItem.ItemNumber && RateContractItem.RCType == 'Material'"><span style="word-wrap: break-word; white-space: wrap;"><b>LoM-{{ RateContractItem.ItemGroupId  }}-{{ RateContractItem.ItemInGroupId  }}</b><br>{{ RateContractItem.ItemLibraryDescription.description.slice(0, 20) + '...' }}</span></td>
                                            <td v-if="RateContractItem.RCType == 'Material'">{{ RateContractItem.Quantity }} {{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase() }} </td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showRCDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <!-- <a class="btn btn-warning float-left" href="#" @click="showItemDetails(key, $event)"><i class="fa fa-edit"></i></a> -->
                                                <a class="btn btn-danger float-left m-l-5" href="#" @click="RemoveItem(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                                                        
                                    <el-checkbox class="chkAgree" v-model="RateContractItemLine.lomTOC">I have read my organization’s Materials Rates’ Contract which will govern the relationship with the awardee vendor.</el-checkbox>

                                    <br><span><a href="">Download <span class="text-ogeo">the Materials Rates’ Contract</span></a></span><br><br>
                                    <span>Note: <i>Please reach out to those holding the role of Application Administrator should you need to have the Materials Rates’ Contract amended.</i></span>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button v-if="RateContractAllItems && RateContractAllItems[0].RCType == 'Service'" type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Services Rates’ Contract Creation Request</i></button>
                                <button v-else-if="RateContractAllItems && RateContractItemLine.lomTOC" type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Materials Rates’ Contract Creation Request</i></button>
                                <button v-else type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light" disabled>Submit The <i>Materials Rates' Contract Creation Request</i></button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                 <el-row v-else :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4><i>Rates' Contract Creation Request</i> Lines</h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="font-size: smaller;" v-if="RateContractItemLine.RCType == 'Material'"><i>Library Of Materials</i> Item Details</th>
                                        <th style="font-size: smaller;" v-if="RateContractItemLine.RCType == 'Service'"><i>Service Line Unit of Measurement</i></th>
                                        <th style="font-size: smaller;" v-if="RateContractItemLine.RCType == 'Material'">Expected Required Quantity</th>
                                        <th style="font-size: smaller;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center" colspan="4">The <i>Rates' Contract Creation Request</i> Is Empty</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                <template>
  <div>
    <fieldset style="width: 450px; height: 100px; background-color: rgb(252, 51, 23, 0.030); padding: 10px; border-radius: 4px; border: 1px solid black; color: black;">
    <el-checkbox class="chkAgree" disabled v-model="RateContractItemLine.lomTOC" @change="checkTOC($event)">
      
        
        <p style="color: black !important;">
          I have read and I'm familiar with my organization's <em>Materials Rates’ Contract</em>, which will govern the 
          relationship with the <em>Awardee Vendor</em>, for the sourcing of this request's <em>Library of Materials</em> items.
        </p>
      
    </el-checkbox>
    </fieldset>
  </div>
</template>

                            
                                <span>
                                    <a href=""style="font-size: smaller;"><span style="color: rgb(252, 51, 23);">Download the </span><span style="color: rgb(252, 51, 23);font-style: italic;">Materials Rates' Contract</span></a>
                                </span>
                                <hr>
                                <span style="color:#111111; font-size: smaller;"><b>Note:</b> Please reach out to those holding the role of <i>Application Administrator</i>, should you need to have the <i>Materials Rates’ Contract</i> amended.</span>
                                
                            </div>
                        </div>
                        <div class="card-footer">
                            <button v-if="RateContractItemLine.RCType == 'Material'" type="button" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Materials Rates' Contract Creation Request</i></button>
                            <button v-else type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Services Rates’ Contract Creation Request</i></button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>
        
       

            <!-- Modal Code   -->
            <div class="modal fade" id="RCDetailsModalId" tabindex="-1" aria-labelledby="RCDetailsModalId" role="dialog" aria-hidden="true">
               
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="RCLineShow">
                    <div class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo">{{RCModalInfo.RCType == 'Material' ? "Materials Rates’ Contract Creation Request Line ID#" + RCModalInfoKey : 'Services Rates’ Contract Creation Request Line ID#'+ RCModalInfoKey }}</h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:60px;height:60px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>            
                     <div class="modal-body">
                            <div class="row">

                                <!-- Rates Contracts General Information Panel -->
                                <div class="col-lg-12">
                                    <table class="table">
                                            <tr>
                                                <td width="40%"><b class="font-700">Setup Details: </b></td>
                                                <td>
                                                    <span><b class="font-700 font-italic">Setup Name:</b> {{ RCModalInfo.project.title }}</span><br>
                                                    <span><b class="font-700 font-italic">Start Date:</b> {{ RCModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">End Date:</b> {{ RCModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(RCModalInfo.project.value - RCModalInfo.project.consumed_budget) }} {{ RCModalInfo.project.currency }}</span>
                                                </td>
                                            </tr>

                                            
                                    </table>
                                </div>
                                <!-- <div class="col-lg-12">
                                    <table class="table">
                                        
                                        <tr>
                                            <td><b class="font-700">Line Added On: </b></td>
                                            <td>{{ moment().format("DD.MMM.YYYY - (HH:MM:SS)") }}</td>
                                        </tr>
                                    </table>
                                </div> -->
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                                <td><b class="font-700">Line Initiated By: </b></td>
                                                <td>{{ currentUser.Name }}</td>
                                            </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                            <td>{{ RCModalInfo.UnderlyingTransaction }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                            <td>{{ RCModalInfo.RCType }}s</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                            <td>{{ RCModalInfo.RateContractItemPeriod }} Months</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.CompanyCountry }} | {{ RCModalInfo.CompanyCities.toString().replaceAll(',', ', ')}}</span><br>

                                            </td>
                                        </tr>

                                    </table>
                                </div>
                                <!-- Rates Contracts General Information Panel End -->

                                <!-- Materials Information Panel -->
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700">{{RCModalInfo.RCType == 'Material' ? "Are Advance Payments Considered For The Items Sourced Under The Materials Rates’ Contract?" : "Are Advance Payments Considered For The Service Lines Sourced Under The Services Rates’ Contract?"}}</b></td>
                                            <td>{{ RCModalInfo.AdvancedPayment }}</td>
                                            <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                            <td>{{ RCModalInfo.GuaranteeNeedForAdvancePayment ? RCModalInfo.GuaranteeNeedForAdvancePayment : 'Not Applicable' }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">{{RCModalInfo.RCType == 'Material' ? "What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Rates’ Contract?" : "What Is The Percentage Of Payment Retention For The Service Lines Sourced Under This Services Rates’ Contract?"}}</b></td>
                                            <td>{{ RCModalInfo.RetentionPercentage ? RCModalInfo.RetentionPercentage + '%' : 'Not Applicable' }}</td>
                                            <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                            <td>{{ RCModalInfo.RetentionDays ? RCModalInfo.RetentionDays + ' Day(s)' : 'Not Applicable' }}</td>
                                        </tr>                                 
                                    </table>
                                    <table class="table">
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                            <td v-if="RCModalInfo.RequiredDocuments">
                                                <ul class="d-inline-flex" v-for="(document, index) in RCModalInfo.RequiredDocuments">
                                                    <li>{{ document }}</li>
                                                </ul>
                                            </td>
                                            <td v-else>
                                                No Documents Required
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ RCModalInfo.SourcingPriority }}</td>
                                        </tr>
                                               
                                       <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td style="width: 40%; vertical-align: center;"><b class="font-700">Library of Materials Item Details:: </b></td>
                                            <td>
                                                <span v-if="!isJSON(RCModalInfo.ItemLibraryDescription.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+RCModalInfo.ItemLibraryDescription.picture"></span>
                                                <span class="text-center" v-else v-for="(img, index) in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></img></span>
                                                <br><br><span><b class="font-700"><span v-if="RCModalInfo.ItemLibraryDescription.company.lom_prefix == null">LoM</span><span v-else>LoM-</span>{{ RCModalInfo.ItemLibraryDescription.item_group_id }}-{{ RCModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                                <span>
                                                    <template v-for="index in 20">      
                                                        <span v-if="RCModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b class="font-700 text-uppercase">{{ RCModalInfo.ItemLibraryDescription.item_template['field_'+index] }}:</b> {{ RCModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br><br>
                                                <!-- <span><b>Quantity:</b> {{ RCModalInfo.Quantity }}</span><br> <br> -->
                                                <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                                <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                            </td>
                                        </tr> 
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Estimated Quantity During The Term: </b></td>
                                            <td>  
                                                
                                                {{  RCModalInfo.Quantity }}
                                            
                                            </td>


                                        </tr>

                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700"> Volume Discount Clause: </b></td>
                                            <td>  
                                               
                                                 <span>{{RCModalInfo.VolumeDiscount}}</span>
                                                         
                                            
                                            </td>


                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Minimum Value Required To Trigger The Item Unit Rates' Discount: </b></td>

                                            <td>  

                                             <span>
                                                 <span v-if="RCModalInfo.VolumeDiscount == 'No' ">
                                                    This Item Doesn't Have A Volume Discount Arrangement.
                                                     </span>
                                                     <span v-else>
                                                         {{ RCModalInfo.MinimalProcuredValue }} {{this.projectcurrency.substring(0, 3)}}
                                                         </span>
                                                          </span>

                                            </td>

                                           
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>  
                                                <!-- This Rates Contract doesn't have a volume discount clause -->
                                                Not Yet Awarded
                                            
                                            </td>


                                        </tr>
                                       
                                    </table>
                                </div>
                                <!-- Materials Information Panel End -->
                                
                                <!-- Services Information Panel -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">
                                    <table class="table">
                                        <!-- <tr align="center" v-if="RCModalInfo.Images.length > 0">
                                            <td v-for="(img, index) in RCModalInfo.Images" v-bind:key="img.id">
                                                <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                            </td>
                                        </tr> -->
                                        
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Service Rates’ Contract Description: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.description }}</span><br>

                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorInstructionHeaders">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700">Vendor Offer Content</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorOfferContent">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="" id="SCT" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 SCTH">Header</b></th>
                                                        <th id="SCTC"><b class="font-700 SCTC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.ServiceContractHeaders">
                                                        <td class="text-uppercase font-700 SCTH col-lg-2">{{ header.name }}</td>
                                                        <td class="SCTC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Service Line Unit of Measurement: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>

                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>
                                                <span>{{ 'Not Yet Awarded' }}</span><br>

                                            </td>
                                        </tr>
                                        
                                            
                                        
                                    </table>
                                </div>
                                <!-- Services Information Panel End -->

                                <!-- List of Valid Rates Contracts -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Material'">
                                    <table class="table">
                                        <tr class="text-ogeo text-center">
                                            <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                        </tr>
                                            <table>
                                                <tr>
                                                    <th><b class="font-700">Vendor Name</b></th>
                                                    <th><b class="font-700">Country Cities</b></th>
                                                    <th><b class="font-700">Unit Rate</b></th>
                                                    <th><b class="font-700">Expiry Date</b></th>
                                                    <th><b class="font-700">Lead Time</b></th>
                                                    <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                    <th><b class="font-700">Discount Rate</b></th>
                                                    <th><b class="font-700">Total Procured So Far</b></th>
                                                    <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                                    <th><b class="font-700">Retention Payment Arrangement</b></th>
                                                </tr>
                                                <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                    <td>{{ item.vendor_name }}</td>
                                                    <td>{{ item.unit_rate }} USD </td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.lead_time }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                </tr>
                                            </table>
                                    </table>
                                </div>

                                <!-- <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">                            
                                    <table class="table mt-3">
                                        <tr class="text-center text-ogeo">
                                            <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                            <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th><b class="font-700">Reference</b></th>
                                            <th><b class="font-700">Short Description</b></th>
                                            <th><b class="font-700">Service Unit Of Measurement</b></th>
                                            <th><b class="font-700">Expiry Date</b></th>
                                        </tr>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </tr>
                                    </table>
                                    <hr>   
                                </div> -->
                                <!-- List of Valid Rates Contracts End -->

                            </div>
                            
                    </div>

                    <div class="col-lg-12">
                        <hr>
                        <div class="d-inline-block">
                            <span><b>Date:</b> {{ new Date() }}</span><br>
                            <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Materials Rates’ Contract Creation Request Line ID# 1-'+window.RCModalInfoKey + 1;window.print()">Download PDF</button>   <br><br>
                        </div>
                        <div class="d-inline-block pull-right">
                            <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                        </div>
                    </div>
                    </div>
                </div> 
       
            </div>
            <!-- Modal Code  -->

    </div>


            
</template>

<script>
    

    //import { CustomJs } from '../../helpers/custom.js';
    import swal from 'sweetalert2';
import validate from 'validate.js';

    export default {
        name: 'new-request-rate-contract-creation',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.RateContractItemLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter The Retention Percentage')); 
                }
                
                if ((value + '').replace(/^-?\d*\.?|0+$/g, '').length > 2) {
                    callback(new Error('Please Enter No More Than Two Decimal Places.'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkItemSelection = (rule, value, callback) => {
                if (!value) { 
                    return callback(new Error('Please Select An Item From The Library of Materials')); 
                } else {
                    callback();
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.RateContractItemLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select The Retention Timeframe')); 
                } else {
                    callback();
                }
                
            };
            return {
                hostName: window.location.protocol+'://'+window.location.hostname,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
			    cities: [],
                projectcurrency: '',
                uom: '',
                showOtDocs: false,
                ReqDocTmp: [],
                OtherDocTmp: '',
                OtherDocTmpHolder: [],
                FirstStepData:{
					CompanyLogo: "",
					curr: "",
                    companyName: "",
                    companyTime:"",
                    CompanyEmployeeRange: [],
                    CompanyIndustry: [],
                    CompanyCities: [],
                    companyCity: "",
                    CompanyCountry: "",
                    CompanyCountries: [],
				},
                vendorinstructionheader: {
                    name: '',
                    content: '',
                },
                vendoroffercontent: {
                    name: '',
                    content: '',
                },
                servicecontractheader: {
                    name: '',
                    content: '',
                },
			    EmployeesList: [],
			    ProjectKey: "",
			    JobNumbers: [],
                itemAdditionFlag: "N",
                minVal: '',
                curr: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                RCModalInfoKey: "",
                RCModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                CompanyDetailsLoaded: false,
                FoundItems: {},
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                RateContractItemLine: {
                    JobNumber: "",
                    RCType: "",
                    curr: "",
                    ItemNumber: "",
                    RateContractItemPeriod: "",
                    VolumeDiscount: "",
                    MinimalProcuredValue: "",
                    Quantity: "",
                    SetupName: "",
                    ServiceDescription: "",
                    ServiceUOM: "",
                    SourcingPriority: "Standard",
                    UnderlyingTransaction: "", 
                    ItemLibraryDescription: "",
                    VendorInstructionHeaders: [],
                    VendorOfferContent: [],
                    ServiceContractHeaders: [],
                    CompanyCities: [],
                    CompanyCountry: "",
                    uomTodisplay: "",
                    ItemGroupId: "",
                    ItemInGroupId: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    lomTOC: "",
                    rtYN: 'no',
                },
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
                      city: 'Herat'
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
                      city: 'Herat'
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                RateContractAllItems: [],
                RCItemModalInfoKey: "",
                RCItemModalInfo: "",
                RCLineShow: false,
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true,
                        message: "Please Select An Account",
                        trigger: ["blur"]
                    }],
                    RCType: [{
                        required: true,
                        message: "Please Select The Rates' Contract Type",
                        trigger: ["blur"]
                    }],
                    VendorInstructionHeaders: [{
                        required: true, message: "At Least 1 Header & Its Content Are Required", trigger: ["change"]
                    }],
                    VendorOfferContent: [{
                        required: true, message: "At Least 1 Header & Its Content Are Required", trigger: ["change"]
                    }],
                    ServiceContractHeaders: [{
                        required: true, message: "At Least 1 Header & Its Content Are Required", trigger: ["blur", "change"]
                    }],
                    CompanyCountries: [{
                        required: false,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        validator: checkItemSelection,
                        trigger: ["blur"]
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Enter The Expected Required Quantity",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    RateContractItemPeriod: [{
                        required: true,
                        message: "Please Enter The Rates’ Contract Term",
                        trigger: "blur"
                    }],
                    VolumeDiscount: [{
                        required: true,
                        message: "Please Make A Decision Re The Volume-discount Arrangement",
                        trigger: "blur"
                    }],
                    MinimalProcuredValue: [{
                        required: true,
                        message: "Please Enter The Minimum Procured Value",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select The Unit of Measurement",
                        trigger: ["blur", "change"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter A Title For The Services Rates’ Contract",
                        trigger: ["blur"]
                    }],
                    ServiceUOM: [{
                        required: true,
                        message: "Please Enter A Detailed Service Line Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please Select Term of Service",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Make A deison Re The Advance Payment Requirement.",
                        trigger: ["blur", "change"]
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please Select Retention Days", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    CompanyCountry: [{
                        required: true,
                        message: "Please Select A Country.",
                        trigger: "blur"
                    }],
                    CompanyCities: [{
                        required: true,
                        message: "Please Select City(ies).",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please Enter The Location Name.",
                        trigger: "blur"
                    }]
                },
            }
        },
		created() {
            this.getCountries();
            console.log('carried');
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            commaprice() {
                if(!isNaN(this.RateContractItemLine.MinimalProcuredValue))
                    this.RateContractItemLine.MinimalProcuredValue = this.RateContractItemLine.MinimalProcuredValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                else
                    this.RateContractItemLine.MinimalProcuredValue = ""
            },
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            OtherDocsVisibility(){
                if(this.ReqDocTmp.includes('Other')){
                  this.$refs.reqDocs.blur();
                  $('.reqDocs').hide();
                }
            },
            searchItems(value) {
                console.log(value);
                this.RateContractItemLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.RateContractItemLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                    })
            },
            checkTOC(e){
                if(e == true){swal('Warning','A Draft of Materials Rates’ Contract Is Needed To Initiate A Rates’ Contract Creation Request', 'warning'); this.RateContractItemLine.lomTOC = false}
            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        console.log('in the countries sections');
                        this.countries = res.data
                        console.log( this.countries );

                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {


                this.cities = [];
                this.RateContractItemLine.CompanyCities = [];
				if(this.RateContractItemLine.CompanyCountry != '' || this.RateContractItemLine.CompanyCountry != null ){
  
                  this.cities= this.countries[this.RateContractItemLine.CompanyCountry];
               }
            },
            checkProjectExpiry(e){
                var val = axios.get('/api/data/get_project_expiry/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        if(new Date(response.data[0]) > new Date())
                            return true;
                        else{
                            this.RateContractItemLine.JobNumber = '';
                            swal('The Selected <i>Account</i> Has Expired', 'Using this <i>Account</i> will be possible if those holding the <i>Budget Update Request Initiator</i> role extend its end date.', 'error');
                            return false;    
                        }
                    })
                return val;
            },
            getProjectCurrency(e)
            {
                if(this.checkProjectExpiry(e))
                    axios.get('/api/data/get-project-currency/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        this.projectcurrency = response.data[0]
                    })
             
            },
            resetForm(){
                this.RateContractItemLine.curr= "",
                this.RateContractItemLine.ItemNumber= "",
                this.RateContractItemLine.RateContractItemPeriod= "",
                this.RateContractItemLine.VolumeDiscount= "",
                this.RateContractItemLine.MinimalProcuredValue= "",
                this.RateContractItemLine.Quantity= "",
                this.RateContractItemLine.SetupName= "",
                this.RateContractItemLine.ServiceDescription= "",
                this.RateContractItemLine.ServiceUOM= "",
                this.RateContractItemLine.SourcingPriority= "Standard",
                this.RateContractItemLine.UnderlyingTransaction= "", 
                this.RateContractItemLine.ItemLibraryDescription= "",
                this.RateContractItemLine.VendorInstructionHeaders= [],
                this.RateContractItemLine.VendorOfferContent= [],
                this.RateContractItemLine.ServiceContractHeaders= [],
                this.RateContractItemLine.CompanyCities= [],
                this.RateContractItemLine.CompanyCountry= "",
                this.RateContractItemLine.uomTodisplay= "",
                this.RateContractItemLine.ItemGroupId= "",
                this.RateContractItemLine.ItemInGroupId= "",
                this.RateContractItemLine.ServiceTerm= "",
                this.RateContractItemLine.AdvancedPayment= "",
                this.RateContractItemLine.GuaranteeNeedForAdvancePayment= "",
                this.RateContractItemLine.RetentionPercentage= "",
                this.RateContractItemLine.RetentionDays= "",
                this.RateContractItemLine.RequiredDocuments= [],
                this.RateContractItemLine.lomTOC= "",
                this.RateContractItemLine.rtYN= 'no';

                this.$nextTick(( ) => {
                    this.$refs.RateContractRequestForm.clearValidate();
                })
            },
            RCTypeSelection: function(e){
                if(e) {
                    $('#Rate_Contract_List').removeClass('animated fadeOutRight');
                    $('#Rate_Contract_List').addClass('animated fadeInRight');
                    $('#Rate_Contract_Request_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);

                    this.resetForm();
                    this.ReqDocTmp = [];
                    this.OtherDocTmp = '';
                    this.OtherDocTmpHolder = [];
                }
                else {
                    this.resetForm();
                    this.ReqDocTmp = [];
                    this.OtherDocTmp = '';
                    this.OtherDocTmpHolder = [];
                    $('#Rate_Contract_List').removeClass('animated fadeInRight');
                    $('#Rate_Contract_List').addClass('animated fadeOutRight');
                    $('#Rate_Contract_Request_Form').removeClass('col-lg-7').addClass('col-lg-12').delay(500);
                }
            },
             getUom()
            {
                     let itemAtHand = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                     
                     this.uom = itemAtHand.u_o_m;

                                  
            },
            addOtherDocs() {
                if (this.existOtherDocs() == true) {
                    this.OtherDocTmpHolder.push({name: this.OtherDocTmp})
                    this.OtherDocTmp = ''
                } else {
                    Swal('Error', 'This Document Already Exists.' , 'error'); 
                }
            },
            existOtherDocs() {
                if (this.OtherDocTmpHolder.find(item => item.name === this.OtherDocTmp)) {
                    return false;
                } else {
                    return true;
                }
            },
            delOtherDocs(index) {
                this.$delete(this.OtherDocTmpHolder, index);
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorInstructionHeaders.push({
                        name: this.vendorinstructionheader.name,
                        content: this.vendorinstructionheader.content,
                    })
                    this.vendorinstructionheader.name = ''
                    this.vendorinstructionheader.content = ''
                } else {
                    Swal('Error', 'This Header Already Exists.' , 'error'); 
                }
            },
            existTendererContractHeader() {
                if (this.RateContractItemLine.VendorInstructionHeaders.find(item => item.name === this.vendorinstructionheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.RateContractItemLine.VendorInstructionHeaders, index);
            },
            addVendorOfferContent() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorOfferContent.push({
                        name: this.vendoroffercontent.name,
                        content: this.vendoroffercontent.content,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.content = ''
                    this.$ref.VendorOfferContent.validateField()
                } else {
                    Swal('Error', 'This Header Already Exists.' , 'error'); 
                }
            },
            existVendorOfferContent() {
                if (this.RateContractItemLine.VendorOfferContent.find(item => item.name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.RateContractItemLine.VendorOfferContent, index);
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.RateContractItemLine.ServiceContractHeaders.push({
                        name: this.servicecontractheader.name,
                        content: this.servicecontractheader.content,
                    })
                    this.servicecontractheader.name = ''
                    this.servicecontractheader.content = ''
                } else {
                    Swal('Error', 'This Contract Header already exists.' , 'error'); 
                }
            },
            existContractHeader() {
                if (this.RateContractItemLine.ServiceContractHeaders.find(item => item.name === this.servicecontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.RateContractItemLine.ServiceContractHeaders, index);
            },
			hideShowMin() {
				this.RateContractItemLine.MinimalProcuredValue = '';
                if(this.RateContractItemLine.VolumeDiscount=='Yes'){
                        $('#min_val').show();
                }else{
                     $('#min_val').hide();
                }

            },
            AddItem: function() {


                this.RateContractItemLine.uomTodisplay=this.uom;
                
                this.$refs.RateContractRequestForm.validate((validation) => {


                    if(validation){
                        let duplicateItem = 'N';
                        if(this.RateContractItemLine.RCType == 'Material') {
                            
                            if(this.itemAdditionFlag=="N"){
                                this.ReqDocTmp.map(item => {
                                    if(item !== 'Other')
                                        this.RateContractItemLine.RequiredDocuments.push(item)
                                })
                                if(this.OtherDocTmpHolder)
                                    this.OtherDocTmpHolder.map(item => {
                                            this.RateContractItemLine.RequiredDocuments.push(item.name)
                                    })
                            }
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                            
                            this.RateContractItemLine.ItemGroupId=  selectedItem.item_group_id;
                            this.RateContractItemLine.ItemInGroupId = selectedItem.item_ingroup_id;
                            this.RateContractItemLine.ItemLibraryDescription = selectedItem;

                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {
                    
                                        
                                            if(element.ItemNumber == this.RateContractItemLine.ItemNumber){
                                                console.log('Duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This item.','warning');
                                                duplicateItem='Y';
                                                return;
                                            }

                                        });
                                            

                                        }
                                }
                        else {
                            const descOBJ = {description: this.RateContractItemLine.ServiceDescription, u_o_m: this.RateContractItemLine.ServiceUOM};
                            this.RateContractItemLine.ItemLibraryDescription = Object.assign({}, descOBJ);                            
                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {

                                        
                                            if(element.ItemLibraryDescription.u_o_m.includes(this.RateContractItemLine.ItemLibraryDescription.u_o_m)){
                                                console.log('Duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This Item.','warning');
                                                duplicateItem='Y';
                                                return false;
                                            }

                                        });
                                            

                                        }
                                }

                        if(duplicateItem=='N'){
                            this.RateContractAllItems.push(Object.assign({}, this.RateContractItemLine));
                            
                            this.RateContractItemLine.ItemLibraryDescription= "",

                            this.RateContractItemLine.ServiceUOM = "";
                            this.RateContractItemLine.ItemNumber = "";
                            this.RateContractItemLine.MinimalProcuredValue = "";
                            this.RateContractItemLine.VolumeDiscount = "";
                            this.RateContractItemLine.Quantity = "";
                        
                        }

                        this.itemAdditionFlag="Y";
                    }
                })

                

            },
            showItemDetails: function(key, event) {
                if (event) event.preventDefault();

                this.RCItemModalInfoKey = key;
                this.RCItemModalInfo = Object.assign({}, this.RateContractAllItems[key]);
                this.$forceUpdate();

                $('#RCItemDetailsModalId').modal('toggle');

            },
            showRCDetails: function(key, event) {
                if (event) event.preventDefault();

                
                this.RCModalInfoKey = key+1;
                window.RCModalInfoKey = this.RCModalInfoKey;
                
                //this.RCModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);
                this.RCModalInfo = Object.assign({}, this.RateContractAllItems[key]);

                axios.post('/api/data/get_project_details', {id: this.RCModalInfo.JobNumber})
                .then(response => {
                    this.RCModalInfo.project = response.data;
                    this.$forceUpdate();
                    this.RCLineShow = true;
    
                    $('#RCDetailsModalId').modal('toggle');
                });


            },
            RemoveItem: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are You Sure?',
                  text: "This Will Remove This Line From The Rates' Contract Creation Request",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        this.RateContractAllItems.splice(key, 1);
                    }
                })

            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            SubmitRateContractItemList(event) {
                if (event) event.preventDefault();
                 axios.post('/api/rate_contracts/post_rate_contract_request', this.RateContractAllItems)
               // axios.post('/api/post_rate_contract_request', this.RateContractAllItems)
                    .then(response => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                        //Clear All Data
                        this.ReqDocTmp = [];
                        this.OtherDocTmp = "";
                        this.OtherDocTmpHolder = [];
                        this.RateContractAllItems = [];
                        this.itemAdditionFlag = 'N';
                        // this.JobNumbers="";
                        this.curr="";
                        this.cities=[];
                        //FoundItems
                        this.RCTypeSelection();
                    })
                    .catch(function(){
                        // check temporary

                        console.log('in the error brackets');
                        //  Swal({ 
                        //     type: 'success', 
                        //     title: 'Rate Contract Request Created.', 
                        //     text: 'Rate Contract Request Created Successfully.',
                        //     showConfirmButton: true,
                        //     timer: 10000
                        // });
                        // check temporary
                         Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Rate Contract Request Submit)', 'error');
                    });
                    


            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
        },
        mounted(){
            $('#Rate_Contract_List').removeClass('animated fadeInRight');
            $('#Rate_Contract_List').addClass('animated fadeOutRight');
            $('#Rate_Contract_Request_Form').removeClass('col-lg-7').addClass('col-lg-12').delay(500);
            //CustomJs();

            $("#expTerm").on('input' ,function (e) {
                alert(e);
                e.value = this.value.replace(/[^0-9]/g, "");
            });

            $("#reqDocs").change(function(){
                var id = $(this).find("el-option:selected").attr("id");

                if(id == 'otDocs'){
                    this.showOtDocs = true;
                }
                else
                    this.showOtDocs = false;
                });
            let self = this;

            axios.get('/api/data/get_user_projects_list_rcco')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;

                    axios.get('/api/data/get_company_details')
                        .then((SecondResponse) => {

                            this.CompanySetup = SecondResponse.data;
                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        // if(this.CompanySetup.additional_required_1 == "Yes"){
                        //    this.rules.UnderlyingTransaction[0].required = false; 
                        // }

                            self.CompanyDetailsLoaded = true;

                        });
                });
           
        },
        
        beforeRouteLeave (to, from, next) {

            if(this.RateContractAllItems.length > 0 || this.RateContractItemLine.RCType){
                Swal({
                    title: 'Are You Sure You want To Navigate Away?',
                    text: "Are You Sure You want To Navigate Away?, Non-submitted Data Will Be Lost.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('RCCO')){
                next();
            } else if (currentUser.hasOwnProperty('RCCV')) {
                next('/app/rate_contracts/rate_contracts_creation_requests_list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

       
</script>


<style>
    .itemSelect .el-select-dropdown__wrap{
        max-height: 400px !important;
    }
.custominput{
    position: relative;
    text-align: left;
}
.custominput label{
    text-align: left;
    padding-left: 12px;
    line-height: 28px;
}
.custominput label:before{
    top: 2px;
    left: 0;
    position: absolute;
}
.customLabel{
    text-align: left;
    max-width: 90%;
    display: inline-block;
}

    #Rate_Contract_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Rate_Contract_Request_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    #RCItemDetailsModalId .modal-dialog {
        width:80%;
    }

    #RCDetailsModalId, #RCDetailsModalId * {
        visibility: visible;
      }
      /* #RCDetailsModalId {
        /* position: absolute; */
        /* left: 0; */
        /* top: 0; */
      /* } */ 

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .lomitemtable>tr>table>tr>th {
        border-top:none;
        margin-top: 0px;
        padding-top: 0px;
    }
    .lomitemtable {
        margin: 0px;
        padding: 0px;
    }
    .el-select-dropdown__item{
        height: 100% !important;
    }
    .hide{
    visibility: hidden !important;
    }
    .el-select-dropdown__wrap{
        max-height: 800px !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style>
<style scoped>
hr {
    background-color: #2874A6;
}
    @media print {
  
        html, body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }
        body * {
    visibility: hidden;
  }
  #RCDetailsModalId, #RCDetailsModalId * {
    visibility: visible;
  }
  #RCDetailsModalId {
    position: absolute;
    left: 0;
    top: 0;
  }
  button {
    display: none;
  }

  #RCDetailsModalId:last-child {
     page-break-after: auto;
    }
    #RCDetailsModalId:last-child {
     page-break-before: none;
    }
}

/* #otDocsF * :not(input) {
    border: none;
} */

.chkAgree .el-checkbox__inner {
        align-self: start;
    }
.chkAgree > .el-checkbox__label {
    display:inline-flex;
    white-space: normal !important;
}
.el-switch__label * {
        font-size: 18px;
        font-weight: bold;
    }
.el-switch__label.is-active {
    color: rgb(252, 51, 23);
}
    #RCDetailsModalId .modal-dialog {
        min-width:80%;
    }
    .el-select-dropdown{
        z-index: 1 !important;
       
    }
  

    .el-popper {
        z-index: 1 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 1;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 1;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 1;
    }

    .swal2-icon.swal2-error {
        border-color: #74f293;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    } 
</style>