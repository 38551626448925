<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Vendor Score Setting</h4>
                </div>
                <div class="card-body">
                    <b class="text-ogeo">
                        The vendors’ performance will be scored, according to up to 7 criteria, as set out in the below table. The criterion which my organization values the most can be given a higher weightage. Beside the weightage, 4 scores, ranging from 3 to 0, must be allocated to the 4 criteria values’ ranges. The overall score, expressed out of one hundred, is the sum of the criteria’s scores multiplied by their respective weightages, divided by the sum of the weightages. <br><br>

                        This Vendor Score Setting can be adjusted at will. Changes to the way the vendors’ performance, when updated, will be instantly communicated to the vendors.

                    </b><br><br><br>

                    <el-form class="demo-form-inline">

                        <el-row :gutter="24">

                            <el-col :span="24"> 
                                <table style="width: 100%; border: 1px solid">
                                    <tr style="height: 50px;">
                                        <td colspan="8"><strong class="text-ogeo">Vendors Score Setting</strong></td>
                                    </tr>
                                    <tr height="150px">
                                        <th style="width: 110px">Active</th>
                                        <th style="width: 350px">Description</th>
                                        <th style="width: 130px">Weightage</th>
                                        <th colspan="5" style="vertical-align: middle; ">Score
                                            <!-- <table style="width:100%">
                                                <tr style="width:100%">
                                                    <td style="background:orange;color:red;width:35%">3</td>
                                                    <td style="background:palegoldenrod;color:red;width:15%;">2</td>
                                                    <td style="background:antiquewhite;color:red;width:15%;">1</td>
                                                    <td style="background:gold;color:red;width:35%;">0</td>
                                                </tr>
                                            </table> -->
                                        </th>
                                    </tr>
                                    <tr class="VSbody">
                                        <td >
                                            <el-checkbox v-model="record.c_1" :label="'Criteria #1'"></el-checkbox>
                                            
                                        </td>
                                        <td class="text-left pl-2" >
                                            What is the percentage (%) of received Requests For Proposals for which a bid/no bid decision was communicated?
                                        </td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_1 == false" v-model="record.c_1_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput class="midStretch" v-if="dataLoaded" :Enabled="record.c_1" :Range3="record.C1Slider1" :Range2="record.C1Slider2" :Range1="record.C1Slider3" @Range1="record.C1Slider3 = $event" @Range2="record.C1Slider2 = $event" @Range3="record.C1Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_2" :label="'Criteria #2'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">
                                            How frequently does the vendor abide by his bid/no bid communicated intention, in percentage (%) of received Requests For Proposals for which bid/no bids intentions were communicated?
                                        </td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_2 == false" v-model="record.c_2_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan="3" class="mid_value_dy">
                                            <VendorScoreInput v-if="dataLoaded" :Enabled="record.c_2" :Range3="record.C2Slider1" :Range2="record.C2Slider2" :Range1="record.C2Slider3" @Range1="record.C2Slider3 = $event" @Range2="record.C2Slider2 = $event" @Range3="record.C2Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_3" :label="'Criteria #3'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How many bids does the vendor submit, in percentage (%) of received Requests For Proposals?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_3 == false" v-model="record.c_3_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_3" :Range3="record.C3Slider1" :Range2="record.C3Slider2" :Range1="record.C3Slider3" @Range1="record.C3Slider3 = $event" @Range2="record.C3Slider2 = $event" @Range3="record.C3Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_4" :label="'Criteria #4'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor submit the lowest bid, in percentage (%) of total submitted bids?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_4 == false" v-model="record.c_4_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_4" :Range3="record.C4Slider1" :Range2="record.C4Slider2" :Range1="record.C4Slider3" @Range1="record.C4Slider3 = $event" @Range2="record.C4Slider2 = $event" @Range3="record.C4Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_5" :label="'Criteria #5'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor submit the shortest lead time bid, in percentage (%) of total submitted bids?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_5 == false" v-model="record.c_5_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_5" :Range3="record.C5Slider1" :Range2="record.C5Slider2" :Range1="record.C5Slider3" @Range1="record.C5Slider3 = $event" @Range2="record.C5Slider2 = $event" @Range3="record.C5Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_6" :label="'Criteria #6'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">Materials' Requests For Proposals - How frequently does the vendor deliver the materials as per the agreed lead time, in percentage (%) of total delivered materials?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_6 == false" v-model="record.c_6_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_6" :Range3="record.C6Slider1" :Range2="record.C6Slider2" :Range1="record.C6Slider3" @Range1="record.C6Slider3 = $event" @Range2="record.C6Slider2 = $event" @Range3="record.C6Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_7" :label="'Criteria #7'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor register "receiving refusals", in percentage (%) of total delivered materials?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_7 == false" v-model="record.c_7_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_7" :Range3="record.C7Slider1" :Range2="record.C7Slider2" :Range1="record.C7Slider3" @Range1="record.C7Slider3 = $event" @Range2="record.C7Slider2 = $event" @Range3="record.C7Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                </table>
                            </el-col>
                            <br><br>

                            <el-col :span="24" style="padding: 12px; margin-top: 50px;">
                                
                                <button type="button" @click="SubmitVendorScoreSetting" class="btn btn-success btn-block waves-effect text-center">Update The Vendors Score Setting</button>
                            </el-col>

                        </el-row>
                    </el-form>

                </div>
            </div>
            
        </div>
    </div>
            
</template>

<script>
    import VendorScoreInput from '../../components/VendorScoreInput.vue'

    export default {
        components: {
            VendorScoreInput
        },
        name: 'new-item-creation-request',
        data(){

            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === ''){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }
                    
                }
            };

            return{
                dataLoaded: false,
                record: {
                    c_1: false,
                    c_2: false,
                    c_3: false,
                    c_4: false,
                    c_5: false,
                    c_6: false,
                    c_7: false,
                    c_1_w: 1,
                    c_2_w: 1,
                    c_3_w: 1,
                    c_4_w: 1,
                    c_5_w: 1,
                    c_6_w: 1,
                    c_7_w: 1,
                    C1Slider1: 80,
                    C1Slider2: 60,
                    C1Slider3: 40,
                    C2Slider1: 80,
                    C2Slider2: 60,
                    C2Slider3: 40,
                    C3Slider1: 80,
                    C3Slider2: 60,
                    C3Slider3: 40,
                    C4Slider1: 80,
                    C4Slider2: 60,
                    C4Slider3: 40,
                    C5Slider1: 80,
                    C5Slider2: 60,
                    C5Slider3: 40,
                    C6Slider1: 80,
                    C6Slider2: 60,
                    C6Slider3: 40,
                    C7Slider1: 80,
                    C7Slider2: 60,
                    C7Slider3: 40,
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now();
                    },
                },
                
            }
        },
        computed : {
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            }
        },
        methods: {
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            errorPop(val) {
                val++
            },
            SubmitVendorScoreSetting: function(){
                axios.post('/api/data/set_vendor_score_setting', this.record)
                .then((response) => {
                    Swal({ 
                        type: 'success', 
                        title: 'Vendors Score Settings Updated Sucessfully.',
                        showConfirmButton: true,
                        timer: 5000
                    });
                })

            },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')
                
            }
        },
        created(){
            axios.get('/api/data/get_vendor_score_setting')
                .then((response) => {
                    response.data.forEach((data, index) => {
                        if(data.criteria == index+1) {
                            this.record['c_'+(index+1)] = true;
                            this.record['c_'+(index+1)+'_w'] = data.weightage;
                            for(let i=1; i<=3; i++) {
                                this.record['C'+(index+1)+'Slider'+i] = data['score_'+(4-i)];

                            }
                        }
                    });
                    this.dataLoaded = true;
                });
        
        },
        mounted(){
            let self = this;



            axios.get('/api/data/get_company_details')
                .then((response) => {
                    self.companyDetails = response.data;
                });
        },
    }

</script>
<style>
.el-input-number--mini{
    width: 100px;
    line-height: 38px;
    height: 40px;
}
.el-input--mini .el-input__inner{
    color: orangered !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}
</style>
<style scoped>
    td, th{
        border: 1px solid !important;
        text-align: center;
    }
    .VSbody {
        height: 100px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .el-input__inner {
        height: 40px !important;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }
    .el-input-number--small{
        height: 125px !important;
    }
    .el-input--small .el-input__inner{
        height: 32px !important;
    }
    .el-select-dropdown__item{
        height: 100% !important;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .start_value_st{
        width:50px;
        font-size: 18px;
        font-weight: 700;
    }
    .end_value_st{
        width:50px;
        font-size: 18px;
        font-weight: 700;
    }
    .mid_value_dy{
        align-items: space-between;
    }
    .midStretch {
        width: 100%;
    }
</style>