<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div v-if="showList == false">
                <span slot="label"><b>Select A Organization</b></span>
                <el-select filterable style="width: 100%; padding: 0px;" @change="getData()" v-model="record.company_id" placeholder="Select Organization">
                    <el-option v-for="(item, index) in companies" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <div class="card">
                    <div class="card-header">
                        <h4>Library of Materials Templates List</h4>
                    </div>
                    <div class="card-body">
                        <table ref="table" id="ItemsTemplatesList" class="display responsive table table-bordered table-hover" style="width:100%">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Template Name</th>
                                    <th>Mandatory Header</th>
                                    <th>Assigned Headers</th>
                                    <th>Linked Items</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
            
</template>

<script>
    export default {
        name: 'purchase-enquiry-list',
        data(){
            return{
                DataTable: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                cancelDataLoaded: false,
                RFIDataLoaded: false,
                POLineRFIDataLoaded: false,
                RequiredDocsDataLoaded: false,
                showList: false,
                companies: [],
                record: {
                    company_id: '',
                },
                EditModalInfo: {
                    purchase_order: '',
                },
                CancellationInfo: {
                    ReceiptNoteId: "",
                    CancelledQty: "",
                    CancellationReason: "",
                    CancellationNotes: "",
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            getData(){
                const table = $('#ItemsTemplatesList').DataTable({
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_item_master_templates_uncovered/'+ this.record.company_id,
                    columns: [
                        { data: 'template_group_id', name: 'template_group_id' },
                        { data: 'template_name', name: 'template_name' },
                        // { data: 'u_o_m', name: 'u_o_m' },
                        { data: 'u_o_m', render: function(data, type, full){
                            return data+"*"
                        }},
                        { data: 'description', render: function(data, type, full){
                            return '<b class="text-uppercase">'+data+'</b>';
                        }},
                        // { data: 'description', name: 'description' },
                        { data: 'items_count', name: 'items_count' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: 1 },
                        { responsivePriority: 2, targets: 5 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterTemplatesList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },    
                });


                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $this.parent().parent().find(">:first-child").text();
                    //Check if Id has prefix and resolve
                    if (RecordID.includes("-")){
                        RecordID = RecordID.substring(RecordID.indexOf("-") + 1);
                    }

                    self.deleteTemplate(RecordID);
                
                });


                self.DataTable = table;
            }

        },
        mounted(){
            axios.get('/api/data/get_templates_companies_list')
            .then((response) => {
                this.companies = response.data
            });
        },
    }

</script>


<style scoped>

    .font-500{
        font-weight: 500;
    }

    #PurchaseOrderUpdateModalView .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderCancellationModal .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderValidationModalEdit .modal-dialog {
        min-width:90%;
    }

    #RFIModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #RequiredDocsModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #POLineRFIDetailsModal .modal-dialog {
        top: 3%;
        min-width:75%;
    }

    .poline-approved {
        background-color: rgb(69, 249, 132, 0.1);
    }

    .poline-rejected {
        background-color: rgb(249, 69, 72, 0.08);
    }

    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }

</style>